import { defaultQueryParam } from '../case/caseActions.js';

export const updateParam = (modify) => (dispatch, getState) => {
    let state = getState();
    let newParam = Object.assign({}, state.queryParam, modify);

    console.log(newParam);
    dispatch(refreshParam(newParam));
};


const typeRefreshParam = 'refreshParam';
export const refreshParam = (param, type = typeRefreshParam) => {
    return {
        type: type,
        param: param
    }
}

const typeClearParam = 'clearParam';
export const clearParam = () => refreshParam(defaultQueryParam, typeClearParam);

export const reducer = (state = defaultQueryParam, action) => {

    switch (action.type) {
        case typeClearParam:
        case typeRefreshParam:
            return action.param;
        default:
    }
    return state;
};
