import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Form, Row, Button, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { store } from 'react-notifications-component';
import { useParams, useHistory } from "react-router-dom";
import { CenteredModalYesNo } from '../../../../components/Material/CenteredModal';
import { supplierInsert, supplierUpdate } from '../../../../action/maintain/supplierAction.js';


const defaultMessage = {
    title: "上傳中",
    message: "資料上傳中，請稍後...",
    type: "warning",
    insert: "top",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 2000,
        onScreen: true
    }
};

const completeMessage = {
    title: "完成",
    message: "更新完成",
    type: "success",
    insert: "top",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 1000,
        onScreen: true
    }
};


const emptySupplier = {
    id: null,
    name: '',
    address: null,
    contactInfo: null,
    contactPerson: null,
    supplierEvaluation: 1,
    remark: null
};



export const MachSupplierEdit = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { supplier } = useSelector(x => x);
    const { id } = useParams();

    const [data, setData] = useState(emptySupplier);


    useEffect(() => {
        if (id !== '0') {
            const SupplierData = supplier.supplier.find(e => e.id == id);// eslint-disable-line eqeqeq
            setData(SupplierData);
        }
    }, []) //  eslint-disable-line react-hooks/exhaustive-deps

    const insertOrUpdateData = async (data) => {
        let msgId = store.addNotification(defaultMessage);
        if (id === '0') {
            await dispatch( supplierInsert(data));
        } else {
            await dispatch( supplierUpdate(data));
        }
        store.removeNotification(msgId);
        store.addNotification(completeMessage);
        history.goBack();
    }


    const close = () => {
        history.push(`/machBasicTab/machSupplier/0`);
    }
    //radio
    const radioContent = [1, 2, 3, 4, 5]
    //欄位驗證-required
    const [validated, setValidated] = useState(false);
    //modal
    const [modalShow, setModalShow] = useState(false);

    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col md={6} className="mx-auto">
                        <Card className="mb-1" style={{ width: '100%', minWidth: '320px' }}>
                            <Card.Title>供應商資料</Card.Title>
                            <Card.Body>
                                <Form noValidate validated={validated} onSubmit={(e) => {
                                    const from = e.currentTarget;
                                    e.preventDefault();//防止預設行為
                                    if (from.checkValidity() === false ||
                                        data.name === '' ) {
                                        e.stopPropagation();
                                    } else {

                                        setModalShow(true);
                                    }
                                    setValidated(true);
                                }}>

                                    <Row style={{ margin: '0 0 20px 0' }} >
                                        <Button onClick={close}>
                                            <FontAwesomeIcon className='rounded me-2' icon={faTimes} />
                                            關閉
                                        </Button>
                                    </Row>
                                    <Row>
                                        <Form.Group className="mb-4">
                                            <Form.Label column sm="4">
                                                <strong>廠商名稱</strong>
                                            </Form.Label>
                                            <Form.Control type="text" placeholder="" required
                                                defaultValue={data.name}
                                                onChangeCapture={(e) => {
                                                    setData({ ...data, name: e.target.value });
                                                }}
                                            />

                                        </Form.Group>
                                        <Form.Group className="mb-4">
                                            <Form.Label column sm="4">
                                                <strong>聯繫方式</strong>
                                            </Form.Label>
                                            <Form.Control type="text" placeholder=""
                                                defaultValue={data.contactInfo}
                                                onChangeCapture={(e) => {
                                                    setData({ ...data, contactInfo: e.target.value });
                                                }}
                                            />

                                        </Form.Group>

                                        <Form.Group className="mb-4">
                                            <Form.Label column sm="4">
                                                <strong>聯繫人</strong>
                                            </Form.Label>

                                            <Form.Control type="text" placeholder=""
                                                defaultValue={data.contactPerson}
                                                onChangeCapture={(e) => {
                                                    setData({ ...data, contactPerson: e.target.value });
                                                }}
                                            />

                                        </Form.Group>
                                        <Form.Group className="mb-4">
                                            <Form.Label column sm="5">
                                                <strong>地址</strong>
                                            </Form.Label>

                                            <Form.Control type="text" placeholder=""
                                                defaultValue={data.address}
                                                onChangeCapture={(e) => {
                                                    setData({ ...data, address: e.target.value });
                                                }}
                                            />

                                        </Form.Group>
                                        <Form.Group className="mb-4">
                                            <Form.Label column sm="2">
                                                <strong>供應商評價</strong>
                                            </Form.Label>
                                           
                                            {radioContent.map((item, index) => {
                                                return (
                                                    <Form.Check type="radio" key={index} id={item} label={item} inline
                                                            checked={data.supplierEvaluation === item ? true : false}
                                                            onChange={(e) => {
                                                                setData({ ...data, supplierEvaluation: item });
                                                            }}                                         >
                                                        </Form.Check>
                                                        );})}
                                        </Form.Group>
                                        <Form.Group className="mb-4">
                                            <Form.Label column sm="5">
                                                <strong>備註</strong>
                                            </Form.Label>

                                            <Form.Control type="text" as="textarea"
                                                defaultValue={data.remark}
                                                onChangeCapture={(e) => {
                                                    setData({ ...data, remark: e.target.value });
                                                }}
                                            />

                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group>
                                            <Row className="mx-auto">
                                                <Button
                                                    variant="primary"
                                                    type="submit"
                                                >
                                                    <FontAwesomeIcon className='rounded me-2' icon={faSave} />
                                                    儲存
                                                </Button>

                                            </Row>
                                        </Form.Group>
                                    </Row>


                                </Form>
                                <CenteredModalYesNo
                                    title={'存檔'}
                                    message={'是否存檔?'}
                                    btnText1={'存檔'}
                                    btnText2={'取消'}
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    onClick={() => { insertOrUpdateData(data); setModalShow(false); }}
                                />
                                
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </React.Fragment>

    )
}


