import React, { useState } from 'react';
import { Form } from 'react-bootstrap';


export const FormattedNumber = (props) => {
    const { onChange = (str) => { }, defaultValue = '' } = props;
    const [str, setStr] = useState(defaultValue);


    const handlechange = (e) => {
        let _value = e.target.value;
        if (_value) {
            const formattedValue = (Number(_value.replace(/\D/g, '')) || '').toLocaleString();
            setStr(formattedValue);
            onChange(formattedValue);
        }
        else {
            setStr('');
            onChange('');
        }
    };

    return (
        <Form.Control type="text" placeholder="$"
            value={str === null || str === 'undefined' ? '' : str}
            onChange={handlechange}
        />
    );
}