import { apiProductTrackQuery } from '../../utils/api.js';

export const productTrackQuery = (newProductTrack) => async (dispatch, getState) => {
   
    let res = await apiProductTrackQuery(newProductTrack);
    let data = JSON.parse(res.request.response);
    dispatch(renProductTrack(data));
}



const typeRenProductTrack = 'typeRenProductTrack';
const renProductTrack = (arrProductTrack) => {

    return {
        type: typeRenProductTrack,
        productTrack: arrProductTrack
    }
}



const initState = {
    productTrack: []
}


export const reducer = (state = initState, action) => {
    switch (action.type) {
        case typeRenProductTrack:
            return { ...state, productTrack: [...action.productTrack] };
       
        default:
            return state;
    }
}