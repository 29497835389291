import React from 'react';
import { Modal, Button } from 'react-bootstrap';

export const CenteredModalYesNo = (props) => {
    const { show = false, onHide = () => { }, title = "", message = "", btnText1 = "", btnText2 = "", onClick = () => { } } = props;

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {message}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={onClick}>{btnText1 }</Button>
                <Button variant="secondary" onClick={props.onHide}>{btnText2}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export const CenteredModalAlert = (props) => {
    const { show = false, onHide = () => { }, title = "", message = "", btnText = "", } = props;

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" class="text-danger h4">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {message}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={props.onHide}>{btnText}</Button>
            </Modal.Footer>
        </Modal>
    );
}