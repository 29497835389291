import { apiScheduledProduct, apiScheduledSalesSlip } from '../../utils/api.js';
import { productSettingsQuery } from '../material/productAction.js';
import { productTrackQuery } from '../material/productTrackAction.js';


export const scheduledTriggerProduct = () => async (dispatch, getState) => {
    let resp = await apiScheduledProduct();
    if (resp.status === 200) {
        var delayInMilliseconds = 2000; //2 second

        setTimeout(function () {
           dispatch(productSettingsQuery());
        }, delayInMilliseconds);
       
    }
}

export const scheduledTriggerSalesSlip = () => async (dispatch, getState) => {
    let resp = await apiScheduledSalesSlip();
    if (resp.status === 200) {
        var delayInMilliseconds = 5000; //5 second

        setTimeout(function () {
            dispatch(productTrackQuery());
        }, delayInMilliseconds);

    }
    

}