import * as React from 'react';
import { useParams, useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { Card, Button, Offcanvas, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useRWD } from '../../../useRWD.js';

export const BusinessReportDetail = (props) => {
    const { id } = useParams();
    const history = useHistory();
    const close = () => history.goBack();
    const name = props.organization.allMembers.find(x => x.ID === id).Name;
    const data = props.businessCase.businessreportdetail.filter(x => x.updatedBy === id);
    //依據視窗大小調整 高度
    let device = useRWD();
    device = (device + 150) + 'px';
    return (
        <React.Fragment>
            <Offcanvas
                show={true}
                onHide={props.onHide}
                placement="end"
                style={{ minWidth: '375px', width: '100%' }}
            >
                <Offcanvas.Header className="bg-primary text-light">
                    <Offcanvas.Title>
                        <FontAwesomeIcon className='rounded me-2' icon={faPenSquare} />
                        {name}
                    </Offcanvas.Title>
                    <Button onClick={close}>
                        <FontAwesomeIcon className='rounded me-2' icon={faTimes} />
                        關閉
                    </Button>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Container fluid>
                        <Card className="mb-1" style={{ width: '100%', minWidth: '312px'}}>
                            <Card.Body>
                                <div className="ag-theme-alpine" style={{ height: device, width: '100%' }}>
                                    <AgGridReact
                                        rowData={data}

                                        defaultColDef={{
                                            sortable: true,
                                            resizable: true,
                                            wrapText: true,
                                            autoHeight: true,
                                            filter: true
                                        }}
                                        onGridReady={(params) => {

                                        }}
                                        onRowClicked={(e) => {
                                            let id = e.data.customerId;
                                            history.push(`/customertab/${id}`);
                                        }}
                                    >
                                        <AgGridColumn headerName="案例名稱" field="name" width={260}></AgGridColumn>
                                        <AgGridColumn headerName="診所名稱" field="customerName" width={260}></AgGridColumn>
                                        <AgGridColumn headerName="拜訪日期" field="visitedDate" width={130} ></AgGridColumn>
                                        <AgGridColumn headerName="工作類別" valueGetter={(x) => {
                                            let jobclass = props.config.jobclass.find(y => y.value === x.data.jobClass);
                                            return jobclass.text;
                                        }} width={180}></AgGridColumn>
                                        <AgGridColumn headerName="案例處理進度" field="changeReason" width={600}></AgGridColumn>
                                        <AgGridColumn headerName="備註" field="comment" width={260}></AgGridColumn>
                                    </AgGridReact>
                                </div>
                            </Card.Body>
                        </Card>

                    </Container>
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment >
    )
};
export const ReportDetail = connect((state) => state, {})(BusinessReportDetail);