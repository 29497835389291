import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Form, Row, Button, Col, Container, Accordion, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes, faPen, faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { store } from 'react-notifications-component';
import { useParams, useHistory } from "react-router-dom";
import { CenteredModalYesNo } from '../../../../components/Material/CenteredModal';
import { recordInsert, recordQueryEQP, recordDeleteDetails } from '../../../../action/maintain/recordAction.js';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../Record/div.css';

const defaultMessage = {
    title: "上傳中",
    message: "資料上傳中，請稍後...",
    type: "warning",
    insert: "top",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 2000,
        onScreen: true
    }
};

const completeMessage = {
    title: "完成",
    message: "更新完成",
    type: "success",
    insert: "top",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 1000,
        onScreen: true
    }
};


const emptyBasic = {
    id: null,
    equipmentID: '',
    employee: '',
    date: null,
    category: '',
    content: '',
    replacementParts: '',
    maintStatus: '',
    mainResults: '',
    filePath: '',
    transTimeStart: null,
    transTimeEnd: null,
    workingHoursStart: null,
    workingHoursEnd: null,
    remark: '',
    eQPLocation: null
};

const queryParam = {
    keyword: null
};


export const MaintRecordEdit = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { eqpName, eqpType, maintJobClass, maintStatus, eqpArea } = useSelector(x => x.config);
    const { organization, machBasic, maintRecord, customers, maintContract } = useSelector(x => x);
    const { id } = useParams();

    const [refreshTrigger, setRefreshTrigger] = useState(0);
    const [data, setData] = useState(emptyBasic);
    const [_eqpType, setEqpType] = useState('');
    const [_eqpArea, setEqpArea] = useState('');
    const [_serialNo, setSerialNo] = useState('');
    const [_customer, setCustomer] = useState('');
    const [isEdit, setIsEdit] = useState(true);

    const refreshRecords = () => {
        setRefreshTrigger(prev => prev + 1);
    };
    useEffect(() => {
        if (id !== '0') {
            const MaintRecordData = maintRecord.record.find(e => e.id == id);// eslint-disable-line eqeqeq
            if (refreshTrigger > 0) {
                dispatch(recordQueryEQP(queryParam));//執行刷新邏輯
            }

            //設備序號名稱
            data.equipmentID = MaintRecordData.equipmentID;
            const machBasicData = machBasic.basic.find(e => e.id == MaintRecordData.equipmentID);// eslint-disable-line eqeqeq

            if (machBasicData !== undefined) {
                const maintContractData = maintContract.contract.find(e => e.contrEQP.find(r => r.equipmentID == machBasicData.id));
                setSerialNo(maintContractData === undefined ? 0 : maintContractData.id);
            } else {
                setSerialNo(0);
            }


            queryParam.keyword = data.equipmentID;
            dispatch(recordQueryEQP(queryParam));
            let itemName = eqpName.find((e) => e.value === parseInt(machBasicData.name));
            let item = eqpType.find((e) => itemName.configId === e.order && e.value === machBasicData.type);
            setEqpType(item.text);
            eqpArea.forEach((temp) => {
                if (temp.value === parseInt(machBasicData.area)) {
                    setEqpArea(temp.text);
                    return;
                }
            })
            customers.forEach((temp) => {
                if (temp.customerId === parseInt(machBasicData.customerId)) {
                    let tax = '';
                    if (temp.taxId != null) {
                        tax = temp.taxId;
                    }
                    setCustomer('(' + tax + ')' + temp.name);
                    return;
                }
            })
            //編輯模式
            setIsEdit(true);
        } else {
            //新增模式
            setIsEdit(false);
        }
    }, [refreshTrigger]) //  eslint-disable-line react-hooks/exhaustive-deps


    const insertOrUpdateData = async (data) => {
        let msgId = store.addNotification(defaultMessage);
        await dispatch(recordInsert(data));
        store.removeNotification(msgId);
        store.addNotification(completeMessage);
        history.goBack();
    }
    //客戶到機台基本資料綁定
    const custToggleEdit = () => {
        history.push(`/machBasicEdit/${data.equipmentID}`);
    }
    //連結合約頁面
    const contractToggleEdit = () => {
        if (_serialNo === 0) {
            history.push(`/maintainTab/maintContract/0`);
        } else {
            history.push(`/maintContractEdit/${_serialNo}`);
        }

    }

    const close = () => {
        history.push(`/maintainTab/maintRecord/0`);
    }
    //Select 客戶資料 快速搜尋
    const defaultOptions = [{}];
    customers.forEach(item => {
        let tax = '';
        if (item.taxId != null) {
            tax = item.taxId;
        }
        defaultOptions.push({
            value: item.customerId,
            label: '(' + tax + ')' + item.name

        });

    });
    //Select 快速搜尋
    const defaultOptionsEqpType = [];
    machBasic.basic.forEach(item => {
        //新增模式已有設備序號排除
        if (isEdit === false) {
            if (maintRecord.record !== null || maintRecord.record === undefined) {
                // eslint-disable-next-line eqeqeq
                if (!maintRecord.record.find(e => e.equipmentID == item.id)) {
                    defaultOptionsEqpType.push({
                        value: item.id,
                        label: item.serialNo,
                        datakey: item.id
                    });

                }
            } else {
                defaultOptionsEqpType.push({
                    value: item.id,
                    label: item.serialNo,
                    datakey: item.id
                });
            }
        } else {
            defaultOptionsEqpType.push({
                value: item.id,
                label: item.serialNo,
                datakey: item.id
            });
        }

    });
    defaultOptionsEqpType.sort(function compareFn(a, b) {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
    });


    //欄位驗證-required
    const [validated, setValidated] = useState(false);
    //modal
    const [modalShow, setModalShow] = useState(false);

    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col md={6} className="mx-auto">
                        <Card className="mb-1" style={{ width: '100%', minWidth: '320px' }}>
                            <Card.Body>
                                <Form noValidate validated={validated} onSubmit={(e) => {
                                    const from = e.currentTarget;
                                    e.preventDefault();//防止預設行為
                                    if (from.checkValidity() === false ||
                                        data.equipmentID === undefined || data.name === '0' || data.type === '0' || data.status === '0'
                                        || data.area === '0' || data.supplierID === '0') {
                                        e.stopPropagation();
                                    } else {

                                        setModalShow(true);
                                    }
                                    setValidated(true);

                                }}>

                                    <Row >
                                        <Button onClick={close}>
                                            <FontAwesomeIcon className='rounded me-2' icon={faTimes} />
                                            關閉
                                        </Button>
                                    </Row>
                                    <Row className="mb-2">
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-4">
                                                    <Form.Label column >
                                                        <strong>設備序號<span class="text-danger" style={{ fontSize: '14px', padding: 'revert' }}>   *必填</span></strong>
                                                    </Form.Label>

                                                    {/* 快速搜尋 功能*/}
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        isSearchable="true"
                                                        isClearable="true"
                                                        isDisabled={isEdit}
                                                        value={defaultOptionsEqpType[defaultOptionsEqpType.findIndex((e) => e.value === data.equipmentID)]}
                                                        onChange={(e) => {
                                                            setEqpType('');
                                                            setEqpArea('');
                                                            setCustomer('');
                                                            if (e !== null) {
                                                                let EQPname_temp = e.datakey;
                                                                let machBasicData = machBasic.basic.find(e => e.id == parseInt(EQPname_temp));// eslint-disable-line eqeqeq

                                                                let itemName = eqpName.find((e) => e.value === parseInt(machBasicData.name));
                                                                let item = eqpType.find((e) => itemName.configId === e.order && e.value === machBasicData.type);
                                                                setEqpType(item.text);
                                                                eqpArea.forEach((temp) => {
                                                                    if (temp.value === parseInt(machBasicData.area)) {
                                                                        setEqpArea(temp.text);
                                                                        return;
                                                                    }
                                                                })
                                                                customers.forEach((temp) => {
                                                                    if (temp.customerId === parseInt(machBasicData.customerId)) {
                                                                        let tax = '';
                                                                        if (temp.taxId != null) {
                                                                            tax = temp.taxId;
                                                                        }
                                                                        setCustomer('(' + tax + ')' + temp.name);
                                                                        return;
                                                                    }
                                                                })

                                                            }
                                                            setData({
                                                                ...data,
                                                                equipmentID: e ? e.value : ''
                                                            });

                                                        }}
                                                        options={defaultOptionsEqpType}
                                                    />



                                                </Form.Group>

                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-4">
                                                    <Form.Label column >
                                                        <strong>機型</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" disabled
                                                        defaultValue={_eqpType}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            {isEdit &&
                                                <Col sm="2">
                                                    <Form.Group className="mb-4">
                                                        <Form.Label column sm="12">
                                                            <strong>合約</strong>
                                                        </Form.Label>
                                                        <Button style={{ fontSize: '14px', padding: 'revert' }} variant="primary" onClick={contractToggleEdit}>
                                                            查看
                                                            <FontAwesomeIcon className='rounded' size="1x" icon={faSearch} />
                                                        </Button>
                                                    </Form.Group>
                                                </Col>
                                            }
                                        </Row>

                                        <Row>
                                            <Col sm="6">
                                                <Form.Group className="mb-4">
                                                    <Form.Label column >
                                                        <strong>客戶資料</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" disabled
                                                        defaultValue={_customer}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-4">
                                                    <Form.Label column >
                                                        <strong>區域</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" disabled
                                                        defaultValue={_eqpArea}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col sm="2">
                                                <Form.Group className="mb-4">
                                                    <Form.Label column style={{ fontSize: '14px' }} >
                                                        <strong>設備資料</strong>
                                                    </Form.Label>
                                                    <Button style={{ fontSize: '14px', padding: 'revert' }} variant="primary" onClick={custToggleEdit}>
                                                        編輯
                                                        <FontAwesomeIcon className='rounded' size="1x" icon={faPen} />
                                                    </Button>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Alert className="mb-0" variant='primary' style={{ margin: '0rem', padding: '0.5rem' }} >
                                            維修紀錄填寫
                                        </Alert>
                                        <Card style={{ width: '100%', minWidth: '320px' }}>

                                            <Card.Body>
                                                <Row>
                                                    <Col>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label column >
                                                                <strong>維修日期</strong>
                                                            </Form.Label>
                                                            <DatePicker className="form-control" required
                                                                locale="zh-TW"
                                                                dateFormat="yyyy/MM/dd"
                                                                selected={
                                                                    data.date == null ? data.date : new Date(data.date)
                                                                }
                                                                onChange={(e) => {
                                                                    let newDate = e.setHours(e.getHours() + 9);
                                                                    setData({
                                                                        ...data,
                                                                        date: e,
                                                                        transTimeStart: new Date(newDate),
                                                                        workingHoursStart: new Date(newDate)
                                                                    });
                                                                }}
                                                            />

                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label column >
                                                                <strong>維修人員</strong>
                                                            </Form.Label>
                                                            <Form.Control required as="select" type="select" onChange={(e) => {
                                                                setData({
                                                                    ...data,
                                                                    employee: e.target.value
                                                                });
                                                            }}
                                                                value={data.employee}
                                                            >
                                                                <option key={0} data-key={0} value=''> 請選擇</option>
                                                                {organization.members.map(item => {
                                                                    return (<option key={item.ID} data-key={item.DepartmentID} value={item.ID}>{item.Name}</option>)

                                                                })}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                </Row>

                                                <Row>
                                                    <Col>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label column >
                                                                <strong>車程開始時間</strong>
                                                            </Form.Label>
                                                            <DatePicker className="form-control" required
                                                                locale="zh-TW"
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={30}
                                                                dateFormat="yyyy/MM/dd HH:mm"
                                                                selected={
                                                                    data.transTimeStart == null ? data.transTimeStart : new Date(data.transTimeStart)
                                                                }
                                                                onChange={(e) => {
                                                                    setData({
                                                                        ...data,
                                                                        transTimeStart: e
                                                                    });
                                                                }}
                                                            />

                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm="1" className="styled-div">
                                                        <strong  >~</strong>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label column >
                                                                <strong>車程結束時間</strong>
                                                            </Form.Label>
                                                            <DatePicker className="form-control" required
                                                                locale="zh-TW"
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={30}
                                                                dateFormat="yyyy/MM/dd HH:mm"
                                                                selected={
                                                                    data.transTimeEnd == null ? data.transTimeEnd : new Date(data.transTimeEnd)
                                                                }
                                                                onChange={(e) => {
                                                                    setData({
                                                                        ...data,
                                                                        transTimeEnd: e
                                                                    });
                                                                }}
                                                            />

                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label column >
                                                                <strong>工作開始時間</strong>
                                                            </Form.Label>
                                                            <DatePicker className="form-control" required
                                                                locale="zh-TW"
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={30}
                                                                dateFormat="yyyy/MM/dd HH:mm"
                                                                selected={
                                                                    data.workingHoursStart == null ? data.workingHoursStart : new Date(data.workingHoursStart)
                                                                }
                                                                onChange={(e) => {
                                                                    setData({
                                                                        ...data,
                                                                        workingHoursStart: e
                                                                    });
                                                                }}
                                                            />

                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm="1" className="styled-div">
                                                        <strong  >~</strong>
                                                    </Col>
                                                    <Col>

                                                        <Form.Group className="mb-4">
                                                            <Form.Label column >
                                                                <strong>工作結束時間</strong>
                                                            </Form.Label>
                                                            <DatePicker className="form-control" required
                                                                locale="zh-TW"
                                                                showTimeSelect
                                                                timeFormat="HH:mm"
                                                                timeIntervals={30}
                                                                dateFormat="yyyy/MM/dd HH:mm"
                                                                selected={
                                                                    data.workingHoursEnd == null ? data.workingHoursEnd : new Date(data.workingHoursEnd)
                                                                }
                                                                onChange={(e) => {
                                                                    setData({
                                                                        ...data,
                                                                        workingHoursEnd: e
                                                                    });
                                                                }}
                                                            />

                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>

                                                    <Col>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label column >
                                                                <strong>工作內容分類</strong>
                                                            </Form.Label>
                                                            <Form.Control required as="select" type="select" onChange={(e) => {
                                                                setData({
                                                                    ...data,
                                                                    category: e.target.value
                                                                });
                                                            }}
                                                                value={data.category}
                                                            >
                                                                <option key={0} data-key={0} value=''> 請選擇</option>
                                                                {maintJobClass.map(item => {
                                                                    return (<option key={item.configId} data-key={item.configId} value={item.value} >{item.text}{item.comment}</option>)
                                                                })}
                                                            </Form.Control>

                                                        </Form.Group>
                                                    </Col>
                                                    <Col>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label column >
                                                                <strong>維修狀態說明</strong>
                                                            </Form.Label>
                                                            <Form.Control required as="select" type="select" onChange={(e) => {
                                                                setData({
                                                                    ...data,
                                                                    maintStatus: e.target.value
                                                                });
                                                            }}
                                                                value={data.maintStatus}
                                                            >
                                                                <option key={0} data-key={0} value=''> 請選擇</option>
                                                                {maintStatus.map(item => {
                                                                    return (<option key={item.configId} data-key={item.configId} value={item.value} >{item.text}{item.comment}</option>)
                                                                })}
                                                            </Form.Control>

                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <Form.Group className="mb-4">
                                                            <Form.Label column >
                                                                <strong>工作內容說明</strong>
                                                            </Form.Label>
                                                            <Form.Control type="text" as="textarea" required
                                                                defaultValue={data.content}
                                                                onChangeCapture={(e) => {
                                                                    setData({ ...data, content: e.target.value });

                                                                }}
                                                            />

                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Form.Group className="mb-4">
                                                    <Form.Label column >
                                                        <strong>維修結果/建議</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" as="textarea" required
                                                        defaultValue={data.mainResults}
                                                        onChangeCapture={(e) => {
                                                            setData({ ...data, mainResults: e.target.value });

                                                        }}
                                                    />

                                                </Form.Group>
                                                <Row>

                                                    <Col>
                                                        <Form.Label sm={3} >
                                                            <strong>設備所在位址</strong>
                                                        </Form.Label>
                                                    </Col>
                                                    <Col sm={9}>
                                                        {/* 快速搜尋 功能*/}
                                                        <Select
                                                            className="basic-single"
                                                            classNamePrefix="select"
                                                            isSearchable="true"
                                                            value={defaultOptions[defaultOptions.findIndex((e) => e.value === data.eQPLocation)]}
                                                            onChange={(newValue) => {
                                                                let val = newValue;
                                                                setData({ ...data, eQPLocation: val.value });
                                                            }}
                                                            options={defaultOptions}
                                                        />
                                                    </Col>

                                                </Row>
                                                <Form.Group className="mb-4">
                                                    <Form.Label column >
                                                        <strong>備註</strong>
                                                    </Form.Label>

                                                    <Form.Control type="text" as="textarea"
                                                        defaultValue={data.remark}
                                                        onChangeCapture={(e) => {
                                                            setData({ ...data, remark: e.target.value });

                                                        }}
                                                    />

                                                </Form.Group>
                                            </Card.Body>
                                        </Card>

                                    </Row>
                                    <Row>
                                        <Form.Group>
                                            <Row className="mx-auto">
                                                <Button
                                                    variant="primary"
                                                    type="submit"
                                                >
                                                    <FontAwesomeIcon className='rounded me-2' icon={faSave} />
                                                    儲存
                                                </Button>

                                            </Row>
                                        </Form.Group>
                                    </Row>
                                </Form>
                                <CenteredModalYesNo
                                    title={'存檔'}
                                    message={'是否存檔?'}
                                    btnText1={'存檔'}
                                    btnText2={'取消'}
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    onClick={() => { insertOrUpdateData(data); setModalShow(false); }}
                                />

                            </Card.Body>
                        </Card>
                    </Col>
                    {isEdit &&
                        <Col md={4} className="mx-auto">
                            <Row>
                                <Card style={{ width: '100%', minWidth: '380px' }}>
                                    <Card.Header>
                                        維修紀錄
                                    </Card.Header>
                                    <Card.Body style={{ padding: '0.5rem' }} >
                                        <Accordion defaultActiveKey="0" variant="secondary" style={{ width: '100%' }}>
                                            {
                                                maintRecord.recordEQP.map((x, index) =>
                                                    <MaintRecord key={x.id} {...x} refreshRecords={refreshRecords} />
                                                )
                                            }
                                        </Accordion>
                                    </Card.Body>
                                </Card>
                            </Row>
                            <Row>

                            </Row>

                        </Col>
                    }
                </Row>
            </Container>

        </React.Fragment>

    )
}

const MaintRecord = (props) => {
    const { id, employee, date, category, content, maintStatus, mainResults, transTimeStart, transTimeEnd, workingHoursStart, workingHoursEnd, remark, transTime, workingHours, updatedDate, eqpLocation, refreshRecords } = props;
    const { customers } = useSelector(x => x);
    let item = customers.find((e) => e.customerId === eqpLocation);
    return (
        <Accordion.Item eventKey={id} >
            <Accordion.Header><HeaderText id_value={id} refreshRecords={refreshRecords} employee_value={employee} category_value={category} status_value={maintStatus} date_value={date} /></Accordion.Header>
            <Accordion.Body>
                <div className="col-sm-12 col-md-12">
                    <div className="form-text" >
                        車程:{transTime}(分)  工時:{workingHours}(小時)
                    </div>
                    <div className="form-text" >
                        工作時間:{workingHoursStart.replace('T', ' ').substr(0, 16)} ~ {workingHoursEnd.replace('T', ' ').substr(0, 16)}
                    </div> <div className="form-text" >
                        車程時間:{transTimeStart.replace('T', ' ').substr(0, 16)} ~ {transTimeEnd.replace('T', ' ').substr(0, 16)}
                    </div>
                    <div className="form-text" >
                        維修內容說明:{content}
                    </div>
                    <div className="form-text" >
                        維修結果/建議:{mainResults}
                    </div>
                    <div className="form-text" >
                        設備所在位址 : {item === undefined ? null : item.name}
                    </div>
                    <div className="form-text" >
                        備註:{remark}
                    </div>

                    <div className="form-text" style={{ textAlign: 'right' }}>
                        建立時間 : {updatedDate.replace('T', ' ').substr(0, 19)}
                    </div>
                </div>
            </Accordion.Body>
        </Accordion.Item>
    );
}

const HeaderText = (props) => {
    const { id_value, employee_value, category_value, status_value, date_value, refreshRecords } = props;
    const { organization, maintRecord } = useSelector(x => x);
    const { maintJobClass, maintStatus } = useSelector(x => x.config);
    const { MachDelete } = useSelector(x => x.uiMach);
    const dispatch = useDispatch();
    const member = organization.allMembers.find(x => x.ID === employee_value);
    const JobClass = maintJobClass.find(x => x.value === category_value);
    const Status = maintStatus.find(x => x.value === status_value);
    const NAME = member === undefined ? '' : member.Name;

    const DeleteMethod = async () => {
        let id = id_value;
        const MaintRecordData = maintRecord.recordEQP.find(e => e.id == id);// eslint-disable-line eqeqeq

        await dispatch(recordDeleteDetails(MaintRecordData));
        refreshRecords(); // 觸發刷新

    }
    return (
        <div className="form-text" style={{ width: '100%' }}>
            <div style={{ width: '100%', display: 'flex' }} >

                <div style={{ width: '60%' }}>
                    維修日期 :{date_value.replace('T', ' ').substr(0, 10)}
                </div>

                <div style={{ width: '30%' }}>
                    人員:{NAME}
                </div>
                {!MachDelete.disabled &&
                    <button
                        style={{ height: 25, width: 25, padding: 0 }}
                        className="btn btn-danger"
                        onClick={() => {
                            DeleteMethod();
                        }}
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </button>
                }
            </div>
            <div style={{ width: '100%', display: 'flex' }}>
                <div style={{ width: '60%' }}>
                    內容分類:{JobClass.text}
                </div>
                <div style={{ width: '40%' }}>
                    狀態:{Status.text}
                </div>
            </div>

        </div>
    );
}