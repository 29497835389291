const typeNavBarUpdate = "NavBarUpdate";
export const toggleNavBar = () => (dispatch, getState) => {
    let state = getState();
    let status = !state.ui.NavBar.isExpanded;
    dispatch(expandNavBar(status));
}

export const expandNavBar = (status) => {
    return {
        type: typeNavBarUpdate,
        status: status
    }
}

const typeLoadingState = "typeLoadingState";
export const toggleLoading = (data) => (dispatch, getState) => {

    dispatch(loadingState(data));
}

export const loadingState = (status) => {
    return {
        type: typeLoadingState,
        maskLoading: status
    }
}
const typeBusinessUpdate = "BusinessUpdate";
export const toggleBusiness = (data) => (dispatch, getState) => {
   
    dispatch(expandBusiness(data));
}

export const expandBusiness = (status) => {
    return {
        type: typeBusinessUpdate,
        business: status
    }
}

const typeBusinessEditBoxUpdate = "BusinessEditBoxUpdate";
export const toggleBusinessEditBox = (data) => (dispatch, getState) => {
   
    dispatch(expandBusinessEditBox(data));
}

export const expandBusinessEditBox = (status) => {
    return {
        type: typeBusinessEditBoxUpdate,
        businessEditBox: status
    }
}


const typeBusinessAddBoxUpdate = "BusinessAddBoxUpdate";
export const toggleBusinessAddBox = (data) => (dispatch, getState) => {
  
    dispatch(expandBusinessAddBox(data));
}

export const expandBusinessAddBox = (status) => {
    return {
        type: typeBusinessAddBoxUpdate,
        businessAddBox: status
    }
}

const typeSearchBarUpdate = "SearchBarUpdate";
export const toggleSearchBar = () => (dispatch, getState) => {
    let state = getState();
    let status = !state.ui.SearchBar.isCollapse;
    dispatch(collapseSearchBar(status, typeSearchBarUpdate));
}

const typeProductTrackSearchBarUpdate = "ProductTrackSearchBarUpdate";
export const toggleProductTrackSearchBar = (data) => (dispatch, getState) => {
    let state = getState();
    let status = data;
    if (data === undefined) {
        status = !state.ui.ProductTrackSearchBar.isCollapse;
    }
    
    dispatch(collapseSearchBar(status, typeProductTrackSearchBarUpdate));
}

export const collapseSearchBar = (status, type) => {
    return {
        type: type,
        collapseSearchBar: status
    }
}
const typeCustomerUpdate = "CustomerUpdate";
export const toggleCustomer = (data) => (dispatch, getState) => {
   
    dispatch(disabledCustomer(data));
}

export const disabledCustomer = (status) => {
    return {
        type: typeCustomerUpdate,
        cus: status
    }
}
const typeCustomerAddUpdate = "CustomerAddUpdate";
export const toggleCustomerAdd = (data) => (dispatch, getState) => {
   
    dispatch(disabledCustomerAdd(data));
}

export const disabledCustomerAdd = (status) => {
    return {
        type: typeCustomerAddUpdate,
        cusadd: status
    }
}

const typeCustomerEditUpdate = "CustomerEditUpdate";
export const toggleCustomerEdit = (data) => (dispatch, getState) => {
    
    dispatch(disabledCustomerEdit(data));
}

export const disabledCustomerEdit = (status) => {
    return {
        type: typeCustomerEditUpdate,
        cusedit: status
    }
}

const typeBusinessReportUpdate = "BusinessReportUpdate";
export const toggleBusinessReport = (data) => (dispatch, getState) => {
  
    dispatch(disabledBusinessReport(data));
}

export const disabledBusinessReport = (status) => {
    return {
        type: typeBusinessReportUpdate,
        businessreport: status
    }
}
const typeAdministrationUpdate = "AdministrationUpdate";
export const toggleAdministration = (data) => (dispatch, getState) => {
   
    dispatch(disabledAdministration(data));
}

export const disabledAdministration = (status) => {
    return {
        type: typeAdministrationUpdate,
        administration: status
    }
}

const typeMaterialSetQuery = "MaterialSetQuery";
export const toggleMaterialSetQuery = (data) => (dispatch, getState) => {
  
    dispatch(disabledMaterialSetQuery(data));
}

export const disabledMaterialSetQuery = (status) => {
    return {
        type: typeMaterialSetQuery,
        setquery: status
    }
}
const typeMaterialSetEdit = "MaterialSetEdit";
export const toggleMaterialSetEdit = (data) => (dispatch, getState) => {
   
    dispatch(disabledMaterialSetEdit(data));
}

export const disabledMaterialSetEdit = (status) => {
    return {
        type: typeMaterialSetEdit,
        setedit: status
    }
}
const typeMaterialQRCodeQuery = "MaterialQRCodeQuery";
export const toggleMaterialQRCodeQuery = (data) => (dispatch, getState) => {
   
    dispatch(disabledMaterialQRCodeQuery(data));
}

export const disabledMaterialQRCodeQuery = (status) => {
    return {
        type: typeMaterialQRCodeQuery,
        qrcodequery: status
    }
}
const typeMaterialQRCodeAdd = "MaterialQRCodeAdd";
export const toggleMaterialQRCodeAdd = (data) => (dispatch, getState) => {
   
    dispatch(disabledMaterialQRCodeAdd(data));
}

export const disabledMaterialQRCodeAdd = (status) => {
    return {
        type: typeMaterialQRCodeAdd,
        qrcodeadd: status
    }
}
const typeMaterialTrackQuery = "MaterialTrackQuery";
export const toggleMaterialTrackQuery = (data) => (dispatch, getState) => {
   
    dispatch(disabledMaterialTrackQuery(data));
}

export const disabledMaterialTrackQuery = (status) => {
    return {
        type: typeMaterialTrackQuery,
        trackquery: status
    }
}


const initState = {
    NavBar: {
        isExpanded: false
    },
    MaskLoading: {
        display: 'none'
    },
    Business: {
        disabled: false
    },
    BusinessEditBox: {
        disabled: false
    },
    BusinessAddBox: {
        disabled: false
    },
    SearchBar: {
        isCollapse: false
    },
    ProductTrackSearchBar: {
        isCollapse: false
    },
    Cust: {
        disabled: false
    },
    CustAdd: {
        disabled: false
    },
    CustEdit: {
        disabled: false
    },
    Report: {
        disabled: false
    },
    AdminBtn: {
        disabled: false
    },
    MaterialSetQuery: {
        disabled: false
    },
    MaterialSetEdit: {
        disabled: false
    },
    MaterialQRCodeQuery: {
        disabled: false
    },
    MaterialQRCodeAdd: {
        disabled: false
    },
    MaterialTrackQuery: {
        disabled: false
    }
}

export const reducer = (state = initState, action) => {
    switch (action.type) {
        case typeNavBarUpdate:
            return Object.assign({}, state, { NavBar: { isExpanded: action.status } });
        case typeLoadingState:
            return Object.assign({}, state, { MaskLoading: { display: action.maskLoading } });
        case typeBusinessUpdate:
            return Object.assign({}, state, { Business: { disabled: action.business } });
        case typeBusinessAddBoxUpdate:
            return Object.assign({}, state, { BusinessAddBox: { disabled: action.businessAddBox } }); 
        case typeBusinessEditBoxUpdate:
            return Object.assign({}, state, { BusinessEditBox: { disabled: action.businessEditBox } });
        case typeSearchBarUpdate:
            return Object.assign({}, state, { SearchBar: { isCollapse: action.collapseSearchBar } });
        case typeProductTrackSearchBarUpdate:
            return Object.assign({}, state, { ProductTrackSearchBar: { isCollapse: action.collapseSearchBar } });
        case typeCustomerUpdate:
            return Object.assign({}, state, { Cust: { disabled: action.cus } });
        case typeCustomerAddUpdate:
            return Object.assign({}, state, { CustAdd: { disabled: action.cusadd } });
        case typeCustomerEditUpdate:
            return Object.assign({}, state, { CustEdit: { disabled: action.cusedit } });
        case typeBusinessReportUpdate:
            return Object.assign({}, state, { Report: { disabled: action.businessreport } });
        case typeAdministrationUpdate:
            return Object.assign({}, state, { AdminBtn: { disabled: action.administration } });
        case typeMaterialSetQuery:
            return Object.assign({}, state, { MaterialSetQuery: { disabled: action.setquery } });
        case typeMaterialSetEdit:
            return Object.assign({}, state, { MaterialSetEdit: { disabled: action.setedit } });
        case typeMaterialQRCodeQuery:
            return Object.assign({}, state, { MaterialQRCodeQuery: { disabled: action.qrcodequery } });
        case typeMaterialQRCodeAdd:
            return Object.assign({}, state, { MaterialQRCodeAdd: { disabled: action.qrcodeadd } });
        case typeMaterialTrackQuery:
            return Object.assign({}, state, { MaterialTrackQuery: { disabled: action.trackquery } });
        default:
    }
    return state;
}