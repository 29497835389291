import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Form, Row, Button, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes, faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { store } from 'react-notifications-component';
import { useParams, useHistory } from "react-router-dom";
import { CenteredModalYesNo } from '../../../../components/Material/CenteredModal';
import { contractInsert, contractUpdate } from '../../../../action/maintain/contractAction.js';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';


const defaultMessage = {
    title: "上傳中",
    message: "資料上傳中，請稍後...",
    type: "warning",
    insert: "top",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 2000,
        onScreen: true
    }
};

const completeMessage = {
    title: "完成",
    message: "更新完成",
    type: "success",
    insert: "top",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 1000,
        onScreen: true
    }
};


const emptyContract = {
    id: null,
    serialNo: '',
    equipmentID: '0',
    customerID: '0',
    category: '0',
    content: '',
    frequency: '',
    date: null,
    contracStartDate: null,
    contracEndDate: null,
    payment: '0',
    contractAmount: null,
    filePath: '',
    remark: '',
    area: '0',
    title: '',
    contrEQP: []
};



export const MaintContractEdit = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const rowStyle = {
        cursor: "pointer",
    };
    const [state, setState] = useState({ gridApi: null });//grid表
    const { eqpName, maintContractJobClass, maintContractPayment, eqpArea, eqpType } = useSelector(x => x.config);
    const { customers, maintContract, machBasic } = useSelector(x => x);
    const { id } = useParams();

    const [data, setData] = useState(emptyContract);
    const [_eqpType, setEqpType] = useState('');
    //eslint-disable-next-line no-unused-vars
    const [pdf, setPdf] = useState();
    const [isEdit, setEdit] = useState(false);

    useEffect(() => {
        if (id !== '0') {
            const MaintContractData = maintContract.contract.find(e => e.id == id);// eslint-disable-line eqeqeq
            setData(MaintContractData);
            setEdit(true);
        }
    }, []) //  eslint-disable-line react-hooks/exhaustive-deps


    const insertOrUpdateData = async (data) => {
        let msgId = store.addNotification(defaultMessage);
        data.equipmentID = 0;
        if (id === '0') {
            await dispatch(contractInsert(data));
        } else {
            await dispatch(contractUpdate(data));
        }
        store.removeNotification(msgId);
        store.addNotification(completeMessage);
        history.goBack();
    }

    const ContrEQPToggleAdd = () => {
        if (data.equipmentID !== '0') {
            let _machMaintContractID = data.id;
            if (data.id === null) {
                _machMaintContractID = 0;;
            }
            const newItem = { machMaintContractID: _machMaintContractID, equipmentID: data.equipmentID, eqpType: _eqpType };

            setData(prevData => ({
                ...prevData,
                contrEQP: [...prevData.contrEQP, newItem]
            }));
            setEqpType(''); // Clear the input field
        }
    }
    //刪除
    const gridRef = useRef();
    const onRemoveSelected = useCallback(() => {
        const selectedData = gridRef.current.api.getSelectedRows();
        gridRef.current.api.applyTransaction({ remove: selectedData });
        setData(prevData => ({
            ...prevData,
            contrEQP: prevData.contrEQP.filter(item => item.equipmentID !== selectedData[0].equipmentID)
        }));

    }, []);
    //客戶新增編輯Btn
    const custToggleAdd = () => {

        history.push(`/customerbox/0`);
    }
    const custToggleEdit = () => {
        if (data.customerId === "" || data.customerId === null) {
            window.alert("請先選擇客戶");
            return;
        }

        history.push(`/customerbox/${data.customerId}`);
    }
    const close = () => {
        history.push(`/maintainTab/maintContract/0`);
    }
    //Select 客戶資料 快速搜尋
    const defaultOptions = [];
    customers.forEach(item => {
        let tax = '';
        if (item.taxId != null) {
            tax = item.taxId;
        }
        defaultOptions.push({
            value: item.customerId,
            label: '(' + tax + ')' + item.name

        });

    });
    //Select 快速搜尋
    const defaultOptionsEqpType = [];
    machBasic.basic.forEach(item => {
        defaultOptionsEqpType.push({
            value: item.id,
            label: item.serialNo,
            datakey: item.name,
            customAtrribute: item.type

        });
    });
    defaultOptionsEqpType.sort(function compareFn(a, b) {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
    });
    //欄位驗證-required
    const [validated, setValidated] = useState(false);
    //modal
    const [modalShow, setModalShow] = useState(false);



    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col md={6} className="mx-auto">
                        <Card className="mb-1" style={{ width: '100%', minWidth: '320px' }}>
                            <Card.Title>維護合約</Card.Title>
                            <Card.Body>
                                <Form noValidate validated={validated} onSubmit={(e) => {
                                    const from = e.currentTarget;
                                    e.preventDefault();//防止預設行為
                                    if (from.checkValidity() === false ||
                                        data.serialNo === '' || data.name === '0' || data.type === '0' || data.status === '0'
                                        || data.area === '0' || data.supplierID === '0') {
                                        e.stopPropagation();
                                    } else {

                                        setModalShow(true);
                                    }
                                    setValidated(true);

                                }}>

                                    <Row style={{ margin: '0 0 20px 0' }} >
                                        <Button onClick={close}>
                                            <FontAwesomeIcon className='rounded me-2' icon={faTimes} />
                                            關閉
                                        </Button>
                                    </Row>
                                    <Row>
                                        <Form.Group className="mb-4">
                                            <Form.Label column sm="4">
                                                <strong>合約編號</strong>
                                            </Form.Label>
                                            <Form.Control type="text" placeholder="" required disabled={isEdit}
                                                defaultValue={data.serialNo}
                                                onChangeCapture={(e) => {
                                                    setData({ ...data, serialNo: e.target.value });

                                                }}
                                            />

                                        </Form.Group>
                                        <Row>
                                            <Form.Group style={{ margin: '0', padding: '0' }} as={Row} className="mb-1">
                                                <Form.Label><strong>客戶資料</strong> </Form.Label>
                                                <Col sm="9">

                                                    {/* 快速搜尋 功能*/}
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        isSearchable="true"
                                                        value={defaultOptions[defaultOptions.findIndex((e) => e.value === data.customerID)]}
                                                        onChange={(newValue) => {
                                                            let val = newValue;
                                                            setData({ ...data, customerID: val.value });
                                                        }}
                                                        options={defaultOptions}
                                                    />
                                                </Col>

                                                <Col sm="1">
                                                    <Row className="mx=auto">
                                                        <Button variant="link" size="sm" onClick={custToggleEdit}>
                                                            <FontAwesomeIcon className='rounded' size="1x" icon={faPen} />
                                                        </Button>
                                                    </Row>
                                                </Col>
                                                <Col sm="2">
                                                    <Row className="mx-auto">
                                                        <Button variant="primary" onClick={custToggleAdd}>
                                                            <FontAwesomeIcon className='rounded' icon={faPlus} />
                                                        </Button>

                                                    </Row>
                                                </Col>

                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-4" >
                                                    <Form.Label column >
                                                        <strong>區域</strong>
                                                    </Form.Label>
                                                    <Form.Control required as="select" type="select" onChange={(e) => {
                                                        setData({
                                                            ...data,
                                                            area: e.target.value
                                                        });
                                                    }}
                                                        value={data.area}
                                                    >
                                                        <option key={0} data-key={0} value=''> 請選擇</option>
                                                        {eqpArea.map(item => {
                                                            return (<option key={item.configId} value={item.value} >{item.text}{item.comment}</option>)
                                                        })}
                                                    </Form.Control>

                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-4">
                                                    <Form.Label column >
                                                        <strong>合約/發票 抬頭</strong>
                                                    </Form.Label>

                                                    <Form.Control type="text" placeholder=""
                                                        defaultValue={data.title}
                                                        onChangeCapture={(e) => {
                                                            setData({ ...data, title: e.target.value });

                                                        }}
                                                    />

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col>

                                                <Form.Group className="mb-4">
                                                    <Form.Label column >
                                                        <strong>設備序號</strong>
                                                    </Form.Label>
                                                    {/* 快速搜尋 功能*/}
                                                    <Select
                                                        className="basic-single"
                                                        classNamePrefix="select"
                                                        isSearchable="true"
                                                        isClearable="true"
                                                        value={defaultOptionsEqpType[defaultOptionsEqpType.findIndex((e) => e.value === data.equipmentID)]}
                                                        onChange={(e) => {
                                                            setEqpType('');
                                                            if (e !== null) {
                                                                let EQPname_temp = e.datakey;
                                                                var customAtrribute = e.customAtrribute;
                                                                let EQPnameID_temp = 0;

                                                                eqpName.forEach((temp) => {
                                                                    if (temp.value === parseInt(EQPname_temp)) {
                                                                        EQPnameID_temp = temp.configId;
                                                                        return;
                                                                    }
                                                                });
                                                                eqpType.forEach((temp) => {

                                                                    if (temp.order === EQPnameID_temp) {
                                                                        if (temp.value === parseInt(customAtrribute)) {
                                                                            setEqpType(temp.text);
                                                                            return;
                                                                        }
                                                                    }
                                                                });
                                                            }
                                                            setData({
                                                                ...data,
                                                                equipmentID: e ? e.value : '0'
                                                            });
                                                        }}
                                                        options={defaultOptionsEqpType}
                                                    />
                                                </Form.Group>

                                            </Col>
                                            <Col>

                                                <Form.Group className="mb-4">
                                                    <Form.Label column >
                                                        <strong>機型</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" disabled
                                                        defaultValue={_eqpType}
                                                    />
                                                </Form.Group>

                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col >
                                                <Button variant="primary" onClick={ContrEQPToggleAdd}>
                                                    <FontAwesomeIcon className='rounded' icon={faPlus} />
                                                </Button>
                                                <Button className="ms-5" onClick={onRemoveSelected}>刪除</Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="ag-theme-alpine" style={{ height: 200, width: '100%' }}>
                                                <AgGridReact
                                                    ref={gridRef}
                                                    rowStyle={rowStyle}
                                                    rowData={data.contrEQP}
                                                    defaultColDef={{
                                                        sortable: true,
                                                        resizable: true,
                                                        wrapText: true,
                                                        autoHeight: true,
                                                        filter: true
                                                    }}
                                                    onGridReady={(params) => {
                                                        setState({
                                                            ...state,
                                                            gridApi: params.api
                                                        });

                                                    }}
                                                    rowSelection={'multiple'}
                                                >
                                                    <AgGridColumn headerName="設備編號" field="equipmentID" width={130} valueGetter={(x) => {
                                                        let item = machBasic.basic.find((e) => e.id === parseInt(x.data.equipmentID));
                                                        return item.serialNo;
                                                    }}></AgGridColumn>
                                                    <AgGridColumn headerName="機型" field="eqpType" width={180}></AgGridColumn>
                                                </AgGridReact>
                                            </div>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-4">
                                                    <Form.Label column >
                                                        <strong>簽約日期</strong>
                                                    </Form.Label>
                                                    <DatePicker className="form-control"
                                                        locale="zh-TW"
                                                        dateFormat="yyyy/MM/dd"
                                                        selected={
                                                            data.date == null ? data.date : new Date(data.date)
                                                        }
                                                        onChange={(e) => {
                                                            setData({
                                                                ...data,
                                                                date: e
                                                            });
                                                        }}
                                                    />

                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-4">
                                                    <Form.Label column >
                                                        <strong>合約類別</strong>
                                                    </Form.Label>
                                                    <Form.Control required as="select" type="select" onChange={(e) => {
                                                        setData({
                                                            ...data,
                                                            category: e.target.value
                                                        });
                                                    }}
                                                        value={data.category}
                                                    >
                                                        <option key={0} data-key={0} value=''> 請選擇</option>
                                                        {maintContractJobClass.map(item => {
                                                            return (<option key={item.configId} value={item.value} >{item.text}{item.comment}</option>)
                                                        })}
                                                    </Form.Control>

                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-4">
                                                    <Form.Label column >
                                                        <strong>合約起始日期</strong>
                                                    </Form.Label>
                                                    <DatePicker className="form-control" required
                                                        dateFormat="yyyy/MM/dd"
                                                        selected={
                                                            data.contracStartDate == null ? data.contracStartDate : new Date(data.contracStartDate)
                                                        }
                                                        onChange={(e) => {
                                                            setData({
                                                                ...data,
                                                                contracStartDate: e
                                                            });
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col sm="1" className="styled-div">
                                                <strong>~</strong>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-4">
                                                    <Form.Label column >
                                                        <strong>合約結束日期</strong>
                                                    </Form.Label>
                                                    <DatePicker className="form-control" required
                                                        dateFormat="yyyy/MM/dd"
                                                        selected={
                                                            data.contracEndDate == null ? data.contracEndDate : new Date(data.contracEndDate)
                                                        }
                                                        onChange={(e) => {
                                                            setData({
                                                                ...data,
                                                                contracEndDate: e
                                                            });
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-4">
                                                    <Form.Label column >
                                                        <strong>付款方式</strong>
                                                    </Form.Label>
                                                    <Form.Control required as="select" type="select" onChange={(e) => {
                                                        setData({
                                                            ...data,
                                                            payment: e.target.value
                                                        });
                                                    }}
                                                        value={data.payment}
                                                    >
                                                        <option key={0} data-key={0} value=''> 請選擇</option>
                                                        {maintContractPayment.map(item => {
                                                            return (<option key={item.configId} value={item.value} >{item.text}{item.comment}</option>)
                                                        })}
                                                    </Form.Control>

                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-4">
                                                    <Form.Label column >
                                                        <strong>合約金額</strong>
                                                    </Form.Label>

                                                    <Form.Control type="text" placeholder=""
                                                        defaultValue={data.contractAmount}
                                                        onChangeCapture={(e) => {
                                                            let newStr = e.target.value.replaceAll(",", "");
                                                            setData({ ...data, contractAmount: newStr });

                                                        }}
                                                    />

                                                </Form.Group>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-4">
                                                    <Form.Label column >
                                                        <strong>服務內容</strong>
                                                    </Form.Label>
                                                    <Form.Control type="text" as="textarea"
                                                        defaultValue={data.content}
                                                        onChangeCapture={(e) => {
                                                            setData({ ...data, content: e.target.value });

                                                        }}
                                                    />

                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Form.Group className="mb-4">
                                            <Form.Label column >
                                                <strong>備註</strong>
                                            </Form.Label>

                                            <Form.Control type="text" as="textarea"
                                                defaultValue={data.remark}
                                                onChangeCapture={(e) => {
                                                    setData({ ...data, remark: e.target.value });

                                                }}
                                            />

                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group>
                                            <Row className="mx-auto">
                                                <Button
                                                    variant="primary"
                                                    type="submit"
                                                >
                                                    <FontAwesomeIcon className='rounded me-2' icon={faSave} />
                                                    儲存
                                                </Button>

                                            </Row>
                                        </Form.Group>
                                    </Row>


                                </Form>
                                <CenteredModalYesNo
                                    title={'存檔'}
                                    message={'是否存檔?'}
                                    btnText1={'存檔'}
                                    btnText2={'取消'}
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    onClick={() => { insertOrUpdateData(data); setModalShow(false); }}
                                />

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </React.Fragment>

    )
}







