import { apiConfigQuery, apiConfigInsert } from '../../utils/api.js';

export const initConfig = () => async (dispatch) => {
    let resp = await apiConfigQuery({});
    if (resp.status !== 200) {
        dispatch(initState);
    }
    let data = JSON.parse(resp.request.response);
    dispatch(renConfig(data));
}

export const configInsert = (data) => async (dispatch) => {
    let res = await apiConfigInsert(data);
    if (res.status !== 200) {
        return;
    }
    dispatch(await initConfig());
}

const typeRenConfig = 'renConfig';
export const renConfig = (data) => {
    return {
        type: typeRenConfig,
        status: data.status,
        degree: data.degree,
        progress: data.progress,
        level: data.level,
        jobclass: data.jobclass,
        preSaleEquip: data.preSaleEquip,
        admin: data.admin,
        eqpName: data.eqpName,
        eqpType: data.eqpType,
        eqpStatus: data.eqpStatus,
        eqpArea: data.eqpArea,
        maintJobClass: data.maintJobClass,
        maintStatus: data.maintStatus,
        maintContractJobClass: data.maintContractJobClass,
        maintContractPayment: data.maintContractPayment
    }
}

const initState = {
    status: [],
    degree: [],
    progress: [],
    level: [],
    jobclass: [],
    preSaleEquip: [],
    admin: [],
    eqpName: [],
    eqpType: [],
    eqpStatus: [],
    eqpArea: [],
    maintJobClass: [],
    maintStatus: [],
    maintContractJobClass: [],
    maintContractPayment: []

};
export const reducer = (config = initState, action) => {

    switch (action.type) {
        case typeRenConfig:
            return {
                status: action.status,
                degree: action.degree,
                progress: action.progress,
                level: action.level,
                jobclass: action.jobclass,
                preSaleEquip: action.preSaleEquip,
                admin: action.admin,
                eqpName: action.eqpName,
                eqpType: action.eqpType,
                eqpStatus: action.eqpStatus,
                eqpArea: action.eqpArea,
                maintJobClass: action.maintJobClass,
                maintStatus: action.maintStatus,
                maintContractJobClass: action.maintContractJobClass,
                maintContractPayment: action.maintContractPayment
            };
        default:
            return config;
    }
};