import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Row, Button, Container, FormControl, InputGroup, Card, Collapse, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus, faChevronDown, faSignOutAlt, faFileExport, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { Link } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import 'react-dropdown-tree-select/dist/styles.css'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useSelector, useDispatch } from 'react-redux';
import { basicQuery, basicDelete } from '../../../../action/maintain/basicAction.js';

import { useRWD, devicesIsMobile } from '../../../../useRWD.js';
import { store } from 'react-notifications-component';
import { useHistory } from "react-router-dom";
import '../../../Maintain/BtnforPhone.css';
import { exportExcelMethod } from '../../../../exportExcel.js';

const queryParam = {
    keyword: null
};

const completeMessage = {
    title: "搜尋",
    message: "完成",
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 3000,
        onScreen: true
    }
};
export const MachBasic = (props) => {

    const dispatch = useDispatch();
    const rowStyle = {
        cursor: "pointer",
    };
    const [state, setState] = useState({ gridApi: null });
    const { eqpName, eqpType, eqpStatus } = useSelector(x => x.config);
    const { machBasic, customers, organization, machHandle } = useSelector(x => x);
    const { MachBasicAdd, MachBasicEdit, MachDelete } = useSelector(x => x.uiMach);
    let filteredData = machBasic.basic;

    const [data, setData] = useState(queryParam);
    const handleLoadingUI = (isShow) => { if (isShow === true) { dispatch({ type: 'SHOW_LOADING' }); } else { dispatch({ type: 'HIDE_LOADING' }); } }//show laod UI
    useEffect(() => {
        // Create an async function within our effect:
        async function runEffect() {
            handleLoadingUI(true);
            await dispatch(basicQuery());
            handleLoadingUI(false);
        }
        // Immediately call this function to run the effect:
        runEffect();

    }, []) //  eslint-disable-line react-hooks/exhaustive-deps

    //匯出excel
    const gridRef = useRef();
    const onBtnExport = useCallback((params) => {
        agGridDataHandle(gridRef, customers, eqpName, eqpType, machHandle, eqpStatus, organization);
    }, []);//  eslint-disable-line react-hooks/exhaustive-deps
    //依據視窗大小調整 高度
    let device = useRWD();
    device = (device + 80) + 'px';
    let isMoble = devicesIsMobile();//判斷手機
    return (
        <React.Fragment>
            <Container fluid="mx-0">
                <Row className="mx-0">
                    <Row className="mx-0">
                        <InputGroup className="mb-3">
                            {!MachBasicAdd.disabled &&
                                <Link to="/machBasicEdit/0">
                                    <Button style={{ background: '#00A2FF' }} className="btn-custom-phone">
                                        <FontAwesomeIcon className='rounded me-2' icon={faPlus} />
                                        新增
                                    </Button>
                                </Link>
                            }
                            <FormControl
                                type="search"
                                placeholder="輸入關鍵字 : "
                                aria-label="Search"
                                size="1g"
                                className="keyword-bar"
                                defaultValue={queryParam.keyword}
                                onChange={(e) => setData({ ...queryParam, keyword: e.target.value })}
                            />
                            <Button variant="primary" className="btn-custom-phone" onClick={() => {
                                dispatch(basicQuery(data));
                                completeMessage.title = "搜尋";
                                store.addNotification(completeMessage);
                            }}>
                                <FontAwesomeIcon className='rounded' icon={faSearch} />
                                搜尋
                            </Button>
                            <Button variant="outline-primary" className="btn-custom-phone" onClick={() => { onBtnExport(); }}>
                                <FontAwesomeIcon className='rounded me-2' icon={faFileExport} />
                                匯出
                            </Button>
                        </InputGroup>
                    </Row>
                    <Row className="mx-0">
                        {isMoble &&
                            <MobileSearchUI />
                        }
                        {!isMoble &&
                            <div className="ag-theme-alpine" style={{ height: device, width: '100%' }}>

                                <AgGridReact
                                    ref={gridRef}
                                    rowData={filteredData}
                                    rowStyle={rowStyle}
                                    defaultColDef={{
                                        sortable: true,
                                        resizable: true,
                                        wrapText: true,
                                        autoHeight: true,
                                        filter: true
                                    }}
                                    onGridReady={(params) => {
                                        setState({
                                            ...state,
                                            gridApi: params.api
                                        });
                                    }}
                                    paginationAutoPageSize={true}
                                    pagination={true}
                                >
                                    {!MachDelete.disabled &&
                                        <AgGridColumn headerName="" field="id" cellRendererFramework={DeleteRenderer} width={60} pinned="left"></AgGridColumn>
                                    }
                                    <AgGridColumn headerName="No." field="id" width={80} pinned="left"></AgGridColumn>
                                    {!MachBasicEdit.disabled &&
                                        <AgGridColumn headerName="編輯" field="id" cellRendererFramework={EditRenderer} width={100} pinned="left"></AgGridColumn>
                                    }
                                    <AgGridColumn headerName="客戶名稱" field="customerId" width={150} valueGetter={(x) => {
                                        let item = customers.find((e) => e.customerId === x.data.customerId);
                                        return item === undefined ? null : item.name;
                                    }}></AgGridColumn>
                                    <AgGridColumn headerName="廠牌" field="name" width={130} valueGetter={(x) => {
                                        let item = eqpName.find((e) => e.value === x.data.name);
                                        return item.text;
                                    }}></AgGridColumn>
                                    {/*   eslint-disable react/jsx-no-duplicate-props */}
                                    <AgGridColumn headerName="機型" field="name" field="type" width={150} valueGetter={(x) => {
                                        let itemName = eqpName.find((e) => e.value === x.data.name);
                                        let item = eqpType.find((e) => itemName.configId === e.order && e.value === x.data.type);
                                        return item.text;
                                    }}></AgGridColumn>
                                    {/*  eslint-enable react/jsx-no-duplicate-props */}
                                    <AgGridColumn headerName="設備序號" field="serialNo" width={120}></AgGridColumn>
                                    <AgGridColumn headerName="手握把序號" field="id" valueGetter={(x) => {
                                        let item = '';
                                        machHandle.handle.forEach((y) => {
                                            if (y.equipmentID === x.data.id) {
                                                item += y.serialNo + ',';
                                            }
                                        })
                                        return item;
                                    }}></AgGridColumn>
                                    <AgGridColumn headerName="狀態" field="status" width={80} valueGetter={(x) => {
                                        let item = eqpStatus.find((e) => e.value === x.data.status);
                                        return item.text;
                                    }}></AgGridColumn>
                                    <AgGridColumn headerName="進貨日期" field="restockDate" width={150} cellRenderer={(x) => {
                                        return x.data.restockDate ? x.data.restockDate.replace('T', ' ').substr(0, 10) : '';
                                    }}></AgGridColumn>
                                    <AgGridColumn headerName="交機日期" field="deliveryDate" width={150} cellRenderer={(x) => {
                                        return x.data.deliveryDate ? x.data.deliveryDate.replace('T', ' ').substr(0, 10) : '';
                                    }}></AgGridColumn>
                                    <AgGridColumn headerName="備註" field="remark" width={200} ></AgGridColumn>
                                    <AgGridColumn headerName="更新人員" field="updatedBy" width={120} valueGetter={(x) => {
                                        let item = organization.allMembers.find((e) => e.ID === x.data.updatedBy);
                                        return item === undefined ? null : item.Name;
                                    }}></AgGridColumn>
                                    <AgGridColumn headerName="更新時間" field="updatedDate" width={200} cellRenderer={(x) => {
                                        return x.data.updatedDate ? x.data.updatedDate.replace('T', ' ').substr(0, 19) : '';
                                    }}></AgGridColumn>
                                </AgGridReact>
                            </div>
                        }
                    </Row>

                </Row>
            </Container>
        </React.Fragment>
    );

}

export const EditRenderer = (props) => {

    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const hist = useHistory();
    const EditMethod = () => {
        let id = cellValue;
        hist.push(`/machBasicEdit/${id}`);
    }

    return (
        <span>
            <button
                style={{ height: 30, lineHeight: 0.5, fontWeight: 'bold' }}
                className="btn btn-link"
                onClick={() => {
                    EditMethod();
                }}
            >
                編輯
            </button>
        </span>
    );
};
export const DeleteRenderer = (props) => {

    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const dispatch = useDispatch();
    const { machBasic } = useSelector(x => x);
    const DeleteMethod = async () => {
        let id = cellValue;
        const MachBasicData = machBasic.basic.find(e => e.id == id);// eslint-disable-line eqeqeq

        await dispatch(basicDelete(MachBasicData));

    }

    return (
        <span>
            <button
                style={{ height: 25, width: 25, padding: 0 }}
                className="btn btn-danger"
                onClick={() => {
                    DeleteMethod();
                }}
            >
                <FontAwesomeIcon icon={faTrashAlt} />
            </button>
        </span>
    );
};
export const MobileSearchUI = (props) => {
    const { eqpName, eqpType, eqpStatus } = useSelector(x => x.config);
    const { machBasic, customers, organization, machHandle } = useSelector(x => x);
    let filteredData = machBasic.basic;
    const hist = useHistory();
    const EditMethod = (id) => {
        hist.push(`/machBasicEdit/${id}`);
    }

    const [expandedId, setExpandedId] = useState(null);

    const handleExpandClick = (id) => {
        setExpandedId(expandedId === id ? null : id);
    };

    return (
        <div style={{ width: '100%' }}>
            {
                filteredData.map((data, index) => {
                    let _customer = customers.find((e) => e.customerId === data.customerId);
                    let _eqpName = eqpName.find((e) => e.value === data.name);
                    let _eqpType = eqpType.find((e) => _eqpName.configId === e.order && e.value === data.type);
                    let _machHandle = '';
                    machHandle.handle.forEach((y) => {
                        if (y.equipmentID === data.id) {
                            _machHandle += y.serialNo + ',';
                        }
                    });
                    let _eqpStatus = eqpStatus.find((e) => e.value === data.status);
                    let _restockDate = data.restockDate ? data.restockDate.replace('T', ' ').substr(0, 10) : '';
                    let _deliveryDate = data.deliveryDate ? data.deliveryDate.replace('T', ' ').substr(0, 10) : '';
                    let _organization = organization.allMembers.find((e) => e.ID === data.updatedBy);
                    let _updatedDate = data.updatedDate ? data.updatedDate.replace('T', ' ').substr(0, 10) : '';
                    return (

                        <Card key={index} style={{ borderRadius: '20px', margin: '5px' }}>
                            <Card.Body style={{ padding: '8px' }}>

                                <Row>
                                    <Card.Title style={{ fontSize: '14px' }}>設備序號:{data.serialNo}</Card.Title>
                                    <Col>
                                        <Card.Text style={{ fontSize: '12px' }}>
                                            客戶名稱:{_customer === undefined ? '' : _customer.name}<br />
                                            廠牌:{_eqpName.text}&nbsp;
                                            機型:{_eqpType.text}<br />
                                            <Button style={{ display: 'contents', lineHeight: '0rm' }}
                                                variant="link"
                                                onClick={() => handleExpandClick(data.id)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={expandedId === data.id}
                                            >
                                                <FontAwesomeIcon icon={faChevronDown} />
                                            </Button>
                                        </Card.Text>
                                    </Col>
                                    <Col xs={2}>
                                        <Button variant="link" style={{ fontSize: '20px' }} onClick={() => {
                                            EditMethod(data.id);
                                        }}>
                                            <FontAwesomeIcon icon={faSignOutAlt} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>

                            <Collapse in={expandedId === data.id}  >
                                <Card.Text style={{ fontSize: '12px', padding: '5px' }}>
                                    手握把序號:{_machHandle}<br />
                                    狀態:{_eqpStatus.text}<br />
                                    進貨日期:{_restockDate}&nbsp;
                                    交機日期:{_deliveryDate}<br />
                                    備註:{data.remark}<br />
                                    更新人員:{_organization === undefined ? '' : _organization.Name}&nbsp;
                                    更新時間:{_updatedDate}
                                </Card.Text>
                            </Collapse>

                        </Card>
                    )
                })
            }

        </div>
    );
}

//匯出資料處理
const agGridDataHandle = (gridRef, customers, eqpName, eqpType, machHandle, eqpStatus, organization) => {
    let columns_array = [];
    let rows_array = [];
    //表標頭headerName
    const _columnApi = gridRef.current.api.columnModel.columnApi.getAllDisplayedColumns();
    _columnApi.forEach((item, index) => {
        if (item.userProvidedColDef.headerName !== '編輯') {
            const excelTitle = {
                name: '',
            };
            if (index === 0) {
                excelTitle.name = 'No.';
            } else {
                excelTitle.name = item.userProvidedColDef.headerName;
            }
            columns_array.push(excelTitle);
        }
    })
    //表資料
    gridRef.current.api.forEachNode((node, index) => {
        let item = customers.find((e) => e.customerId === node.data.customerId);
        let customerId = item === undefined ? null : item.name;
        item = eqpName.find((e) => e.value === node.data.name);
        let name = item === undefined ? null : item.text;
        item = eqpType.find((e) => item.configId === e.order && e.value === node.data.type);
        let type = item === undefined ? null : item.text;
        let handSerialNo = '';
        machHandle.handle.forEach((y) => {
            if (y.equipmentID === node.data.id) {
                handSerialNo += y.serialNo + ',';
            }
        })
        item = eqpStatus.find((e) => e.value === node.data.status);
        let status = item === undefined ? null : item.text;
        let restockDate = node.data.restockDate ? node.data.restockDate.replace('T', ' ').substr(0, 10) : '';
        let deliveryDate = node.data.deliveryDate ? node.data.deliveryDate.replace('T', ' ').substr(0, 10) : '';
        item = organization.allMembers.find((e) => e.ID === node.data.updatedBy);
        let updatedBy = item === undefined ? null : item.Name;
        let updatedDate = node.data.updatedDate ? node.data.updatedDate.replace('T', ' ').substr(0, 19) : '';

        let temp = [];
        temp[0] = index + 1;
        temp[1] = customerId;
        temp[2] = name;
        temp[3] = type;
        temp[4] = node.data.serialNo;
        temp[5] = handSerialNo;
        temp[6] = status;
        temp[7] = restockDate;
        temp[8] = deliveryDate;
        temp[9] = node.data.remark;
        temp[10] = updatedBy;
        temp[11] = updatedDate;
        rows_array.push(temp);
    });
    //匯出Excel
    exportExcelMethod(columns_array, rows_array, '機台基本資料');
}