import React, {  } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from "react-router-dom";
import { Card, Button, Offcanvas, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useRWD } from '../../../../useRWD.js';


export const MachReportDetail = (props) => {
    const { id } = useParams();
    const history = useHistory();
    const close = () => {
        history.push(`/reportTab/machReport/0`);
    }
    
    const { eqpName, maintStatus, maintJobClass } = useSelector(x => x.config);
    const { maintReport, machBasic, organization } = useSelector(x => x);
    

    let basicID = machBasic.basic.find((e) => e.id === parseInt(id));
    let item = eqpName.find((e) => e.value === basicID.name);
    const name = item.text;
    const serialNo = basicID.serialNo;

    const data = maintReport.machReportDetail.filter(x => x.equipmentID === parseInt(id));
   
   
    //依據視窗大小調整 高度
    let device = useRWD();
    device = (device + 75) + 'px';

    return (

        <React.Fragment >
            <Offcanvas
                show={true}
                onHide={props.onHide}
                placement="end"
                style={{ minWidth: '375px', width: '100%' }}
            >
                <Offcanvas.Header className="bg-primary text-light">
                    <Offcanvas.Title>
                        <FontAwesomeIcon className='rounded me-2' icon={faSearch} />
                        {serialNo} --- {name}
                    </Offcanvas.Title>
                    <Button onClick={close}>
                        <FontAwesomeIcon className='rounded me-2' icon={faTimes} />
                        關閉
                    </Button>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Container fluid>
                        <Card className="mb-1" style={{ width: '100%', minWidth: '312px' }}>
                            <Card.Body>
                                <div className="ag-theme-alpine" style={{ height: device, width: '100%' }}>
                                    <AgGridReact
                                        rowData={data}

                                        defaultColDef={{
                                            sortable: true,
                                            resizable: true,
                                            wrapText: true,
                                            autoHeight: true,
                                            filter: true
                                        }}
                                    >
                                        <AgGridColumn headerName="維修人員" field="employee" width={120} valueGetter={(x) => {
                                            let item = organization.allMembers.find((e) => e.ID === x.data.employee);
                                            return item.Name;
                                        }}></AgGridColumn>
                                        <AgGridColumn headerName="維修日期" field="date" width={120} cellRenderer={(x) => {
                                            return x.data.date ? x.data.date.replace('T', ' ').substr(0, 10) : '';
                                        }}></AgGridColumn>
                                        <AgGridColumn headerName="維修內容分類" field="category" width={130} valueGetter={(x) => {
                                            let item = maintJobClass.find((e) => e.value === x.data.category);
                                            return item.text;
                                        }}></AgGridColumn>
                                        <AgGridColumn headerName="維修內容說明" field="content" width={150} ></AgGridColumn>
                                        <AgGridColumn headerName="維修狀態說明" field="maintStatus" width={130} valueGetter={(x) => {
                                            let item = maintStatus.find((e) => e.value === x.data.maintStatus);
                                            return item.text;
                                        }}></AgGridColumn>
                                        <AgGridColumn headerName="維修結果/建議" field="mainResults" width={150} ></AgGridColumn>
                                        <AgGridColumn headerName="車程(分)" field="transTime" width={110} ></AgGridColumn>
                                        <AgGridColumn headerName="工時(時)" field="workingHours" width={110} ></AgGridColumn>
                                    </AgGridReact>
                                </div>
                            </Card.Body>
                        </Card>

                    </Container>
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>

    )
}
