import { apiOrgStruct, apiOrgMembers, apiOrgDepartments } from '../../utils/api.js';

export const initOrganizationStructure = (DepartmentID) => (dispatch, getState) => {
    const initParam = { ID: DepartmentID, Code: "-1" };

    dispatch(requestStructure(initParam));
    dispatch(requestMembners(initParam));

    dispatch(requestDepartment());
    dispatch(setUserVisible());
};

const requestStructure = (param) => async (dispatch, getState) => {
    let resp = await apiOrgStruct(param);
    let json = JSON.parse(resp.request.response);
    await dispatch(setUserVisible());//確認是否有更新 目前使用者權限樹
    dispatch(renStructure(json));
}

const typeRenStructure = 'renStructure';
export const renStructure = (resp) => {
    return {
        type: typeRenStructure,
        structure: resp,
    };
}

const emptyMember = {
    ID: "-1",
    Name: "(empty)",
    DepartmentID: 0
};

export const findMember = (userId) => (dispatch, getState) => {
    let state = getState();
    let user = state.organization.allMembers.find(x => x.ID === userId);
    if (user === undefined) {
        return emptyMember;
    }
    return user;
}

export const getSubDepartments = (currentDepartment, departments) => {
    const { LevelCode } = departments.find(x => x.ID === currentDepartment);
    var res = departments
        .filter(x => x.LevelCode.includes(LevelCode))
        .map(x => x.ID);

    return res;
}


export const setUserVisible = () => (dispatch, getState) => {
    let state = getState();
    let user = state.currentUser.userID;
    let structure = state.organization.structure;
    //���N�Ҧ�Node��visible�]��false
    setNodeVisible(structure, false);
    //��DFS��MCurrent User��Node�A�M��N��visible�]��true�A�A�N��children�]��visible�]��true
    searchUserNodes(structure, user);
    dispatch(renStructure(structure));
}

const searchUserNodes = (tree, user) => {
    if (tree !== null) {
        if (tree.length > 0) {
            tree.forEach(node => {
                if (node.value === user) {
                    node.visible = true;
                    setNodeVisible(node.children, true);
                }
                else {
                    searchUserNodes(node.children, user);
                }
            })
        }
    }
}

const setNodeVisible = (tree, value) => {
    if (tree !== null) {
        if (tree.length > 0) {
            tree.forEach(node => {
                node.visible = value;
                setNodeVisible(node.children, value);
            })
        }
    }
}


export const updateStructureChecked = (isUser = false, assistingBy = []) => (dispatch, getState) => {
    let state = getState();
    let user = state.currentUser.userID;
    let structure = state.organization.structure;

    structure.forEach(node => {
        clearTree(node);
    })

    if (isUser) {
        assistingBy.push(user);
    }
    if (assistingBy !== null) {
        if (assistingBy.length > 0) {
            structure.forEach(node => {
                updateTree(assistingBy, node);
            });
        }
    }
}

const clearTree = (root) => {
    root.checked = false;

    if (root.children !== null) {
        root.children.forEach(node => clearTree(node));
    }
}

const updateTree = (assistingBy, root) => {
    let isMatch = assistingBy.includes(root.value);
    if (isMatch) {
        root.checked = true;
    }

    if (root.children !== null) {
        root.children.forEach(node => updateTree(assistingBy, node));
    }
}

const typeRenAllMembers = 'renAllMembers';
const typeRenMembers = 'renMember';
export const requestMembners = (param = {}) => async (dispatch, getState) => {
  
    let resp = await apiOrgMembers(param);
    let json = JSON.parse(resp.request.response);
    dispatch({
        type: typeRenMembers,
        members: json
    });
    resp = await apiOrgMembers({ ID: "-1", Code: "-1" });
    json = JSON.parse(resp.request.response);
    dispatch({
        type: typeRenAllMembers,
        members: json
    });
}


const typeRenDepartments = 'renDepartments';
export const requestDepartment = () => async (dispatch, getState) => {
    let resp = await apiOrgDepartments();
    let json = JSON.parse(resp.request.response);
    dispatch({
        type: typeRenDepartments,
        departments: json
    });
}




const initState = {
    structure: [],
    members: [],
    departments: [],
    allMembers: []
}


export const reducer = (state = initState, action) => {

    switch (action.type) {
        case typeRenStructure:
            return { ...state, structure: [...action.structure] };

        case typeRenMembers:
            return { ...state, members: [...action.members] };

        case typeRenAllMembers:
            return { ...state, allMembers: action.members };

        case typeRenDepartments:
            return { ...state, departments: action.departments };
        default:
    }
    return state;
}
