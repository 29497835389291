import { apiProductSettingsQuery, apiProductSettingsInsert, apiProductSettingsUpdate } from '../../utils/api.js';


export const productSettingsQuery = (newProductSettings) => async (dispatch, getState) => {
    let res = await apiProductSettingsQuery(newProductSettings);
    let data = JSON.parse(res.request.response);
    dispatch(renProductSettings(data));
}

export const productSettingsInsert = (newProductSettings) => async (dispatch, getState) => {
    let res = await apiProductSettingsInsert(newProductSettings);
    if (res.status !== 200) {
        return;
    }
    dispatch(await productSettingsQuery());
}

export const productSettingsUpdate = (newUserGroup) => async (dispatch, getState) => {
    let res = await apiProductSettingsUpdate(newUserGroup);
    if (res.status !== 200) {
        return;
    }
    dispatch(await productSettingsQuery());
}




const typeRenProductSettings = 'typeRenProductSettings';
const renProductSettings = (arrProductSettings) => {

    return {
        type: typeRenProductSettings,
        productSettings: arrProductSettings
    }
}


const initState = {
    productSettings: []
}


export const reducer = (state = initState, action) => {
    switch (action.type) {
        case typeRenProductSettings:
            return { ...state, productSettings: [...action.productSettings] };
        
        default:
            return state;
    }
}