import React, { useState, useEffect } from 'react';
import { Row, Button, Container, FormControl, InputGroup, Card, Collapse, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus, faChevronDown, faSignOutAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { Link } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import 'react-dropdown-tree-select/dist/styles.css'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useSelector, useDispatch } from 'react-redux';
import { handleQuery, handleDelete } from '../../../../action/maintain/handleAction.js';


import { useRWD, devicesIsMobile } from '../../../../useRWD.js';
import { store } from 'react-notifications-component';
import { useHistory } from "react-router-dom";
import '../../../Maintain/BtnforPhone.css';

const queryParam = {
    keyword: null
};

const completeMessage = {
    title: "搜尋",
    message: "完成",
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 3000,
        onScreen: true
    }
};
export const MachHandle = (props) => {

    const dispatch = useDispatch();
    const rowStyle = {
        cursor: "pointer",
    };
    const [state, setState] = useState({ gridApi: null });


    const { organization, machHandle, machBasic, customers } = useSelector(x => x);
    const { MachHandleAdd, MachHandleEdit, MachDelete } = useSelector(x => x.uiMach);

    let filteredData = machHandle.handle;

    const [data, setData] = useState(queryParam);
    const handleLoadingUI = (isShow) => { if (isShow === true) { dispatch({ type: 'SHOW_LOADING' }); } else { dispatch({ type: 'HIDE_LOADING' }); } }//show laod UI
    /* eslint-disable  */
    useEffect(() => {

        queryParam.keyword = props.id === '0' ? '' : props.id;

        // Create an async function within our effect:
        async function runEffect() {
            handleLoadingUI(true);
            if (props.id !== '0') {
                await dispatch(handleQuery(data));
            } else {
                await dispatch(handleQuery());
            }
            handleLoadingUI(false);
        }
        // Immediately call this function to run the effect:
        runEffect();

    }, []);
    /* eslint-enable  */


    //依據視窗大小調整 高度
    let device = useRWD();
    device = (device + 80) + 'px';
    let isMoble = devicesIsMobile();//判斷手機
    return (
        <React.Fragment>
            <Container fluid="mx-0">
                <Row className="mx-0">
                    <Row className="mx-0">
                        <InputGroup className="mb-3">
                            {!MachHandleAdd.disabled &&
                                <Link to="/machHandleEdit/0">
                                    <Button style={{ background: '#00A2FF' }} className="btn-custom-phone">
                                        <FontAwesomeIcon className='rounded me-2' icon={faPlus} />
                                        新增
                                    </Button>
                                </Link>
                            }
                            <FormControl
                                type="search"
                                placeholder="輸入關鍵字 : "
                                aria-label="Search"
                                size="1g"
                                className="keyword-bar"
                                defaultValue={queryParam.keyword}
                                onChange={(e) => setData({ ...queryParam, keyword: e.target.value })}
                            />
                            <Button variant="primary" className="btn-custom-phone" onClick={() => {
                                dispatch(handleQuery(data));
                                completeMessage.title = "搜尋";
                                store.addNotification(completeMessage);
                            }}>
                                <FontAwesomeIcon className='rounded' icon={faSearch} />
                                搜尋
                            </Button>
                        </InputGroup>
                    </Row>

                    <Row className="mx-0">
                        {isMoble &&
                            <MobileSearchUI />
                        }
                        {!isMoble &&
                            <div className="ag-theme-alpine" style={{ height: device, width: '100%' }}>

                                <AgGridReact
                                    rowData={filteredData}
                                    rowStyle={rowStyle}
                                    defaultColDef={{
                                        sortable: true,
                                        resizable: true,
                                        wrapText: true,
                                        autoHeight: true,
                                        filter: true
                                    }}
                                    onGridReady={(params) => {
                                        setState({
                                            ...state,
                                            gridApi: params.api
                                        });
                                    }}


                                    paginationAutoPageSize={true}
                                    pagination={true}
                                >
                                    {!MachDelete.disabled &&
                                        <AgGridColumn headerName="" field="id" cellRendererFramework={DeleteRenderer} width={60} pinned="left"></AgGridColumn>
                                    }
                                    <AgGridColumn headerName="No." field="id" width={80} pinned="left"></AgGridColumn>
                                    {!MachHandleEdit.disabled &&
                                        <AgGridColumn headerName="編輯" field="id" cellRendererFramework={EditRenderer} width={100} pinned="left"></AgGridColumn>
                                    }
                                    <AgGridColumn headerName="手握把序號" field="serialNo" width={150}></AgGridColumn>
                                    <AgGridColumn headerName="綁定設備序號" field="equipmentID" width={150} valueGetter={(x) => {
                                        let item = machBasic.basic.find((e) => e.id === x.data.equipmentID);
                                        return item === undefined ? null : item.serialNo;
                                    }}></AgGridColumn>
                                    <AgGridColumn headerName="發數/使用時數" field="numberOfShots" width={150} ></AgGridColumn>
                                    <AgGridColumn headerName="客戶名稱" field="customerID" width={150} valueGetter={(x) => {
                                        let item = customers.find((e) => e.customerId === x.data.customerID);
                                        return item === undefined ? null : item.name;
                                    }}></AgGridColumn>
                                    <AgGridColumn headerName="使用狀態" field="flag" width={150} valueGetter={(x) => {
                                        let item = '使用中';
                                        if (x.data.flag === 0) { item = '已使用完'; } else if (x.data.flag === 2) { item = '其他'; }
                                        return item;
                                    }}></AgGridColumn>
                                    <AgGridColumn headerName="備註" field="remark" width={300} ></AgGridColumn>
                                    <AgGridColumn headerName="更新人員" field="updatedBy" width={120} valueGetter={(x) => {
                                        let item = organization.allMembers.find((e) => e.ID === x.data.updatedBy);
                                        return item === undefined ? null : item.Name;
                                    }}></AgGridColumn>
                                    <AgGridColumn headerName="更新時間" field="updatedDate" width={200} cellRenderer={(x) => {
                                        return x.data.updatedDate ? x.data.updatedDate.replace('T', ' ').substr(0, 19) : '';
                                    }}></AgGridColumn>
                                </AgGridReact>
                            </div>
                        }
                    </Row>

                </Row>
            </Container>
        </React.Fragment>
    );

}

export const EditRenderer = (props) => {

    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const hist = useHistory();
    const EditMethod = () => {
        let id = cellValue;
        hist.push(`/machHandleEdit/${id}`);


    }

    return (
        <span>
            <button
                style={{ height: 30, lineHeight: 0.5, fontWeight: 'bold' }}
                className="btn btn-link"
                onClick={() => {
                    EditMethod();
                }}
            >
                編輯
            </button>
        </span>
    );
};
export const DeleteRenderer = (props) => {

    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const dispatch = useDispatch();
    const {  machHandle } = useSelector(x => x);
    const DeleteMethod = async () => {
        let id = cellValue;
         //eslint-disable-next-line eqeqeq
        const HandleData = machHandle.handle.find(e => e.id == id);

        await dispatch(handleDelete(HandleData));

    }

    return (
        <span>
            <button
                style={{ height: 25, width: 25, padding: 0 }}
                className="btn btn-danger"
                onClick={() => {
                    DeleteMethod();
                }}
            >
                <FontAwesomeIcon icon={faTrashAlt} />
            </button>
        </span>
    );
};

export const MobileSearchUI = (props) => {
    const { machHandle, machBasic, customers, organization } = useSelector(x => x);
    let filteredData = machHandle.handle;
    const hist = useHistory();
    const EditMethod = (id) => {
        hist.push(`/machHandleEdit/${id}`);
    }

    const [expandedId, setExpandedId] = useState(null);

    const handleExpandClick = (id) => {
        setExpandedId(expandedId === id ? null : id);
    };

    return (
        <div style={{ width: '100%' }}>
            {
                filteredData.map((data, index) => {
                    let _machBasic = machBasic.basic.find((e) => e.id === data.equipmentID);
                    let _serialNo = _machBasic === undefined ? null : _machBasic.serialNo;
                    let _customer = customers.find((e) => e.customerId === data.customerID);
                    let _organization = organization.allMembers.find((e) => e.ID === data.updatedBy);
                    let _updatedDate = data.updatedDate ? data.updatedDate.replace('T', ' ').substr(0, 10) : '';
                    return (

                        <Card key={index} style={{ borderRadius: '20px', margin: '5px' }}>
                            <Card.Body style={{ padding: '8px' }}>

                                <Row>
                                    <Card.Title style={{ fontSize: '14px' }}>手握把序號:{data.serialNo}</Card.Title>
                                    <Col>
                                        <Card.Text style={{ fontSize: '12px' }}>
                                            綁定設備序號:{_serialNo}<br />
                                            客戶名稱:{_customer.name}<br />
                                            發數/使用時數:{data.numberOfShots}<br />

                                            <Button style={{ display: 'contents', lineHeight: '0rm' }}
                                                variant="link"
                                                onClick={() => handleExpandClick(data.id)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={expandedId === data.id}
                                            >
                                                <FontAwesomeIcon icon={faChevronDown} />
                                            </Button>
                                        </Card.Text>
                                    </Col>
                                    <Col xs={2}>
                                        <Button variant="link" style={{ fontSize: '20px' }} onClick={() => {
                                            EditMethod(data.id);
                                        }}>
                                            <FontAwesomeIcon icon={faSignOutAlt} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>

                            <Collapse in={expandedId === data.id}  >
                                <Card.Text style={{ fontSize: '12px', padding: '5px' }}>
                                    備註:{data.remark}<br />
                                    更新人員:{_organization.Name}&nbsp;
                                    更新時間:{_updatedDate}
                                </Card.Text>
                            </Collapse>

                        </Card>
                    )
                })
            }

        </div>
    );
}