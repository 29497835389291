import React, { useState } from 'react';
import { Card, Row, Button, Container, FormControl, InputGroup, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faDownload } from '@fortawesome/free-solid-svg-icons';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import NavBar from '../../Navbar/NavBar.jsx';
import "react-datepicker/dist/react-datepicker.css";
import 'react-dropdown-tree-select/dist/styles.css'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useSelector, useDispatch } from 'react-redux';
import { productSettingsQuery } from '../../../action/material/productAction.js';
import { scheduledTriggerProduct } from '../../../action/scheduled/scheduledAction.js';

import { useRWD } from '../../../useRWD.js';
import { store } from 'react-notifications-component';
import { useHistory } from "react-router-dom";



const queryParam = {
    keyword: null
};

const completeMessage = {
    title: "搜尋",
    message: "完成",
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 3000,
        onScreen: true
    }
};
export const ProductSettings = (props) => {

    const dispatch = useDispatch();
    const rowStyle = {
        cursor: "pointer",
    };
    const [state, setState] = useState({ gridApi: null });
    const { productSettings } = useSelector(x => x);
    let filteredData = productSettings.productSettings;

    const [data, setData] = useState(queryParam);
    const { MaterialSetEdit } = useSelector(x => x.ui);
  
  /*  { filteredData[0].updatedDate ? (new Date(filteredData[0].updatedDate)).toISOString().replace('T', ' ').substr(0, 19) : '' }*/
    //依據視窗大小調整 高度
    let device = useRWD();
    device = (device) + 'px';
    return (
        <React.Fragment>
            <NavBar />
            <Container fluid>
                <Row className="mx-auto">
                    <Card.Header>產品設定</Card.Header>
                    <Card className="mb-3" style={{ width: '100%', minWidth: '312px' }}>
                        <Card.Body>
                            <Row className="mx-auto">
                                <InputGroup className="mb-3">
                                    <FormControl
                                        type="search"
                                        placeholder="輸入關鍵字 : "
                                        aria-label="Search"
                                        size="1g"
                                        className="keyword-bar"
                                        defaultValue={queryParam.keyword}
                                        onChange={(e) => setData({ ...queryParam, keyword: e.target.value })}
                                    />
                                    <Button variant="primary" onClick={() => {
                                        dispatch(productSettingsQuery(data));
                                        completeMessage.title = "搜尋";
                                        store.addNotification(completeMessage);
                                    }}>
                                        <FontAwesomeIcon className='rounded' icon={faSearch} />
                                        搜尋
                                    </Button>
                                </InputGroup>
                            </Row>
                            <Row className="mx-auto">
                                <Form.Group as={Row} className="mb-2">
                                    <Form.Label column sm="3">
                                        <strong>更新時間: </strong>
                                        {filteredData[0].updatedDate ? filteredData[0].updatedDate.replace('T', ' ').substr(0, 19) : ''}
                                    </Form.Label >
                                    <Button variant="primary" style={{ height: 30, width: 110, lineHeight: 0.5 }} onClick={() => {
                                        dispatch(scheduledTriggerProduct());
                                        completeMessage.title = "產品更新";
                                        store.addNotification(completeMessage);
                                        
                                    }}>
                                        <FontAwesomeIcon className='rounded' icon={faDownload} />
                                        產品更新
                                    </Button>
                                </Form.Group>

                                <div className="ag-theme-alpine" style={{ height: device, width: '100%' }}>

                                    <AgGridReact
                                        rowData={filteredData}
                                        rowStyle={rowStyle}
                                        defaultColDef={{
                                            sortable: true,
                                            resizable: true,
                                            wrapText: true,
                                            autoHeight: true,
                                            filter: true
                                        }}
                                        onGridReady={(params) => {
                                            setState({
                                                ...state,
                                                gridApi: params.api
                                            });
                                        }}


                                        paginationAutoPageSize={true}
                                        pagination={true}
                                    >
                                        <AgGridColumn headerName="No." field="id" width={80} pinned="left"></AgGridColumn>
                                        <AgGridColumn headerName="產品代號" field="code" width={160} pinned="left"></AgGridColumn>
                                        <AgGridColumn headerName="產品名稱" field="name" width={250}></AgGridColumn>

                                        <AgGridColumn headerName="編輯" field="code" cellRendererFramework={EditRenderer} width={100} hide={MaterialSetEdit.disabled} ></AgGridColumn>

                                        <AgGridColumn headerName="網址路徑名稱" field="addressCategory" width={130} ></AgGridColumn>
                                        <AgGridColumn headerName="驗證顯示名稱" field="verifyName" width={130} ></AgGridColumn>
                                        <AgGridColumn headerName="驗證失敗次數上限" field="failureLimit" width={160} ></AgGridColumn>
                                        <AgGridColumn headerName="產品圖片路徑" field="productImagePath" width={300} ></AgGridColumn>
                                        <AgGridColumn headerName="衛教圖片路徑" field="healthImagePath" width={300} ></AgGridColumn>
                                        <AgGridColumn headerName="標籤前綴" field="labelCode" width={120} ></AgGridColumn>
                                        <AgGridColumn headerName="更新者" field="updatedBy" width={100} ></AgGridColumn>
                                        <AgGridColumn headerName="更新時間" field="updatedDate" width={200} cellRenderer={(x) => {
                                            return x.data.updatedDate ? x.data.updatedDate.replace('T', ' ').substr(0, 19) : '';
                                        }}></AgGridColumn>
                                    </AgGridReact>
                                </div>


                            </Row>

                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        </React.Fragment>
    );

}

export const EditRenderer = (props) => {

    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const hist = useHistory();
    const EditMethod = () => {
        let code = cellValue;
        hist.push(`/productSettingsEdit/${code}`);


    }

    return (
        <span>
            <button
                style={{ height: 30, lineHeight: 0.5, fontWeight: 'bold' }}
                className="btn btn-link"
                onClick={() => {
                    EditMethod();
                }}
            >
                編輯
            </button>
        </span>
    );
};

