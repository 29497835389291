import { useSelector } from 'react-redux';

const emptyMember = {
    ID: "-1",
    Name: "(empty)",
    DepartmentID: 0
};

export const useMember = (userID) => {
    const { allMembers } = useSelector(x => x.organization);
    const member = allMembers.find(x => x.ID === userID);
    if (member === undefined) {
        return emptyMember;
    }
    return member;
}

export const useCurrentUser = () => {
    const { currentUser } = useSelector(x => x);
    return currentUser;
}

export const useDepartment = (departmentID) => {
    const { departments } = useSelector(x => x.organization);
    const department = departments.find(x => x.ID === departmentID);
    return department;
}

export const useJobClass = (value = 1) => {
    const { jobclass } = useSelector(x => x.config);
    const setting = jobclass.find(x => x.value === value);
    return setting;
}

export const useLevelCode = (_LevelCode) => {
    const level_Code = useSelector(x => x.organization);
    let Level_length = _LevelCode.length;
    let levelCode = '';
    if (Level_length > 3) {
        for (let i = 4; i <= Level_length; i++) {
            let getlevel = level_Code.departments.find(x => x.LevelCode === _LevelCode.slice(0, i));
            if (i === Level_length) {
                levelCode += getlevel.Name;
            } else {
                levelCode += getlevel.Name + '-';
            }

        }
    }

    return levelCode;
}

export const useGuestContact = (_customerId) => {
    const { customers } = useSelector(x => x);
    let contacts = [];
    let handleData = null;
    for (let i = 0; i < _customerId.length; i++) {

        if (_customerId[i].custContact !== '(尚未設定)') {
            let getContacts = customers.find(x => x.customerId === _customerId[i].customer);
            if (getContacts === undefined) {
                handleData = _customerId[i].custContact;
            } else {
                let title = getContacts.custContacts[0].title == null ? '' : getContacts.custContacts[0].title;
                let cellphone = getContacts.custContacts[0].cellphone == null ? '' : getContacts.custContacts[0].cellphone;
                handleData = getContacts.custContacts[0].name + '--' + title + '(' + cellphone + ')';
            }
        } else {
            handleData = _customerId[i].custContact;
        }

        contacts.push(handleData);
    }

    return contacts;
}


export const functiondata = (_admindata) => {
    const emptyFunction = {
        funID: null,
        roleID: 30,
        funNameEn: null,
        FunNameTw: null,
        Type: null,
        enable: false,
        Remark: null
    }
    let result = [];
    for (let i = 0; i < _admindata.length; i++) {
        let newFunc = {
            ...emptyFunction,
            funID: _admindata[i].comment,
            FunNameTw: _admindata[i].text,
            enable: !Boolean(_admindata[i].flag),
        }
        result.push(newFunc);
    }
    return result;
}
