import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Form, Row, Button, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { store } from 'react-notifications-component';
import { useParams, useHistory } from "react-router-dom";
import { CenteredModalYesNo } from '../../../../components/Material/CenteredModal';
import { handleInsert, handleUpdate } from '../../../../action/maintain/handleAction.js';
import { apiMachHandleQuery } from '../../../../utils/api.js';
import Select from 'react-select';

const defaultMessage = {
    title: "上傳中",
    message: "資料上傳中，請稍後...",
    type: "warning",
    insert: "top",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 2000,
        onScreen: true
    }
};

const completeMessage = {
    title: "完成",
    message: "更新完成",
    type: "success",
    insert: "top",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 1000,
        onScreen: true
    }
};

const queryParam = {
    keyword: null
};
const emptyHandle = {
    id: null,
    equipmentID: '',
    serialNo: '',
    numberOfShots: null,
    remark: null,
    flag: 1
};



export const MachHandleEdit = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { eqpName, eqpType } = useSelector(x => x.config);
    const { machBasic, machHandle } = useSelector(x => x);
    const { id } = useParams();
    const { MachHandSnoEdit } = useSelector(x => x.uiMach);

    const [data, setData] = useState(emptyHandle);
    const [eqp, setEqp] = useState([]);
    const [isEdit, setEdit] = useState(false);

    useEffect(() => {
        if (id !== '0') {
            //eslint-disable-next-line eqeqeq
            const HandleData = machHandle.handle.find(e => e.id == id);
            setData(HandleData);
            if (MachHandSnoEdit.disabled === false) {
                setEdit(false);
            } else {
                setEdit(true);
            }
            
        }
        machBasic.basic.forEach((item) => {
            let eqpName_temp = eqpName.find((e) => e.value === item.name);
            let eqpType_temp = eqpType.find((e) => eqpName_temp.configId === e.order && e.value === item.type);
            const newItem = { id: item.id, serialNo: item.serialNo, name: eqpName_temp.text, type: eqpType_temp.text };
            setEqp(prevData => [...prevData, newItem]);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const insertOrUpdateData = async (data) => {
        //手握把序號不重複
        queryParam.keyword = data.serialNo;
        let res = await apiMachHandleQuery(queryParam);
        let item = JSON.parse(res.request.response);

        let msgId = store.addNotification(defaultMessage);
        if (id === '0') {
            if (item.length <= 0) {
                handleInsertOrUpdate(dispatch(handleInsert(data)), msgId, completeMessage);

            } else {
                if (item.find((e) => e.serialNo === data.serialNo)) {
                    window.alert('手握把序號重複!!');
                } else {
                    handleInsertOrUpdate(dispatch(handleInsert(data)), msgId, completeMessage);
                }
            }

        } else {
            handleInsertOrUpdate(dispatch(handleUpdate(data)), msgId, completeMessage);
        }


    }
    const handleInsertOrUpdate = async (data, msgId, completeMessage) => {
        await data;
        store.removeNotification(msgId);
        store.addNotification(completeMessage);
        close();
    }

    const close = () => {
        /* history.push(`/machBasicTab/machHandle/0`);*/
        history.goBack();
    }
    //Select 快速搜尋
    const defaultOptionsEqpType = [];
    eqp.forEach(item => {
        let temp = item.serialNo + ' 【 ' + item.name + '】--' + item.type;
        defaultOptionsEqpType.push({
            value: item.id,
            label: temp
        });
    });
    defaultOptionsEqpType.sort(function compareFn(a, b) {
        if (a.label < b.label) return -1;
        if (a.label > b.label) return 1;
        return 0;
    });

    //欄位驗證-required
    const [validated, setValidated] = useState(false);
    //modal
    const [modalShow, setModalShow] = useState(false);

    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col md={6} className="mx-auto">
                        <Card className="mb-1" style={{ width: '100%', minWidth: '320px' }}>
                            <Card.Body>
                                <Form noValidate validated={validated} onSubmit={(e) => {
                                    const from = e.currentTarget;
                                    e.preventDefault();//防止預設行為
                                    if (from.checkValidity() === false ||
                                        data.serialNo === '' || data.equipmentID === '0' || data.numberOfShots === null) {
                                        e.stopPropagation();
                                    } else {

                                        setModalShow(true);
                                    }
                                    setValidated(true);

                                }}>
                                    <Card.Title>手握把資料</Card.Title>
                                    <Row style={{ margin: '0 0 20px 0' }} >
                                        <Button onClick={close}>
                                            <FontAwesomeIcon className='rounded me-2' icon={faTimes} />
                                            關閉
                                        </Button>
                                    </Row>
                                    <Row>
                                        <Form.Group className="mb-4">
                                            <Form.Label column sm="4">
                                                <strong>手握把序號</strong>
                                            </Form.Label>
                                            <Form.Control type="text" placeholder="" required disabled={isEdit}
                                                defaultValue={data.serialNo}
                                                onChangeCapture={(e) => {
                                                    setData({ ...data, serialNo: e.target.value });

                                                }}
                                            />

                                        </Form.Group>
                                        <Form.Group className="mb-4">
                                            <Form.Label column sm="4">
                                                <strong>發數/使用時數</strong>
                                            </Form.Label>
                                            <Form.Control type="text" placeholder="" required
                                                defaultValue={data.numberOfShots}
                                                onChangeCapture={(e) => {
                                                    setData({ ...data, numberOfShots: e.target.value });

                                                }}
                                            />

                                        </Form.Group>

                                        <Form.Group className="mb-4">
                                            <Form.Label column sm="5">
                                                <strong>綁定設備</strong>
                                                <span class="text-danger" style={{ fontSize: '14px', padding: 'revert' }}>*必填，設備可有複數手握把</span>
                                            </Form.Label>

                                            {/* 快速搜尋 功能*/}
                                            <Select 
                                                className="basic-single"
                                                classNamePrefix="select"
                                                isSearchable="true"
                                                isClearable="true"
                                                value={defaultOptionsEqpType[defaultOptionsEqpType.findIndex((e) => e.value === data.equipmentID)]}
                                                onChange={(e) => {

                                                    setData({
                                                        ...data,
                                                        equipmentID: e ? e.value : ''
                                                    });

                                                }}
                                                options={defaultOptionsEqpType}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-4">
                                            <Form.Label column sm="5">
                                                <strong>使用狀態</strong>
                                            </Form.Label>
                                            <Form.Control required as="select" type="select" onChange={(e) => {
                                                setData({
                                                    ...data,
                                                    flag: e.target.value
                                                });
                                            }}
                                                value={data.flag}
                                            >
                                                <option key={0} data-key={0} value='1'> 使用中</option>
                                                <option key={1} data-key={0} value='0'> 已使用完</option>
                                                <option key={1} data-key={0} value='2'> 其他</option>
                                            </Form.Control>


                                        </Form.Group>
                                        <Form.Group className="mb-4">
                                            <Form.Label column sm="5">
                                                <strong>備註</strong>
                                            </Form.Label>

                                            <Form.Control type="text" as="textarea"
                                                defaultValue={data.remark}
                                                onChangeCapture={(e) => {
                                                    setData({ ...data, remark: e.target.value });

                                                }}
                                            />

                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group>
                                            <Row className="mx-auto">
                                                <Button
                                                    variant="primary"
                                                    type="submit"
                                                >
                                                    <FontAwesomeIcon className='rounded me-2' icon={faSave} />
                                                    儲存
                                                </Button>

                                            </Row>
                                        </Form.Group>
                                    </Row>


                                </Form>
                                <CenteredModalYesNo
                                    title={'存檔'}
                                    message={'是否存檔?'}
                                    btnText1={'存檔'}
                                    btnText2={'取消'}
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    onClick={() => { insertOrUpdateData(data); setModalShow(false); }}
                                />

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </React.Fragment>

    )
}


