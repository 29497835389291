import { apiMachSupplierQuery, apiMachSupplierInsert, apiMachSupplierUpdate, apiMachSupplierDelete } from '../../utils/api.js';


export const supplierQuery = (newSupplier) => async (dispatch, getState) => {
    let res = await apiMachSupplierQuery(newSupplier);
    let data = JSON.parse(res.request.response);
    dispatch(renSupplier(data));
}

export const supplierInsert = (newSupplier) => async (dispatch, getState) => {
    let res = await apiMachSupplierInsert(newSupplier);
    if (res.status !== 200) {
        return;
    }
    dispatch(await supplierQuery());
}

export const supplierUpdate = (newSupplier) => async (dispatch, getState) => {
    let res = await apiMachSupplierUpdate(newSupplier);
    if (res.status !== 200) {
        return;
    }
    dispatch(await supplierQuery());
}
export const supplierDelete = (newSupplier) => async (dispatch, getState) => {
    let res = await apiMachSupplierDelete(newSupplier);
    if (res.status !== 200) {
        return;
    }
    dispatch(await supplierQuery());
}


const typeRenSupplier = 'typeRenQSupplier';
const renSupplier = (arrSupplier) => {

    return {
        type: typeRenSupplier,
        supplier: arrSupplier
    }
}



const initState = {
    supplier: []
}


export const reducer = (state = initState, action) => {
    switch (action.type) {
        case typeRenSupplier:
            return { ...state, supplier: [...action.supplier] };
       
        default:
            return state;
    }
}