import { apiMaintContractQuery, apiMaintContractInsert, apiMaintContractUpdate, apiMaintContractDelete } from '../../utils/api.js';


export const contractQuery = (newContract) => async (dispatch, getState) => {
    let res = await apiMaintContractQuery(newContract);
    let data = JSON.parse(res.request.response);
    dispatch(renContract(data));
}

export const contractInsert = (newContract,eqp) => async (dispatch, getState) => {
    let res = await apiMaintContractInsert(newContract, eqp);
    if (res.status !== 200) {
        return;
    }
    dispatch(await contractQuery());
}

export const contractUpdate = (newContract) => async (dispatch, getState) => {
    let res = await apiMaintContractUpdate(newContract);
    if (res.status !== 200) {
        return;
    }
    dispatch(await contractQuery());
}
export const contractDelete = (newContract) => async (dispatch, getState) => {
    let res = await apiMaintContractDelete(newContract);
    if (res.status !== 200) {
        return;
    }
    dispatch(await contractQuery());
}


const typeRenContract = 'typeRenContract';
const renContract = (arrContract) => {

    return {
        type: typeRenContract,
        contract: arrContract
    }
}


const initState = {
    contract: []
}
export const reducer = (state = initState, action) => {
    switch (action.type) {
        case typeRenContract:
            return { ...state, contract: [...action.contract] };

        default:
            return state;
    }
}
