import { apiCaseLogQuery } from '../../utils/api.js';

export const defaultCaseLogQuery = {
    caseId: -1
}

export const caseLogQuery = (param = defaultCaseLogQuery) => async (dispatch, getState) => {
    let resp = await apiCaseLogQuery(param);
    if (resp.status !== 200) {
        return dispatch(renCaseLogs([]));
    }
    let logs = JSON.parse(resp.request.response);
    return dispatch(renCaseLogs(logs));
}

const typeRenCaseLogs = 'RenCaseLogs';
export const renCaseLogs = (data) => {
    return {
        type: typeRenCaseLogs,
        logs: data
    }
}


//export const caseLogInsert(newCaseLog) => async (dispatch, getState) => {

//}
const initState = [];

export const reducer = (state = initState, action) => {
    switch (action.type) {
        case typeRenCaseLogs:
            return [...action.logs];
        default:
    }
    return state;
}
