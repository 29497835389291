import { apiCaseQuery, apiBusinessReportQuery, apiBusinessReportDetailQuery, apiCaseInsert, apiCaseUpdate, apiNonTrackingCases } from '../../utils/api.js';
import { getSubDepartments } from '../organization/organizationActions.js';
import moment from 'moment';

export const defaultQueryParam = {
    keyword: '',
    status: [],
    level: [],
    degree: [],
    progress: [],
    totalValueStart: '',
    totalValueEnd: '',
    updatedDateStart: null,
    updatedDateEnd: null,
    estimatedDateStart: null,
    estimatedDateEnd: null,
    assistingBy: [],
    customer: null,
    department: [],
    isQueryTracking: false,
    tracking: [],
    trackDateStart: null,
    trackDateEnd: null,
    jobClass: [],
    equipment: null,
    preSaleEquip: null
};

const DATE_FORMAT = "yyyy-MM-DD";

export const defaultBusinessReportParam = {
    StartDate: moment(),
    EndDate: moment(),
    assistingBy: '',
};

export const reportQuery = (param = defaultBusinessReportParam) => async (dispatch, getState) => {
    const { currentUser } = getState();
    const userID = currentUser.userID;
    const reqParam = {
        StartDate: moment(param.StartDate).format(DATE_FORMAT),
        EndDate: moment(param.EndDate).format(DATE_FORMAT),
        assistingBy: userID
    };

    let resp = await apiBusinessReportQuery(reqParam);
    let data = JSON.parse(resp.request.response);
    dispatch(renBusinessReport(data));

    let resp2 = await apiBusinessReportDetailQuery(reqParam);
    let data2 = JSON.parse(resp2.request.response);
    dispatch(renBusinessReportDetail(data2));
}

export const caseQuery = (param = defaultQueryParam) => async (dispatch, getState) => {
    let resp = await apiCaseQuery(param);
    let data = JSON.parse(resp.request.response);
    dispatch(renCases(data));
}

export const caseQueryDepartment = (param = defaultQueryParam) => async (dispatch, getState) => {
    const { organization, currentUser } = getState();
    const departmentID = currentUser.departmentID;
    const departments = organization.departments;
    const queryDepartments = getSubDepartments(departmentID, departments);

    dispatch(caseQuery({ ...param, department: queryDepartments }));
}

export const caseQueryNonTracking = () => async (dispatch, getState) => {
    let resp = await apiNonTrackingCases();
    let data = JSON.parse(resp.request.response);
    dispatch(renCases(data));
}

const typeRenCases = 'renCases';
const renCases = (cases) => {
    return {
        type: typeRenCases,
        case: cases
    }
}

const typeRenBusinessReport = 'renBusinessReport';
const renBusinessReport = (data) => {
    return {
        type: typeRenBusinessReport,
        businessreport: data
    }
}

const typeRenBusinessReportDetail = 'renBusinessReportDetail';
const renBusinessReportDetail = (data) => {
    return {
        type: typeRenBusinessReportDetail,
        businessreportdetail: data
    }
}

export const caseUpdate = (updateCase) => async (dispatch, getState) => {
   
    let resp = await apiCaseUpdate(updateCase)
    if (resp !== 200) {
        return;
    }
    dispatch(caseQuery());

}



export const caseInsert = (newCase) => async (dispatch, getState) => {
    let resp = await apiCaseInsert(newCase);
    if (resp.status !== 200) {
        return;
    }
    dispatch(caseQuery());
}

const initState = {
    case: [],
    businessreport: [],
    businessreportdetail: [],
}
export const reducer = (state = initState, action) => {

    switch (action.type) {
        case typeRenCases:
            return { ...state, case: [...action.case] };
        case typeRenBusinessReport:
            return { ...state, businessreport: [...action.businessreport] };
        case typeRenBusinessReportDetail:
            return { ...state, businessreportdetail: [...action.businessreportdetail] };

        default:
    }
    return state;
};
