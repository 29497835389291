import { apiPhotUpload, apiPhotDownload } from '../../utils/api.js';


export const phtoDownload = (newdata) => async (dispatch, getState) => {

    let res = await apiPhotDownload(newdata);
    if (res.status !== 200) {
        return;
    }
    return res;
}


export const phtoUpload = (newdata, category) => async (dispatch, getState) => {
  
    let res = await apiPhotUpload(newdata, category);
    if (res.status !== 200) {
        return;
    }
    
}








