import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';



export const AuthorizedRouteCookie = (props) => {

    console.log(props);
    const { component: Component, pending, currentUser, ...rest } = props;
    connect(currentUser);

    const cookies = new Cookies();
    const cookies_str = cookies.get('AuthToken');
    let isAuth = true;
    if (cookies_str === undefined) {
        isAuth = false;
    }

    if (isAuth && props.location.pathname === "/unauthorized") {
        console.log("isAuth unauthorized");

        return (
            <Redirect to="/home" />
        )
    }

    return (
        <Route {...rest} render={prop => {
            return isAuth || props.location.pathname === '/login'
                ? <Component {...prop} />
                : <Redirect to="/login" />
        }} />
    );



}
