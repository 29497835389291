import React, { useState, useRef, useCallback } from 'react';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-dropdown-tree-select/dist/styles.css'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { Card, Row, Col, Button, Container, Form } from 'react-bootstrap';
import { faFilter, faDownload,faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import NavBar from '../../Navbar/NavBar.jsx';
import { ProductSearchBar } from '../ProductTrack/ProductSearchBar.jsx';
import { toggleProductTrackSearchBar } from '../../../action/ui/uiActions.js';
import { scheduledTriggerSalesSlip } from '../../../action/scheduled/scheduledAction.js';

import { useRWD } from '../../../useRWD.js';
import { store } from 'react-notifications-component';
import ExcelJs from "exceljs"

const completeMessage = {
    title: "銷貨單更新",
    message: "完成",
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 5000,
        onScreen: true
    }
};
export const ProductTrack = (props) => {

    const dispatch = useDispatch();
    const rowStyle = {
        cursor: "pointer",
    };
    const [state, setState] = useState({ gridApi: null });
    const { productTrack } = useSelector(x => x);
    let filteredData = productTrack.productTrack;

    let temp = '';
    const handeleUpdateTime = () => {
        let time = '';
        /* eslint-disable array-callback-return*/
        productTrack.productTrack.map((item) => {
            if (item.scheduleUpdateDate !== temp && item.scheduleUpdateDate !== '') {
                time = item.scheduleUpdateDate;
            }
        })
        /* eslint-enable array-callback-return*/
        return time;
    }
    const gridRef = useRef();
    const onBtnExport = useCallback((params) => {
        const nodesToSelect = [];
        gridRef.current.api.forEachNode((node) => {
            if (node.selected === true) {
                nodesToSelect.push(node.data);
            }
        });
        exportMethod(nodesToSelect);
    }, []);
    //依據視窗大小調整 高度
    let device = useRWD();
    device = (device) + 'px';
    return (

        <React.Fragment >
            <NavBar />
            <Container fluid >
                <Row className="mx-auto">
                    <Card.Header>產品追蹤</Card.Header>
                    <Card className="mb-3" style={{ height: '100%', width: '100%', minWidth: '312px' }}>
                        <Card.Body>
                            <Row className="mx-auto"  >
                                <ProductSearchBar />
                            </Row>
                            <Row className="mb-3">
                                
                                <Col sm="3" style={{ textAlign: "right" }}>
                                    <Form.Group  className="mb-2">
                                        <Form.Label>
                                            <strong>更新時間: </strong>
                                            {handeleUpdateTime()}
                                        </Form.Label >

                                    </Form.Group>
                                </Col>
                                <Col  sm="3">
                                    <Button variant="primary" style={{ height: 30, width: 150, lineHeight: 0.5 }} onClick={() => {
                                        dispatch(scheduledTriggerSalesSlip());
                                        store.addNotification(completeMessage);
                                    }}>
                                        <FontAwesomeIcon className='rounded' icon={faDownload} />
                                        銷貨單更新
                                    </Button>
                                </Col>
                                <Col sm="4" style={{ textAlign: "center" }}>
                                    <Button variant="warning" style={{ height: 30, width: 150, lineHeight: 0.5 }} onClick={() => {
                                        onBtnExport();
                                    }}>
                                        <FontAwesomeIcon className='rounded' icon={faFileExport} />
                                        匯出
                                    </Button>
                                </Col>
                                <Col sm="2" style={{textAlign: "right"}} >
                                    <div>
                                        <Button variant="secondary" onClick={() => { dispatch(toggleProductTrackSearchBar()); }}>
                                            <FontAwesomeIcon className='rounded' icon={faFilter} />
                                            進階篩選
                                        </Button>

                                    </div>

                                </Col>
                            </Row>
                            <Row>

                                <div className="ag-theme-alpine " style={{ height: device, width: '100%' }}>
                                    <div style={{ height: '100%', width: '100%' }}>
                                        <AgGridReact
                                            ref={gridRef}
                                            rowStyle={rowStyle}
                                            rowData={filteredData}
                                            defaultColDef={{
                                                sortable: true,
                                                resizable: true,
                                                wrapText: true,
                                                autoHeight: true,
                                                filter: true
                                            }}
                                            onGridReady={(params) => {
                                                setState({
                                                    ...state,
                                                    gridApi: params.api
                                                });

                                            }}
                                            paginationAutoPageSize={true}
                                            pagination={true}
                                            rowSelection={'multiple'}
                                        >
                                            <AgGridColumn headerName="No." field="id" width={80} pinned="left"></AgGridColumn>
                                            <AgGridColumn headerName="產品代號" field="productNo" width={200} pinned="left" editable={true} headerCheckboxSelection={true} checkboxSelection={true}></AgGridColumn>
                                            <AgGridColumn headerName="品名" field="productName" pinned="left" editable={true} width={220}></AgGridColumn>
                                            <AgGridColumn headerName="UDI" field="udi" pinned="left" editable={true} width={150}></AgGridColumn>
                                            <AgGridColumn headerName="QRCode" field="qrCode" editable={true} width={250}></AgGridColumn>
                                            <AgGridColumn headerName="銷貨單號" field="salesSlipNo" editable={true} width={160}></AgGridColumn>
                                            <AgGridColumn headerName="銷貨日期" field="salesDate" editable={true} width={110}></AgGridColumn>
                                            <AgGridColumn headerName="銷貨客供應商" field="customer" editable={true}  width={160}></AgGridColumn>
                                            <AgGridColumn headerName="銷貨批號" field="salesLotNo" editable={true} width={110}></AgGridColumn>
                                            <AgGridColumn headerName="驗證失敗次數" field="failureCount" editable={true} width={130}></AgGridColumn>
                                            <AgGridColumn headerName="驗證時間" field="verifyDate" editable={true} width={170}></AgGridColumn>
                                        </AgGridReact>
                                    </div>

                                </div>

                            </Row>

                        </Card.Body>
                    </Card>
                </Row>

            </Container>
        </React.Fragment >
    );
}

const exportMethod = async (data) => {
   
    let rows_array = [];
    data.forEach((item, index) => {
        let temp = [];
        temp[0] = item.productNo;
        temp[1] = item.productName;
        temp[2] = item.udi;
        temp[3] = item.qrCode;
        temp[4] = item.salesSlipNo;
        temp[5] = item.salesDate;
        temp[6] = item.customer;
        temp[7] = item.salesLotNo;
        temp[8] = item.failureCount;
        temp[9] = item.verifyDate;
        rows_array[index] = temp;
    });

    const workbook = new ExcelJs.Workbook(); // 創建試算表檔案
    const sheet = workbook.addWorksheet('sheet1'); //在檔案中新增工作表 參數放自訂名稱
    sheet.addTable({ // 在工作表裡面指定位置、格式並用columsn與rows屬性填寫內容
        name: 'table名稱',  // 表格內看不到的，算是key值，讓你之後想要針對這個table去做額外設定的時候，可以指定到這個table
        ref: 'A1', // 從A1開始
        columns: [{ name: '產品代號' }, { name: '品名' }, { name: 'UDI' }, { name: 'QRCode' }, { name: '銷貨單號' }, { name: '銷貨日期' }, { name: '銷貨客供應商' }, { name: '銷貨批號' }, { name: '驗證失敗次數' }, { name: '驗證時間' }],
        rows: rows_array
    });

    // 表格裡面的資料都填寫完成之後，訂出下載的callback function
    // 異步的等待他處理完之後，創建url與連結，觸發下載
    workbook.xlsx.writeBuffer().then((content) => {
        const link = document.createElement("a");
        const blobData = new Blob([content], {
            type: "application/vnd.ms-excel;charset=utf-8;"
        });
        const time = (new Date(Date.now())).toLocaleString('sv');

        link.download = `產品追蹤_${time}.xlsx`;
        link.href = URL.createObjectURL(blobData);
        link.click();
    });

}