import * as React from 'react';
import { Card, Form, Row, Button, Modal, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

export const CustContactsAddBox = (props) => {
    const { data,show , onHide , setContact = () => { } } = props;
    
    const temp = {
        salesGo_CustomerCustomerId:data,
        name: null,
        title: null,
        cellphone: null,
        email: null,
        maincontact: false
    }

    return (
        <React.Fragment>
            <Modal show={show} fullscreen={false} centered onHide={onHide}>
                <Modal.Header className="bg-primary text-light" closeButton>
                    <Modal.Title>新增客戶聯絡人資料</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className="" style={{ width: '100%', minWidth: '312px' }}>
                        <Card.Body>
                            <Form autoComplete="false">
                                <Row>
                                    <Form.Group as={Row} className="mb-4">
                                        <Form.Label column sm="3">
                                            <strong>聯絡人名稱</strong>
                                            
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control type="text" placeholder=""
                                                onChangeCapture={(e) => {
                                                    temp.name = e.target.value;
                                                }}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-4">
                                        <Form.Label column sm="3">
                                            <strong>職稱</strong>
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control type="text" placeholder=""
                                                onChangeCapture={(e) => {
                                                    temp.title = e.target.value;
                                                }}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-4">
                                        <Form.Label column sm="3">
                                            <strong>連絡電話</strong>
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control type="text" placeholder=""
                                                onChangeCapture={(e) => {
                                                    temp.cellphone = e.target.value;
                                                }}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-4">
                                        <Form.Label column sm="3">
                                            <strong>E-mail</strong>
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control type="text" placeholder=""
                                                onChangeCapture={(e) => {
                                                    temp.email = e.target.value;
                                                }}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="mb-4 ml-4">
                                        <Form.Check type="switch" label="主要聯絡人" id="custom-switch"
                                            onChangeCapture={(e) => {
                                                temp.maincontact = e.target.checked;
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary"
                        onClickCapture={() => {
                            if (temp.name === null || temp.name === "" || temp.name === undefined
                            ) {

                                window.alert("請輸入聯絡人名稱");
                            }
                            else {
                                
                                setContact(temp);
                            }
                            onHide();

                        }}
                    >
                        <FontAwesomeIcon className='rounded me-2' icon={faSave} />
                        存檔
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment >
    );
}
