import { useState, useEffect } from 'react';

//Grid 一定要給固定高
//依據視窗大小調整 高度
export const useRWD = (props) => {
    let size = (window.innerHeight - 300) ;
    const [device, setDevice] = useState(size);

    const handleRWD = () => {
        size = (window.innerHeight - 300) ;
        setDevice(size);
        
    }

    useEffect(() => {
        window.addEventListener('resize', handleRWD);
        return (() => {
            window.removeEventListener('resize', handleRWD);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [device]);

    return device;
}

export const devicesIsMobile = (props) => {
    const toMatch = [
        /Android/i,
        /iPhone/i,
    ];

    //true or false
    let isMobile = toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });

    return isMobile;
}