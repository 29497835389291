import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Container, Row, Tab, Tabs } from 'react-bootstrap';
import NavBar from '../Navbar/NavBar.jsx';
import '../Maintain/maintainTab.css';
import { MaintRecord } from '../Maintain/MaintMgt/Record/MaintRecord.jsx';
import { MaintContract } from '../Maintain/MaintMgt/Contract/MaintContract.jsx';

import { useRWD } from '../../useRWD.js';


const maintainTabs = ['maintRecord', 'maintContract'];


export const MaintainTab = (props) => {

    const { tabs, id } = useParams();
    const [key, setKey] = useState('');

    useEffect(() => {
        const Data = maintainTabs.find(e => e === tabs);// eslint-disable-line eqeqeq
        setKey(Data);
    }, []) //  eslint-disable-line react-hooks/exhaustive-deps


    //依據視窗大小調整 高度
    let device = useRWD();
    device = (device) + 'px';

    return (
        <React.Fragment>
            <NavBar />
            <Container fluid>
                <Row className="mx-auto" >
                    <Tabs className="nav nav-pills" style={{ height: device }}
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        variant="pills"
                        justify>
                        <Tab eventKey={maintainTabs[0]} title="維修紀錄"  >
                            <MaintRecord />
                        </Tab>
                        <Tab eventKey={maintainTabs[1]} title="維護合約" >
                            <MaintContract id={id} />
                        </Tab>
                        
                    </Tabs>

                </Row>
            </Container>
        </React.Fragment>



    );

}