import React, { useState } from 'react';
import { Card, Row, Col, Button, Container, Form } from 'react-bootstrap';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import NavBar from '../Navbar/NavBar.jsx';
import "react-datepicker/dist/react-datepicker.css";
import 'react-dropdown-tree-select/dist/styles.css'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useSelector, useDispatch } from 'react-redux';
import { qrCodeQuery, qrCodeProductItemQuery, qrCodeInsert, qrCodeUpdate } from '../../action/material/qrCodeAction.js';

import ExcelJs from "exceljs"
import { store } from 'react-notifications-component';
import { CenteredModalYesNo } from '../../components/Material/CenteredModal';


const completeMessage = {
    title: "QRCode產生",
    message: "完成",
    type: "success",
    insert: "top",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 300,
        onScreen: true
    }
};
const queryParam = {
    productName: null
};

const queryProductItemParam = {
    QRCodeID: null,
    isExport: false
}
const insterParam = {
    productID: null,
    quantity: null,
    purchaseOrderID: null,
    purchaseOrderItemID: 0
};
let rowIndex = 0;

export const QRCode = (props) => {

    const dispatch = useDispatch();
    const rowStyle = {
        cursor: "pointer",
    };
    const [state, setState] = useState({ gridApi: null });
    const { MaterialQRCodeAdd } = useSelector(x => x.ui);

    const { productSettings, qrcode } = useSelector(x => x);
    let productSettingsData = productSettings.productSettings;

    const [data, setData] = useState(queryParam);// eslint-disable-line no-unused-vars
    const [insterData, setInsterData] = useState(insterParam);

    const QRCODEData = qrcode.qrCode; //QR code grid表
    const ProductItemData = qrcode.qrCodeProductItem;//QR code細項 grid表
    const SalseSlip = qrcode.salseSlip;


    const [listBox, setlistBox] = useState(() => {
        if (QRCODEData.length > 0) {
            if (QRCODEData.length > 1) {
                if (QRCODEData[0].productID !== QRCODEData[1].productID) {
                    return 0;
                }
            }
            return QRCODEData[0].productID;
        } else {
            return 0;
        }
    });
    let temp = [];
    //QR code 查詢 grid表
    const queryQRCode = (productName) => {
        dispatch(qrCodeQuery(productName));

    }
    //QR code細項 查詢 for grid表
    const queryProduct = (item) => {
        dispatch(qrCodeProductItemQuery(item));
    }
    const [validated, setValidated] = useState(false);
    //產生QR Code
    const handleSubmitInsertUpdate = async (item, data) => {
        //const from = e.currentTarget;
        //e.preventDefault();//防止預設行為
        //if (from.checkValidity() === false ||
        //    item.productName === '' || item.productName === null || item.productName === '0' ||
        //    item.quantity === '' || item.quantity === null) {

        //    e.stopPropagation();
        //} else {
        //    dispatch(qrCodeInsert(item, data));
        //    store.addNotification(completeMessage);

        //}
        //setValidated(true);
        dispatch(qrCodeInsert(item, data));
        store.addNotification(completeMessage);
       
    }

    //Select  快速搜尋
    const defaultOptions = [{}];
    let tax = '';
    SalseSlip.forEach(item => {
        if (tax !== item.purchaseOrderID) {
            defaultOptions.push({
                value: item.id,
                label: item.purchaseOrderID

            });
            tax = item.purchaseOrderID;
        }
    });
    //modal
    const [modalShow, setModalShow] = React.useState(false);

    return (
        <React.Fragment>
            <NavBar />
            <Container fluid>
                <Row className="mx-auto">
                    <Card.Header>QR Code</Card.Header>
                    <Card className="mb-3" style={{ width: '100%', minWidth: '312px' }}>
                        <Card.Body>
                            <Form noValidate validated={validated} onSubmit={(e) => {

                                const from = e.currentTarget;
                                e.preventDefault();//防止預設行為
                                if (from.checkValidity() === false ||
                                    insterData.productName === '' || insterData.productName === null || insterData.productName === '0' ||
                                    insterData.quantity === '' || insterData.quantity === null) {

                                    e.stopPropagation();
                                } else {

                                    setModalShow(true)
                                }
                                setValidated(true);

                            }}>
                                <CenteredModalYesNo
                                    title={'產生QRCode'}
                                    message={'確定要產生QRCode ?'}
                                    btnText1={'確定'}
                                    btnText2={'取消'}
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                    onClick={() => { handleSubmitInsertUpdate(insterData, data); setModalShow(false); }}
                                />
                                <Row className="mx-auto">

                                    <Form.Group as={Row} className="mb-2">
                                        <Form.Label column sm="3">
                                            <strong>選擇產品</strong>
                                        </Form.Label>
                                        <Col sm="9">
                                            <select
                                                value={listBox == null ? 0 : listBox}
                                                className="form-select"
                                                onChange={(e) => {

                                                    let selectedIndex = e.target.options.selectedIndex;
                                                    let productID_temp = e.target.options[selectedIndex].getAttribute('data-key');
                                                    if (productID_temp === '0') {
                                                        data.productName = '';
                                                    } else {
                                                        data.productName = productID_temp;
                                                    }
                                                    queryQRCode(data);

                                                    setInsterData({ ...insterData, productID: e.target.value });
                                                    setlistBox(e.target.value);
                                                }}
                                            >

                                                <option key={0} data-key={0} value={0} >全部</option>
                                                {/* eslint-disable array-callback-return */
                                                    productSettingsData.map(item => {
                                                        if (!temp.includes(item.addressCategory) && item.addressCategory != null) {
                                                            temp.push(item.addressCategory);
                                                            return (<option key={item.id} data-key={item.addressCategory} value={item.id}>{item.addressCategory}</option>)
                                                        }
                                                    })
                                                    /* eslint-enable array-callback-return */
                                                }
                                            </select>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-3">
                                        <Form.Label column sm="3">
                                            <strong>產生筆數</strong>
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control type="text" placeholder="" required
                                                onChangeCapture={(e) => {
                                                    setInsterData({ ...insterData, quantity: e.target.value });

                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                請輸入數量
                                            </Form.Control.Feedback>
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-2">
                                        <Col sm={{ span: 10, offset: 3 }}>
                                            <Button className="mb-2" variant="primary" disabled={MaterialQRCodeAdd.disabled}
                                                type="submit"
                                            >
                                                產生QRCode
                                            </Button>
                                        </Col>
                                    </Form.Group>
                                </Row>
                            </Form>

                            <Row>
                                <div className="ag-theme-alpine" style={{ height: '600px', width: '100%' }}>

                                    <AgGridReact
                                        rowStyle={rowStyle}
                                        rowData={QRCODEData}

                                        defaultColDef={{
                                            sortable: true,
                                            resizable: true,
                                            wrapText: true,
                                            autoHeight: true,
                                            filter: true
                                        }}
                                        onGridReady={(params) => {
                                            setState({
                                                ...state,
                                                gridApi: params.api
                                            });
                                        }}
                                        onRowClicked={(e) => {
                                            queryProductItemParam.QRCodeID = e.data.id;
                                            queryProductItemParam.isExport = false;
                                            queryProduct(queryProductItemParam);
                                            rowIndex = e.rowIndex;
                                        }}

                                        paginationAutoPageSize={true}
                                        pagination={true}
                                    >
                                        <AgGridColumn headerName="NO." field="id" width={80} pinned="left"></AgGridColumn>
                                        <AgGridColumn headerName="建立日期" field="createdDate" width={110} pinned="left" cellRenderer={(x) => {
                                            return x.data.createdDate ? (new Date(x.data.createdDate)).toLocaleDateString() : '';
                                        }}></AgGridColumn>
                                        <AgGridColumn headerName="建立者" field="createdBy" width={110}></AgGridColumn>
                                        <AgGridColumn headerName="產品類別" field="productName" width={250} ></AgGridColumn>
                                        <AgGridColumn headerName="筆數" field="quantity" width={80} ></AgGridColumn>
                                        <AgGridColumn headerName="匯出" field="id" cellRendererFramework={ExportRenderer} width={120} ></AgGridColumn>
                                        <AgGridColumn headerName="匯出者" field="exportBy" width={100} ></AgGridColumn>
                                        <AgGridColumn headerName="匯出時間" field="exportDate" width={180} cellRenderer={(x) => {
                                            return x.data.exportDate ? x.data.exportDate.replace('T', ' ').substr(0, 19) : '';
                                        }}></AgGridColumn>
                                    </AgGridReact>
                                </div>
                            </Row>
                            <Row>
                                <Form.Label sm="3">
                                    <strong>展開細項</strong>
                                </Form.Label>
                                <div className="ag-theme-alpine" style={{ height: '300px', width: '100%' }}>

                                    <AgGridReact
                                        rowStyle={rowStyle}
                                        rowData={ProductItemData}
                                        defaultColDef={{
                                            sortable: true,
                                            resizable: true,
                                            wrapText: true,
                                            autoHeight: true,
                                            filter: true
                                        }}
                                        onGridReady={(params) => {
                                            setState({
                                                ...state,
                                                gridApi: params.api
                                            });
                                        }}
                                        paginationAutoPageSize={true}
                                        pagination={true}
                                    >
                                        <AgGridColumn headerName="NO." field="id" width={80} pinned="left"></AgGridColumn>
                                        <AgGridColumn headerName="生產序號" field="serialNo" width={130} pinned="left"></AgGridColumn>
                                        <AgGridColumn headerName="QRCode" field="webAddress" width={400}></AgGridColumn>
                                        <AgGridColumn headerName="驗證碼" field="verifyCode" width={100} ></AgGridColumn>
                                    </AgGridReact>
                                </div>
                            </Row>
                        </Card.Body>
                    </Card>
                </Row>
            </Container>

        </React.Fragment >
    );

}

export const ExportRenderer = (props) => {

    const dispatch = useDispatch();
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;


    const { qrcode } = useSelector(x => x);

    const exportMethod = async () => {
        queryProductItemParam.QRCodeID = cellValue;
        queryProductItemParam.isExport = false;
        let ProductItemData = await dispatch(qrCodeProductItemQuery(queryProductItemParam));//匯出資料

        let product = qrcode.qrCode.find(x => x.productID === ProductItemData[0].productID);//匯出檔名-產品名稱
        let rows_array = [];
        ProductItemData.forEach((item, index) => {
            let temp = [];
            temp[0] = item.serialNo;
            temp[1] = item.webAddress;
            temp[2] = item.verifyCode;
            rows_array[index] = temp;
        });

        const workbook = new ExcelJs.Workbook(); // 創建試算表檔案
        const sheet = workbook.addWorksheet('sheet1'); //在檔案中新增工作表 參數放自訂名稱
        sheet.addTable({ // 在工作表裡面指定位置、格式並用columsn與rows屬性填寫內容
            name: 'table名稱',  // 表格內看不到的，算是key值，讓你之後想要針對這個table去做額外設定的時候，可以指定到這個table
            ref: 'A1', // 從A1開始
            columns: [{ name: '生產序號' }, { name: 'QRCode' }, { name: '驗證碼' }],
            rows: rows_array
        });

        // 表格裡面的資料都填寫完成之後，訂出下載的callback function
        // 異步的等待他處理完之後，創建url與連結，觸發下載
        workbook.xlsx.writeBuffer().then((content) => {
            const link = document.createElement("a");
            const blobData = new Blob([content], {
                type: "application/vnd.ms-excel;charset=utf-8;"
            });
            const time = (new Date(Date.now())).toLocaleString('sv');

            link.download = product.productName + '_' + time + '.xlsx';
            link.href = URL.createObjectURL(blobData);
            link.click();
        });

    }

    return (
        <span>
            <button
                style={{ height: 30, lineHeight: 0.5, fontWeight: 'bold' }}
                className="btn btn-warning"
                onClick={(e) => {
                    exportMethod();
                    dispatch(qrCodeUpdate(qrcode.qrCode[rowIndex]));
                }}
            >
                匯出
            </button>
        </span>
    );
};

