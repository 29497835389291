import React, { useState } from 'react';
import { Container, Row, Tab, Tabs, Table } from 'react-bootstrap';
import { Business } from '../Business/Business.jsx';
import { CustomerBox } from '../Customer/CustomerBox.jsx';
import { useParams, useHistory } from "react-router-dom";
import '../Customer/Tab.css';
import { customerQuery, customerUpdate } from '../../action/customer/customerActions.js';
import { store } from 'react-notifications-component';
import { useSelector, useDispatch } from 'react-redux';

import { CenteredModalYesNo } from '../../components/Material/CenteredModal';

const defaultMessage = {
    title: "更新",
    message: "資料上傳中，請稍後...",
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 2000,
        onScreen: true
    }
};

const completeMessage = {
    title: "更新",
    message: "更新完成",
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 2000,
        onScreen: true
    }
};

const customerTabs = {
    businessCases: "@CustomerTabs/BusinessCases",
    customerBox: "@CustomerTabs/CustomerBox"
};


export const CustomerTab = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { customers } = useSelector(x => x);
    const { id } = useParams();
    const CustomerData = customers.find(e => e.customerId == id);// eslint-disable-line


    let customer = {};
    const onCustomerModify = (e) => {
        customer = { ...e };
    }

    const insertOrUpdateData = async (data) => {
        if (customer.name === null || customer.name === "" || customer.name === undefined) {
            window.alert("請輸入客戶名稱");
        } else {
            let msgId = store.addNotification(defaultMessage);
            await dispatch(customerUpdate(data));
            await dispatch(customerQuery());
            store.removeNotification(msgId);
            store.addNotification(completeMessage);
            customer = {};
            history.goBack();
        }

    }
    const CustEdit = useSelector(x => x.ui.CustEdit);

    //modal
    const [modalShow, setModalShow] = useState(false);
    const handleClose = () => {
        if (CustEdit.disabled === false) {
            setModalShow(true);
        } else {
            dispatch(customerQuery());
            history.goBack();
        }
    }


    return (
        <React.Fragment>

            <Table className="styled-table">
                <thead  >
                    <tr >
                        <th width="120px">客戶名稱</th>
                        <th>{CustomerData.name}</th>
                    </tr>
                </thead>
                <tbody style={{ background: '#FFFFFF' }}>
                    <tr>
                        <td>聯絡電話</td>
                        <td>{CustomerData.telephone}</td>
                    </tr>
                    <tr>
                        <td>地址</td>
                        <td>{CustomerData.address}</td>
                    </tr>
                </tbody>
            </Table>
            <Container fluid>
                <button className="btn float-right float-end" onClick={handleClose}>✖關閉</button>
                <Row className="mx-auto">

                    <Tabs
                        defaultActiveKey={customerTabs.businessCases}
                        variant="pills"
                        justify>
                        <Tab eventKey={customerTabs.businessCases} title="案例管理">
                            <div className="py-2"></div>
                            <Business ShowNarBar={false} />
                        </Tab>
                        <Tab eventKey={customerTabs.customerBox} title="基本資料" >
                            <div className="py-2"></div>
                            <CustomerBox onCustomerModify={onCustomerModify} />
                        </Tab>

                    </Tabs>

                </Row>
                <CenteredModalYesNo
                    title={'存檔'}
                    message={'確定要存檔?'}
                    btnText1={'確定'}
                    btnText2={'取消'}
                    show={modalShow}
                    onHide={() => {
                        dispatch(customerQuery());
                        history.goBack(); setModalShow(false) } }
                    onClick={() => { insertOrUpdateData(customer); setModalShow(false); }}
                />
            </Container>
        </React.Fragment>
    );

}