import { apiQRCodeQuery, apiQRCodeInsert, apiQRCodeUpdate, apiQRCodeProductItemQuery, apiSalesSlipQuery } from '../../utils/api.js';


export const qrCodeQuery = (newQRCode) => async (dispatch, getState) => {
    let res = await apiQRCodeQuery(newQRCode);
    let data = JSON.parse(res.request.response);
    dispatch(renQRCode(data));
}

export const qrCodeInsert = (newQRCode,data) => async (dispatch, getState) => {
    let res = await apiQRCodeInsert(newQRCode);
    if (res.status !== 200) {
        return;
    }
    dispatch(await qrCodeQuery(data));
}

export const qrCodeUpdate = (newQRCode) => async (dispatch, getState) => {
    let res = await apiQRCodeUpdate(newQRCode);
    if (res.status !== 200) {
        return;
    }
    dispatch(await qrCodeQuery());
}

export const qrCodeProductItemQuery = (newProductItem) => async (dispatch, getState) => {
    let res = await apiQRCodeProductItemQuery(newProductItem);
    let data = JSON.parse(res.request.response);
    dispatch(renQRCodeProductItem(data));
    return data;
   
}
export const salseSlipQuery = () => async (dispatch, getState) => {
    let res = await apiSalesSlipQuery();
    let data = JSON.parse(res.request.response);
    dispatch(renSalseSlip(data));
   
}

const typeRenQRCode = 'typeRenQRCode';
const renQRCode = (arrQRCode) => {

    return {
        type: typeRenQRCode,
        qrCode: arrQRCode
    }
}
const typeRenQRCodeProductItem = 'typeRenQRCodeProductItem';
const renQRCodeProductItem = (arrQRCodeProductItem) => {

    return {
        type: typeRenQRCodeProductItem,
        qrCodeProductItem: arrQRCodeProductItem
    }
}
const typeSalseSlip = 'typeSalseSlip';
const renSalseSlip = (arrSalseSlip) => {

    return {
        type: typeSalseSlip,
        salseSlip: arrSalseSlip
    }
}


const initState = {
    qrCode: [],
    qrCodeProductItem: [],
    salseSlip:[]
}


export const reducer = (state = initState, action) => {
    switch (action.type) {
        case typeRenQRCode:
            return { ...state, qrCode: [...action.qrCode] };
        case typeRenQRCodeProductItem:
            return { ...state, qrCodeProductItem: [...action.qrCodeProductItem] };
        case typeSalseSlip:
            return { ...state, salseSlip: [...action.salseSlip] };
        default:
            return state;
    }
}