import { apiSalesSlipCustomerQuery, apiStatisticsReportQuery } from '../../utils/api.js';


export const SalesSlipCustomerQuery = () => async (dispatch, getState) => {
    let res = await apiSalesSlipCustomerQuery();
    let data = JSON.parse(res.request.response);
    dispatch(renSalesSlipCus(data));
}

export const StatisticsReportQuery = (newData) => async (dispatch, getState) => {
    let res = await apiStatisticsReportQuery(newData);
    let data = JSON.parse(res.request.response);
    if (res.status  !== 200) {
        return;
    }
    return data;
}

const typeRenSalesSlipCus = 'typeRenSalesSlipCus';
const renSalesSlipCus = (arrSalesSlipCus) => {

    return {
        type: typeRenSalesSlipCus,
        salesSlipCus: arrSalesSlipCus
    }
}

const initState = {
    salesSlipCus: []
}


export const reducer = (state = initState, action) => {
    switch (action.type) {
        case typeRenSalesSlipCus:
            return { ...state, salesSlipCus: [...action.salesSlipCus] };
       
        default:
            return state;
    }
}