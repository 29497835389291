import ExcelJs from "exceljs"


//匯出Excel方法
export const exportExcelMethod = async (columns_array, rows_array, fileName) => {
    const workbook = new ExcelJs.Workbook(); // 創建試算表檔案
    const sheet = workbook.addWorksheet('sheet1'); //在檔案中新增工作表 參數放自訂名稱
    sheet.addTable({ // 在工作表裡面指定位置、格式並用columsn與rows屬性填寫內容
        name: 'table名稱',  // 表格內看不到的，算是key值，讓你之後想要針對這個table去做額外設定的時候，可以指定到這個table
        ref: 'A1', // 從A1開始
        columns: columns_array,
        rows: rows_array
    });

    // 表格裡面的資料都填寫完成之後，訂出下載的callback function
    // 異步的等待他處理完之後，創建url與連結，觸發下載
    workbook.xlsx.writeBuffer().then((content) => {
        const link = document.createElement("a");
        const blobData = new Blob([content], {
            type: "application/vnd.ms-excel;charset=utf-8;"
        });
        const time = (new Date(Date.now())).toLocaleString('sv');

        link.download = `${fileName}_${time}.xlsx`;
        link.href = URL.createObjectURL(blobData);
        link.click();
    });

}