import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Container, Row, Tab, Tabs } from 'react-bootstrap';
import NavBar from '../Navbar/NavBar.jsx';
import '../Maintain/maintainTab.css';
import { MachReport } from '../Maintain/Report/Mach/MachReport.jsx';
import { MaintStaffReport } from '../Maintain/Report/MaintStaff/MaintStaffReport.jsx';


import { useRWD } from '../../useRWD.js';


const reportTabs = ['machReport', 'employeeReport'];


export const ReportTab = (props) => {

    const { tabs, id } = useParams();
    const [key, setKey] = useState('');

    useEffect(() => {
        const Data = reportTabs.find(e => e === tabs);// eslint-disable-line eqeqeq
        setKey(Data);
    }, []) //  eslint-disable-line react-hooks/exhaustive-deps


    //依據視窗大小調整 高度
    let device = useRWD();
    device = (device) + 'px';

    return (
        <React.Fragment>
            <NavBar />
            <Container fluid>
                <Row className="mx-auto" >
                    <Tabs className="nav nav-pills" style={{ height: device }}
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        variant="pills"
                        justify>
                        <Tab eventKey={reportTabs[0]} title="機台紀錄分析"  >
                            <MachReport />
                        </Tab>
                        <Tab eventKey={reportTabs[1]} title="工程師紀錄分析" >
                            <MaintStaffReport id={id} />
                        </Tab>

                    </Tabs>

                </Row>
            </Container>
        </React.Fragment>



    );

}