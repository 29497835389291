import React, { useState } from 'react';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-dropdown-tree-select/dist/styles.css'
import { Form, Row, Col, Accordion, Container, InputGroup, Button, Collapse } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import { useSelector, useDispatch } from 'react-redux';
import { productTrackQuery } from '../../../action/material/productTrackAction.js';
import { store } from 'react-notifications-component';
import { toggleProductTrackSearchBar } from '../../../action/ui/uiActions.js';


const queryParam = {
    keyword: '',
    productName: '',
    UDI: '',
    qRcCode: '',
    salesSlipNo: '',
    salesDate: null,
    customer: '',
    salesLotNo: '',
    failureCount: 0
};

const completeMessage = {
    title: "搜尋",
    message: "搜尋完成",
    type: "success",
    insert: "top",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 1000,
        onScreen: true
    }
};

export const ProductSearchBar = (props) => {

    const dispatch = useDispatch();
    

    const { productSettings, ui } = useSelector(x => x);
    let productSettingsData = productSettings.productSettings;
    let temp = [];

    const [data, setData] = useState(queryParam);
   

    const query = () => {
        dispatch(productTrackQuery(data));
        dispatch(toggleProductTrackSearchBar(false));
        store.addNotification(completeMessage);
    }

    const clearQuery = () => {

        setData({ ...data, ...queryParam });
    };

    const handleDatePickerChange = (modify) => {
        let res = Object.assign({}, data, modify);
        setData({ ...data, ...res });
       
    }

    
    const handleSubmit = async (e) => {
        e.preventDefault();//防止預設行為
        query();
        
    }
    return (
        <Container>
            <Row className="mx-auto">
                <Form noValidate  onSubmit={(e) => {
                    handleSubmit(e);
                }}>
                <InputGroup className="mb-3">
                    <Form.Control
                        id="message"
                        type="text"
                        placeholder="輸入關鍵字 : "
                        autocomplete="on"
                        aria-label="Search"
                        size="lg"
                        className="keyword-bar"
                        value={data.keyword}
                        onChange={(e) => {
                            setData({ ...data, keyword: e.target.value })
                        }}
                    />
                        <Button variant="primary" type="submit">
                        <FontAwesomeIcon className='rounded' icon={faSearch} />
                        搜尋
                    </Button>
                    <Button variant="light" onClick={clearQuery}>
                        <FontAwesomeIcon className='rounded' icon={faTrash} />
                        清除
                    </Button>
                    
                    </InputGroup>
                </Form>
            </Row>
            <Row className="mx-auto">
                <Collapse in={ui.ProductTrackSearchBar.isCollapse}>
                    <Row className="mx-auto">
                        <Col md={3}>
                            <Accordion defaultActiveKey="0" className="mb-3" flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>產品選擇</Accordion.Header>
                                    <Accordion.Body>
                                        <Form>
                                            <select
                                                value={data.productName}
                                                className="form-select"
                                                onChange={(e) => {
                                                    setData({ ...data, productName: e.target.value })
                                                }}
                                            >
                                                <option>請選擇</option>
                                                {/* eslint-disable array-callback-return */
                                                    productSettingsData.map(item => {
                                                        if (!temp.includes(item.addressCategory) && item.addressCategory != null) {
                                                            temp.push(item.addressCategory);
                                                            return (<option key={item.id} data-key={item.id} value={item.addressCategory}>{item.addressCategory}</option>)
                                                        }
                                                    })
                                                    /* eslint-enable array-callback-return */
                                                }
                                            </select>
                                        </Form>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion defaultActiveKey="0" className="mb-3" flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>銷貨日期</Accordion.Header>
                                    <Accordion.Body>
                                        <Form>
                                            <DatePicker
                                                className="form-control"
                                                isClearable
                                                selectsEnd
                                                dateFormat="yyyy-MM-dd"
                                                startDate={new Date()}
                                                selected={data.salesDate}
                                                onChange={(date) => {
                                                    let modify = { salesDate: date };
                                                    handleDatePickerChange(modify);
                                                }} />
                                        </Form>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                          
                        </Col>
                        <Col md={3}>
                            <Accordion defaultActiveKey="0" className="mb-3" flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>UDI</Accordion.Header>
                                    <Accordion.Body>
                                        <Form>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={data.UDI}
                                                onChange={(e) => {
                                                    setData({ ...data, UDI: e.target.value })
                                                }}
                                            />
                                        </Form>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion defaultActiveKey="0" className="mb-3" flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>超過驗證失敗次數</Accordion.Header>
                                    <Accordion.Body>
                                        <Form>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={data.failureCount}
                                                onChange={(e) => {
                                                    setData({ ...data, failureCount: e.target.value })
                                                }}
                                            />
                                        </Form>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>

                        <Col md={3}>
                            <Accordion defaultActiveKey="0" className="mb-3" flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>QRCode</Accordion.Header>
                                    <Accordion.Body>
                                        <Form autoComplete="false">
                                            <Form.Group>
                                                <Row className="g-3">
                                                    <Form.Control
                                                        size="sm"
                                                        type="text"
                                                        value={data.qRcCode}
                                                        onChange={(e) => {
                                                            setData({ ...data, qRcCode: e.target.value })
                                                        }}
                                                    />
                                                </Row>
                                            </Form.Group>
                                        </Form>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion defaultActiveKey="0" className="mb-3" flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>銷貨客供商</Accordion.Header>
                                    <Accordion.Body>
                                        <Form autoComplete="false">
                                            <Form.Group>
                                                <Row className="g-3">
                                                    <Form.Control
                                                        size="sm"
                                                        type="text"
                                                        value={data.customer}
                                                        onChange={(e) => {
                                                            setData({ ...data, customer: e.target.value })
                                                        }}
                                                    />
                                                </Row>
                                            </Form.Group>
                                        </Form>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                        <Col md={3}>
                            <Accordion defaultActiveKey="0" className="mb-3" flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>銷貨單號</Accordion.Header>
                                    <Accordion.Body>
                                        <Form>
                                            <Form.Control
                                                size="sm"
                                                type="text"
                                                value={data.salesSlipNo}
                                                onChange={(e) => {
                                                    setData({ ...data, salesSlipNo: e.target.value })
                                                }}
                                            />
                                        </Form>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            <Accordion defaultActiveKey="0" className="mb-3" flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>銷貨批號</Accordion.Header>
                                    <Accordion.Body>
                                        <Form autoComplete="false">
                                            <Form.Group>
                                                <Row className="g-3">
                                                    <Form.Control
                                                        size="sm"
                                                        type="text"
                                                        value={data.salesLotNo}
                                                        onChange={(e) => {
                                                            setData({ ...data, salesLotNo: e.target.value })
                                                        }}
                                                    />
                                                </Row>
                                            </Form.Group>
                                        </Form>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Col>
                    </Row>
                </Collapse>
            </Row>

        </Container>);
}