import { defaultQueryParam } from '../case/caseActions.js';
import { updateStructureChecked } from '../organization/organizationActions.js';

const statusImcomplete = {
    status: ['1', '2']
}
const statusComplete = {
    status: ['3', '4', '5']
}

const _50above = {
    degree: ['2', '3', '4']
}

const _70above = {
    degree: ['3', '4']
}

const estimatedDate = (start, days) => {
    let periodStart = new Date(start);
    let periodEnd = new Date(start.setDate(periodStart.getDate() + days));
    return {
        estimatedDateStart: periodStart,
        estimatedDateEnd: periodEnd,
    }
}

const trackingDate = (start, days) => {
    let periodStart = new Date(start);
    let periodEnd = new Date(start.setDate(periodStart.getDate() + days));
    return {
        trackDateStart: periodEnd,
        trackDateEnd: periodStart,
    }
}
const Weeks = (n = 1) => 7 * n;

const Month = (n = 1) => 30 * n;

const assignToMe = () => (dispatch, getState) => {
    let state = getState();
    let userID = state.currentUser.userID;
    dispatch(updateStructureChecked(true, [userID]));
    return { assistingBy: [state.currentUser.userID] };
}

const myDepartment = () => (dispatch, getState) => {
    let state = getState();
    return { department: [state.currentUser.departmentID] };
}
export const myCasesCompleted = () => (dispatch, getState) => {
    let param = myCases()(dispatch, getState);
    let res = Object.assign({}, param, statusComplete);
    console.log(res);
    return res;
}

export const myCasesIncomplete = () => (dispatch, getState) => {
    let param = myCases()(dispatch, getState);
    let res = Object.assign({}, param, statusImcomplete);
    return res;
}

export const myTrackingCases = () => (dispatch, getState) => {
    let param = defaultQueryParam;
    let state = getState();
    return Object.assign({}, param, { tracking: [state.currentUser.userID] })
}

export const myCases = () => (dispatch, getState) => {
    let param = defaultQueryParam;
    let assignBy = assignToMe()(dispatch, getState);
    return Object.assign({}, param, assignBy);
}


export const departmentCasesAll = () => (dispatch, getState) => {
    let param = defaultQueryParam;
    let department = myDepartment()(dispatch, getState);
    return Object.assign({}, param, department);
}

export const departmentCasesIncomplete = () => (dispatch, getState) => {
    let param = departmentCasesAll()(dispatch, getState);
    return Object.assign({}, param, statusImcomplete);
}

export const departmentCases2Week = () => (dispatch, getState) => {
    let param = departmentCasesAll()(dispatch, getState);
    let today = new Date();
    return Object.assign(
        {},
        param,
        estimatedDate(today, Weeks(2))
    );
}

export const departmentCasesImcomplete3m = () => (dispatch, getState) => {
    let param = departmentCasesAll()(dispatch, getState);
    let today = new Date();
    return Object.assign(
        {},
        param,
        estimatedDate(today, Month(3)),
        statusImcomplete
    );
}


export const predictionCasesMonth = (n) => (dispatch, getState) => {
    let param = defaultQueryParam;
    let today = new Date();
    let periodEnd = Month(n);
    return Object.assign(
        {},
        param,
        estimatedDate(today, periodEnd),
        {
            totalValueStart: '',
            totalValueEnd: ''
        }
    );
}


export const ZeroTracking = () => (dispatch, getState) => {
    let param = defaultQueryParam;
    return param;
}

export const _1week70pCases = () => (dispatch, getState) => {
    let param = defaultQueryParam;
    let today = new Date();
    return Object.assign(
        {},
        param,
        trackingDate(today, Weeks(-1)),
        { isQueryTracking: true },
        _70above
    );
}

export const _2week50pCases = () => (dispatch, getState) => {
    let param = defaultQueryParam;
    let today = new Date();
    return Object.assign(
        {},
        param,
        trackingDate(today, Weeks(-2)),
        { isQueryTracking: true },
        _50above
    );
}

export const delayCases = () => _2week50pCases();

export const totalValueAsZero = () => (dispatch, getState) => {
    let param = defaultQueryParam;
    param.totalValueStart = 0;
    param.totalValueEnd = 0;
    return param;
}

