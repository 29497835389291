import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { doLogout } from './action/auth/authActions.js';


const AppLogout = ({ children }) => {

    const dispatch = useDispatch();
    const time = 10 * 60 * 1000;//10分鐘檢查一次

    useEffect(() => {
        const interval = setInterval(() => AutoLogout(), time);

        return () => clearInterval(interval);
    }, []);//  eslint-disable-line react-hooks/exhaustive-deps
    const AutoLogout = () => {
        const thirtyMinutesInMs = 30 * 60 * 1000;  // 使用者為動作超過30分鐘 自動登出 
        const token = sessionStorage.getItem('authToken');
        if (token != null) {
            const userActionTime = sessionStorage.getItem('userActionTime');
            const expiryDate = parseInt(userActionTime) + thirtyMinutesInMs;
            const currentTime = Date.now();
            if (currentTime >= expiryDate) {
                dispatch(doLogout());
                window.location.reload(true);
            }
        }

    }

    return children;
};

export default AppLogout;