import React from 'react';
import { useSelector } from 'react-redux';
import { Navbar, Container, Nav, Offcanvas, Button, NavDropdown, Card, Dropdown, Col,Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import logo from '../../img/logo-sm.png';
/*import logoTest from '../../img/logo-sm-test.png';*/
import '../Navbar/nav.css';
import { doLogout } from '../../action/auth/authActions.js';
import { caseQueryDepartment, caseQueryNonTracking } from '../../action/case/caseActions.js';
import { refreshParam } from '../../action/query/queryAction.js';
import * as defaultQuery from '../../action/case/defaultQueryActions.js';
import { toggleNavBar, expandNavBar, collapseSearchBar } from '../../action/ui/uiActions.js';
import { store } from 'react-notifications-component';
import { Link, useParams } from 'react-router-dom';
import { useCurrentUser, useDepartment, useLevelCode } from '../../utils/hooks';
import { useDispatch } from 'react-redux';

const Message = (title, message, duration = 3000) => {
    return {
        title: title,
        message: message,
        type: 'success',
        insert: "top",
        container: "top-right",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
            duration: duration,
            onScreen: true
        }
    }
}

//LevelCode 部門階級顯示
const HandleLeve = () => {
    const user = useCurrentUser();
    const TKbaseLevelcode = 259;
    const department = useDepartment(user.departmentID);
    let _LevelCode = department === undefined ? TKbaseLevelcode : department.LevelCode;
    const _leve = useLevelCode(_LevelCode);
    const leve = _leve === '' ? '最高權限' : _leve;
    return leve;
}


const LoginStatus = (props) => {
    const user = useCurrentUser();
    const leve = HandleLeve();
    const dispatch = useDispatch();
    const handlLogout = (e) => {
        dispatch(doLogout());
        window.location.reload(true);
    }
    return (
        <Nav className="m-3 mt-0 mb-0">
            <NavDropdown
                title={
                    <span>
                        <FontAwesomeIcon className='rounded me-2' icon={faUserCircle} />
                    </span>}
            >
                <NavDropdown.Item>
                    {user.userName}({leve})
                </NavDropdown.Item>
                <NavDropdown.Divider />

                <NavDropdown.Item>
                    <Button variant="dark" size="sm" onClick={handlLogout}>
                        <FontAwesomeIcon className='rounded me-2' icon={faSignOutAlt} />
                        登出
                    </Button>
                </NavDropdown.Item>
            </NavDropdown>
        </Nav>
    );
}



export const NavMenu = (props) => {
    const leve = HandleLeve();

    const dispatch = useDispatch();
    const { Expand = 2 } = useParams();
    const NavBar = useSelector(x => x.ui.NavBar);
    console.log('Nav', NavBar.isExpanded);
    const { Business, Cust, Report, AdminBtn, MaterialSetQuery, MaterialQRCodeQuery, MaterialTrackQuery } = useSelector(x => x.ui);
    const { MachBasicQuery, MachRecordQuery, MachReport } = useSelector(x => x.uiMach);
    const doQuery = async (param) => {
        let id = store.addNotification(Message("讀取", "資料讀取中，請稍後..."));

        await dispatch(caseQueryDepartment(param));
        dispatch(refreshParam(param));

        store.removeNotification(id);
        store.addNotification(Message("讀取", "讀取完成"));

        dispatch(collapseSearchBar(false));
        dispatch(expandNavBar(false));
    }

    let fristloginisExpanded = true;
    if (Expand === 'Expand=1') { fristloginisExpanded = true; } else { fristloginisExpanded = false; }
    const handleToggleNavBar = () => { dispatch(toggleNavBar()); }
    const handleExpandNavBar = () => { dispatch(expandNavBar(false)); }
    const handleNonTracking = () => { dispatch(caseQueryNonTracking()); handleExpandNavBar(); }
    
    return (
        <React.Fragment>
            <Navbar bg="primary" variant="dark" className="nav-menu" expand={false} collapseOnSelect
                expanded={NavBar.isExpanded || fristloginisExpanded}
            >
                <Container fluid>

                    <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={handleToggleNavBar} />
                    <Navbar.Brand href="/business">
                        <Row >
                            <Col  style={{ fontSize: '16px', display: 'flex', alignItems:' flex-end'}} >
                                {leve}
                            </Col>
                            <Col>
                                <div style={{ borderLeft: 'dashed #006400', height: '70%', position: 'absolute' }} />
                            </Col>
                            <Col>
                                <img src={logo} height="40" className="d-inline-block align-top" alt="logo" />
                            </Col>
                        </Row>
                    </Navbar.Brand>
                    <Navbar.Offcanvas
                        id="offcanvasNavbar"
                        aria-labelledby="offcanvasNavbarLabel"
                        placement="start"
                        className="d-flex flex-column flex-shrink-0 p-3 text-light bg-secondary"
                        style={{ width: '800px' }}
                    >
                        <Offcanvas.Header closeVariant="white" closeButton onHide={handleExpandNavBar}>
                            <Offcanvas.Title id="offcanvasNavbarLabel">
                                <img src={logo} height="50" className="d-inline-block align-top" alt="logo" />
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <hr />
                        <Offcanvas.Body>
                            <Nav>
                                <Card className="card-bg" >
                                    <Card.Header className="card-header">客戶關係管理</Card.Header>
                                    <Card.Body  >
                                        {!Cust.disabled &&
                                            <Link className="mt-2 mb-2" to="/customer">
                                                <Button className="card-btn" variant="warning"  onClick={() => { handleToggleNavBar();  }}>客戶管理</Button>
                                            </Link>
                                        }
                                        {!Business.disabled &&
                                            <Link className="mt-2 mb-2" to="/business">
                                                <Button className="card-btn" variant="warning"  onClick={() => { handleToggleNavBar();  }}>案例管理</Button>
                                            </Link>
                                        }
                                        {!Report.disabled &&
                                            <Link className="mt-2 mb-2" to="/business/report">
                                                <Button className="card-btn" variant="warning"  onClick={() => { handleToggleNavBar(); }}>業務報表樞紐</Button>
                                            </Link>
                                        }
                                        {!Business.disabled &&
                                            <div style={{ padding: '1rem 0rem 0rem 0.4rem' }} >
                                                <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        案例管理相關查詢
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <div className="mb-2">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="dark" id="dropdown-basic">
                                                                    部門資料
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="/business" onClick={() => { doQuery(dispatch(defaultQuery.departmentCasesImcomplete3m())) }}>案例(未結案-3M)</Dropdown.Item>
                                                                    <Dropdown.Item href="/business" onClick={() => { doQuery(dispatch(defaultQuery.departmentCasesIncomplete())) }}>案例(未結案)</Dropdown.Item>
                                                                    <Dropdown.Item href="/business" onClick={() => { doQuery(dispatch(defaultQuery.departmentCases2Week())) }}>案例(2週內)</Dropdown.Item>
                                                                    <Dropdown.Item href="/business" onClick={() => { doQuery(dispatch(defaultQuery.departmentCasesAll())) }}>案例(ALL)</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        <div className="mb-2">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="dark" id="dropdown-basic">
                                                                    異常管理
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="/business" onClick={() => { doQuery(dispatch(defaultQuery.delayCases())) }}>逾期案例</Dropdown.Item>
                                                                    <Dropdown.Item href="/business" onClick={() => { doQuery(dispatch(defaultQuery._1week70pCases())) }}>一週未聯絡 70%以上案例</Dropdown.Item>
                                                                    <Dropdown.Item href="/business" onClick={() => { doQuery(dispatch(defaultQuery._2week50pCases())) }}>二週未聯絡 50%以上案例</Dropdown.Item>
                                                                    <Dropdown.Item href="/business" onClick={handleNonTracking}>起案無拜訪紀錄</Dropdown.Item>
                                                                    <Dropdown.Item href="/business" onClick={() => { doQuery(dispatch(defaultQuery.totalValueAsZero())) }}>銷售金額為0</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        <div className="mb-2">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="dark" id="dropdown-basic">
                                                                    業績預估
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="/business" onClick={() => { doQuery(dispatch(defaultQuery.predictionCasesMonth(1))) }}>未來一個月預計簽約案例</Dropdown.Item>
                                                                    <Dropdown.Item href="/business" onClick={() => { doQuery(dispatch(defaultQuery.predictionCasesMonth(3))) }}>未來三個月預計簽約案例</Dropdown.Item>
                                                                    <Dropdown.Item href="/business" onClick={() => { doQuery(dispatch(defaultQuery.predictionCasesMonth(6))) }}>未來六個月預計簽約案例</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        <div className="mb-2">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="dark" id="dropdown-basic">
                                                                    我的案例
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="/business" onClick={() => { doQuery(dispatch(defaultQuery.myCasesCompleted())) }}>我的案例(已結案)</Dropdown.Item>
                                                                    <Dropdown.Item href="/business" onClick={() => { doQuery(dispatch(defaultQuery.myCasesIncomplete())) }}>我的案例(未結案)</Dropdown.Item>
                                                                    <Dropdown.Item href="/business" onClick={() => { doQuery(dispatch(defaultQuery.myTrackingCases())) }}>我的追蹤紀錄</Dropdown.Item>
                                                                    <Dropdown.Item href="/business" onClick={() => { doQuery(dispatch(defaultQuery.myCases())) }}>我的案件</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                        <div className="mb-2">
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="dark" id="dropdown-basic">
                                                                    快速查詢
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="/business" onClick={() => {
                                                                        dispatch(collapseSearchBar(true));
                                                                        dispatch(expandNavBar(false));
                                                                    }}>案例快速查詢</Dropdown.Item>
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        }
                                    </Card.Body>
                                </Card>

                                <Card className="card-bg" >
                                    <Card.Header className="card-header">權限管理</Card.Header>
                                    <Card.Body style={{ padding: '0.5rem 0rem 0rem 1.4rem' }} >
                                        {!AdminBtn.disabled &&
                                            <div className="mb-2">
                                                <Link className="mt-2 mb-2" to="/usergroup">
                                                    <Button className="card-btn" variant="primary" onClick={() => { handleToggleNavBar(); }} >群組設定</Button>
                                                </Link>
                                                <Link className="mt-2 mb-2" to="/userFunc">
                                                    <Button className="card-btn" variant="primary" onClick={() => { handleToggleNavBar(); }} >權限設定</Button>
                                                </Link>
                                                <Link className="mt-2 mb-2" to="/config">
                                                    <Button className="card-btn" variant="primary" onClick={() => { handleToggleNavBar(); }} >設定檔</Button>
                                                </Link>
                                            </div>
                                        }
                                    </Card.Body>
                                </Card>


                                <Card className="card-bg" >
                                    <Card.Header className="card-header">物料管理</Card.Header>
                                    <Card.Body style={{ padding: '0rem 1rem' }} >
                                        {!MaterialSetQuery.disabled &&
                                            <Link className="mt-2 mb-2" to="/productSettings">
                                                <Button className="card-btn" variant="warning" onClick={() => { handleToggleNavBar();  }} >產品設定</Button>
                                            </Link>
                                        }
                                        {!MaterialQRCodeQuery.disabled &&
                                            <Link className="mt-2 mb-2" to="/qrCode">
                                                <Button className="card-btn" variant="warning" onClick={() => { handleToggleNavBar(); }}>QR Code</Button>
                                            </Link>
                                        }
                                        {!MaterialTrackQuery.disabled &&
                                            <Link className="mt-2 mb-2" to="/productTrack">
                                                <Button className="card-btn" variant="warning" onClick={() => { handleToggleNavBar(); }}>產品追蹤</Button>
                                            </Link>
                                        }
                                        {!MaterialTrackQuery.disabled &&
                                            <Link className="mt-2 mb-2" to="/statisticsReport">
                                                <Button className="card-btn" variant="warning" onClick={() => { handleToggleNavBar(); }}>統計報告</Button>
                                            </Link>
                                        }
                                        {!MaterialTrackQuery.disabled &&
                                            <Link className="mt-2 mb-2" to="/materialLog">
                                                <Button className="card-btn" variant="warning" onClick={() => { handleToggleNavBar();  }}>Log</Button>
                                            </Link>
                                        }
                                    </Card.Body>
                                </Card>

                                <Card className="card-bg" >
                                    <Card.Header className="card-header">維修管理</Card.Header>
                                    <Card.Body style={{ padding: '0rem 1rem' }} >
                                        {!MachBasicQuery.disabled &&
                                            <Link className="mt-2 mb-2" to="/machBasicTab/machBasic/0">
                                                <Button className="card-btn" variant="warning" onClick={() => { handleToggleNavBar();}} >基本資料</Button>
                                            </Link>
                                        }
                                        {!MachRecordQuery.disabled &&
                                            <Link className="mt-2 mb-2" to="/maintainTab/maintRecord/0">
                                                <Button className="card-btn" variant="warning" onClick={() => { handleToggleNavBar();}} >維修管理</Button>
                                            </Link>
                                        }
                                        {!MachReport.disabled &&
                                            <Link className="mt-2 mb-2" to="/reportTab/machReport/0">
                                                <Button className="card-btn" variant="warning" onClick={() => { handleToggleNavBar();}} >統計分析</Button>
                                            </Link>
                                        }
                                    </Card.Body>
                                </Card>
                            </Nav>
                        </Offcanvas.Body>
                        <hr />

                        <LoginStatus />
                    </Navbar.Offcanvas>

                </Container>
            </Navbar>
        </React.Fragment>
    );
}