import * as React from 'react';
import { NavMenu } from '../Navbar/NavHome.jsx';

export default class NavBar extends React.PureComponent {
    render() {
        return (
            <React.Fragment>
                <NavMenu />
                <div style={{ marginBottom:'15px' }}>
                    {this.props.children}
                </div>
            </React.Fragment>
        );
    }
}