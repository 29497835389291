import React, { useState, useRef, useCallback } from 'react';
import 'react-dropdown-tree-select/dist/styles.css'
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { Card, Row, Col, Button, Container, Form } from 'react-bootstrap';
import { faFileExport, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import NavBar from '../Navbar/NavBar.jsx';
import { qrCodeQuery } from '../../action/material/qrCodeAction.js';
import { StatisticsReportQuery } from '../../action/material/statisticsReportAction.js';

import { useRWD } from '../../useRWD.js';
import { store } from 'react-notifications-component';
import ExcelJs from "exceljs"
import Select from 'react-select';

const queryProduct = {
    productName: null
};
const queryParam = {
    productID: null,
    qrCodeID: null,
    customerNo: null,
    isHaveUDI: false,
    isVerify: false
};
const completeMessage = {
    title: "搜尋",
    message: "完成",
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 5000,
        onScreen: true
    }
};
export const StatisticsReport = (props) => {

    const dispatch = useDispatch();
    const rowStyle = {
        cursor: "pointer",
    };
    const [state, setState] = useState({ gridApi: null });//grid表
    const [filteredData, setFilteredData] = useState(null);
    //   let filteredData = null;

    const { productSettings, qrcode, statistics } = useSelector(x => x);
    //產品清單
    let productSettingsData = productSettings.productSettings;
    let temp = [];
    const [product, setProduct] = useState(queryProduct);// eslint-disable-line no-unused-vars
    //QR code 查詢 for 建立日期清單
    const queryQRCode = (productName) => {
        dispatch(qrCodeQuery(productName));

    }
    //客戶診所清單
    const QRCODEData = qrcode.qrCode;
    const SalesSlipCustomer = statistics.salesSlipCus;
    //搜尋參數
    const [data, setData] = useState(queryParam);
    const query = async () => {
        let filteredData = await dispatch(StatisticsReportQuery(data));
        setFilteredData(filteredData);
        store.addNotification(completeMessage);
    };
    //產品是否有選 比數和UDI元件 是否可用
    const [selectDisabled, setSelectDisabled] = useState(true);
    //
    //Select 客戶資料 快速搜尋
    const defaultOptions = [{ value: 0, label: '請選擇' }];
    SalesSlipCustomer.forEach((item) => {

        defaultOptions.push({
            value: item.customerNo,
            label: item.customerNo + '--' + item.customerName

        });

    });
    //匯出
    const gridRef = useRef();
    const onBtnExport = useCallback((params) => {
        const nodesToSelect = [];
        gridRef.current.api.forEachNode((node) => {
            if (node.selected === true) {
                nodesToSelect.push(node.data);
            }
        });
        exportMethod(nodesToSelect);
    }, []);

    //依據視窗大小調整 高度
    let device = useRWD();
    device = (device) + 'px';
    return (

        <React.Fragment >
            <NavBar />
            <Container fluid >
                <Row className="mx-auto">
                    <Card.Header>統計分析</Card.Header>
                    <Card className="mb-3" style={{ height: '100%', width: '100%', minWidth: '312px' }}>
                        <Card.Body>
                            <Form>
                                <Row className="mb-3" xs="auto">
                                    <Col sm="1">
                                        <Form.Label>
                                            <strong>產品</strong>
                                        </Form.Label>
                                    </Col>
                                    <Col sm="3">
                                        <select
                                            className="form-select"
                                            onChange={(e) => {
                                                let selectedIndex = e.target.options.selectedIndex;
                                                let productID_temp = e.target.options[selectedIndex].getAttribute('data-key');
                                                if (productID_temp === '0') {
                                                    product.productName = '';
                                                    setSelectDisabled(true);
                                                } else {
                                                    product.productName = productID_temp;
                                                    setSelectDisabled(false);
                                                }
                                                queryQRCode(product);

                                            }}>
                                            <option key={0} data-key={0} value={0} >請選擇</option>
                                            {/* eslint-disable array-callback-return */
                                                productSettingsData.map(item => {
                                                    if (!temp.includes(item.addressCategory) && item.addressCategory != null) {
                                                        temp.push(item.addressCategory);
                                                        return (<option key={item.id} data-key={item.addressCategory} value={item.id}>{item.addressCategory}</option>)
                                                    }
                                                })
                                                /* eslint-enable array-callback-return */
                                            }
                                        </select>
                                    </Col>
                                    <Form.Label column>
                                        <strong>建立日期/筆數</strong>
                                        </Form.Label>
                                    <Col sm="3">
                                        <select className="form-select" disabled={selectDisabled}
                                            onChange={(e) => {
                                                let selectedIndex = e.target.options.selectedIndex;
                                                let productID_temp = e.target.options[selectedIndex].getAttribute('data-key');
                                                setData({
                                                    ...data,
                                                    qrCodeID: e.target.value,
                                                    productID: productID_temp
                                                });
                                            }}>
                                            <option key={0} data-key={0} value={0} >請選擇</option>
                                            {
                                                /* eslint-disable array-callback-return */
                                                QRCODEData.map(item => {
                                                    return (<option key={item.id} data-key={item.productID} value={item.id}>{item.createdDate.replace('T', ' ').substr(0, 19) + '/' + item.quantity}</option>)
                                                })
                                                /* eslint-enable array-callback-return */
                                            }
                                        </select>
                                    </Col>
                                    <Col>
                                        <Form.Check type="switch" id="isVerify" label={<strong>已被驗證</strong>} disabled={selectDisabled}
                                            onChange={(e) => {
                                                setData({ ...data, isVerify: e.target.checked });
                                            }}                                         >
                                        </Form.Check>
                                    </Col>
                                 
                                </Row>
                                <Row className="mb-3" xs="auto">
                                    <Col sm="1">
                                    <Form.Label>
                                        <strong>客供應商</strong>
                                        </Form.Label>
                                    </Col>
                                    <Col sm="3">
                                        {/* 快速搜尋 功能*/}
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            isSearchable="true"
                                            onChange={(e) => {
                                                setData({ ...data, customerNo: e.value });
                                            }}
                                            options={defaultOptions}
                                        />
                                    </Col>
                                    <Col >
                                        <Form.Check type="switch" id="isHaveUDI" label={<strong>已匯入UDI</strong>}
                                            onChange={(e) => {
                                                setData({ ...data, isHaveUDI: e.target.checked });
                                            }}                                        >
                                        </Form.Check>
                                    </Col>

                                    <Col md={{ span: 1, offset: 2 }}>
                                        <Button variant="primary" onClick={query}>
                                            <FontAwesomeIcon className='rounded' icon={faSearch} />
                                            搜尋
                                        </Button>
                                    </Col>
                                    <Col md={{ span: 1, offset: 2 }} style={{ textAlign: "right" }}>
                                        <Button variant="warning" style={{ height: 30, width: 150, lineHeight: 0.5 }} onClick={() => {
                                            onBtnExport();
                                        }}>
                                            <FontAwesomeIcon className='rounded' icon={faFileExport} />
                                            匯出
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>


                            <Row>

                                <div className="ag-theme-alpine " style={{ height: device, width: '100%' }}>
                                    <div style={{ height: '100%', width: '100%' }}>
                                        <AgGridReact
                                            ref={gridRef}
                                            rowStyle={rowStyle}
                                            rowData={filteredData}
                                            defaultColDef={{
                                                sortable: true,
                                                resizable: true,
                                                wrapText: true,
                                                autoHeight: true,
                                                filter: true
                                            }}
                                            onGridReady={(params) => {
                                                setState({
                                                    ...state,
                                                    gridApi: params.api
                                                });

                                            }}
                                            paginationAutoPageSize={true}
                                            pagination={true}
                                            rowSelection={'multiple'}
                                        >
                                            <AgGridColumn headerName="No." field="id" width={80} pinned="left" headerCheckboxSelection={true} checkboxSelection={true}></AgGridColumn>
                                            <AgGridColumn headerName="銷貨客供應商" field="customerName" width={150} pinned="left"></AgGridColumn>
                                            <AgGridColumn headerName="銷貨單號" field="salesSlipNo" width={160}></AgGridColumn>
                                            <AgGridColumn headerName="銷貨批號" field="salesLotNo" width={110}></AgGridColumn>
                                            <AgGridColumn headerName="產品代號" field="productNo" width={160}></AgGridColumn>
                                            <AgGridColumn headerName="品名" field="productName" width={220}></AgGridColumn>
                                            <AgGridColumn headerName="銷貨日期" field="salesDate" width={110}></AgGridColumn>
                                            <AgGridColumn headerName="驗證時間" field="verifyDate" width={170}></AgGridColumn>
                                            <AgGridColumn headerName="UDI" field="udi" width={250}></AgGridColumn>
                                            <AgGridColumn headerName="QRCode" field="qrCode" width={250}></AgGridColumn>
                                        </AgGridReact>
                                    </div>

                                </div>

                            </Row>

                        </Card.Body>
                    </Card>
                </Row>

            </Container>
        </React.Fragment >
    );
}

const exportMethod = async (data) => {

    let rows_array = [];
    data.forEach((item, index) => {
        let temp = [];
        temp[0] = item.customerName;
        temp[1] = item.salesSlipNo;
        temp[2] = item.salesLotNo;
        temp[3] = item.productNo;
        temp[4] = item.productName;
        temp[5] = item.salesDate;
        temp[6] = item.verifyDate;
        temp[7] = item.udi;
        temp[8] = item.qrCode;
        rows_array[index] = temp;
    });

    const workbook = new ExcelJs.Workbook(); // 創建試算表檔案
    const sheet = workbook.addWorksheet('sheet1'); //在檔案中新增工作表 參數放自訂名稱
    sheet.addTable({ // 在工作表裡面指定位置、格式並用columsn與rows屬性填寫內容
        name: 'table名稱',  // 表格內看不到的，算是key值，讓你之後想要針對這個table去做額外設定的時候，可以指定到這個table
        ref: 'A1', // 從A1開始
        columns: [{ name: '銷貨客供應商' }, { name: '銷貨單號' }, { name: '銷貨批號' }, { name: '產品代號' }, { name: '品名' }, { name: '銷貨日期' }, { name: '驗證時間' }, { name: 'UDI' }, { name: 'QRCode' }],
        rows: rows_array
    });

    // 表格裡面的資料都填寫完成之後，訂出下載的callback function
    // 異步的等待他處理完之後，創建url與連結，觸發下載
    workbook.xlsx.writeBuffer().then((content) => {
        const link = document.createElement("a");
        const blobData = new Blob([content], {
            type: "application/vnd.ms-excel;charset=utf-8;"
        });
        const time = (new Date(Date.now())).toLocaleString('sv');

        link.download = `統計分析_${time}.xlsx`;
        link.href = URL.createObjectURL(blobData);
        link.click();
    });

}