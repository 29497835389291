import axios from 'axios';

const version_default = 'default';

const localRequest = axios.create({
    baseURL: '/',
});

const API = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: false,
});

/*
 * API�ݪ�CORS�]�w����: 
 * Access-Control-Allow-Credentials : false
 * Access-Control-Allow-Origin : *
 */
const InitAPIRequest = () => axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    withCredentials: false,
    headers: { Authorization: `AuthToken ${sessionStorage.getItem("authToken")}` }
});
/* eslint-disable no-unused-vars */
const InitRequest = () => axios.create({
    baseURL: '/',
    withCredentials: false,
    headers: { Authorization: `AuthToken ${sessionStorage.getItem("authToken")}` }
});
/* eslint-enable no-unused-vars */

//User
export const apiUserLogin = data => API.post('api/Auth/MambaIn', data);
//export const apiUserLogin = data => localRequest.post(version_default + '/Auth/MambaIn',data);



export const apiUserLoginCheck = data => localRequest.post(version_default + '/auth/authorization', data);
//Case
export const apiBusinessReportQuery = data => {
    return localRequest.get(version_default + '/case/BusinessReport', { params: data });
}
export const apiBusinessReportDetailQuery = data => {
    return localRequest.get(version_default + '/case/BusinessReportDetail', { params: data });
}
export const apiCaseQuery = data => {
    //console.log(data);
    return localRequest.get(version_default + '/case', { params: data });
}
export const apiCaseInsert = data => localRequest.post(version_default + '/case', data);
export const apiCaseUpdate = data => localRequest.put(version_default + '/case', data);
//CaseLog
export const apiCaseLogQuery = data => {
    //console.log(data);
    return localRequest.get(version_default + '/caselog', { params: data });
}
//Config
export const apiConfigQuery = data => localRequest.get(version_default + '/config', data);
export const apiConfigInsert = data => localRequest.post(version_default + '/config', data);
//Customer
//export const apiCustomerQuery = data => localRequest.get(version_default + '/customer', { params: data });
export const apiCustomerQuery = data => {

    return localRequest.get(version_default + '/customer', { params: data });
}
export const apiCustomerInsert = data => localRequest.post(version_default + '/customer', data);
export const apiCustomerUpdate = data => localRequest.put(version_default + '/customer', data);
//CustContacts
export const apiCustContactsInsert = data => localRequest.post(version_default + '/custcontacts', data);
export const apiCustContactsUpdate = data => localRequest.put(version_default + '/custcontacts', data);
//Roles
export const apiRolesQuery = data => localRequest.get(version_default + '/roles', { params: data });
export const apiRolesInsert = data => localRequest.post(version_default + '/roles', data);
export const apiRolesUpdate = data => localRequest.put(version_default + '/roles', data);
//RoleMembers
export const apiRoleMembersInsert = data => localRequest.post(version_default + '/rolemembers', data);
export const apiRoleMembersUpdate = data => localRequest.put(version_default + '/rolemembers', data);
//Administration-Group
export const apiUserGroupQuery = data => localRequest.get(version_default + '/userGroup', { params: data });
export const apiUserGroupInsert = data => localRequest.post(version_default + '/userGroup', data);
export const apiUserGroupUpdate = data => localRequest.put(version_default + '/userGroup', data);
//AdminFunc
export const apiAdminFuncQuery = data => {
    return localRequest.get(version_default + '/userGroup/AdminFunc', { params: data });
}
//Administration-Function
export const apiUserFunctionQuery = data => localRequest.get(version_default + '/userFunc', { params: data });
export const apiUserFunctionInsert = data => localRequest.post(version_default + '/userFunc', data);
export const apiUserFunctionUpdate = data => localRequest.put(version_default + '/userFunc', data);

const defaultDepartment = {
    ID: process.env.REACT_APP_DEPARTMET_ID,
    Code: process.env.REACT_APP_DEPARTMET_CODE
};

//SEAPI
export const apiOrgStruct = (param = defaultDepartment) => InitAPIRequest().post('api/Organization/Structure', param);
export const apiOrgMembers = (param = defaultDepartment) => InitAPIRequest().post('api/Organization/AllMember', param);
export const apiOrgDepartments = () => InitAPIRequest().post('api/Organization/Departments');

//local
//export const apiOrgStruct = (data = defaultDepartment) => InitRequest().post(version_default + '/Organization/Structure', data);
//export const apiOrgMembers = (data = defaultDepartment) =>  InitRequest().post(version_default + '/Organization/AllMember', data );
//export const apiOrgDepartments = () => InitRequest().post(version_default + '/Organization/Departments');



export const apiNonTrackingCases = () => localRequest.get(version_default + '/case/NonTrackingCases');

//**Material**
//ProductSettings
export const apiProductSettingsQuery = data => localRequest.get(version_default + '/productSettings', { params: data });
export const apiProductSettingsInsert = data => localRequest.post(version_default + '/productSettings', data);
export const apiProductSettingsUpdate = data => localRequest.put(version_default + '/productSettings', data);

//QRCode
export const apiQRCodeQuery = data => localRequest.get(version_default + '/qrCode', { params: data });
export const apiQRCodeProductItemQuery = data => {
    return localRequest.get(version_default + '/qrCode/ProductItem', { params: data });
}
export const apiSalesSlipQuery = data => {
    return localRequest.get(version_default + '/qrCode/SalesSlip', { params: data });
}
export const apiQRCodeInsert = data => localRequest.post(version_default + '/qrCode', data);
export const apiQRCodeUpdate = data => localRequest.put(version_default + '/qrCode', data);
//ProductTrack
export const apiProductTrackQuery = data => localRequest.get(version_default + '/productTrack', { params: data });
//PhotUpload
export const apiPhotDownload = data => localRequest.get(version_default + '/file', { params: data });
export const apiPhotUpload = (data, ss) => localRequest.post(version_default + '/file/Upload', data, { params: ss });
//schedule
export const apiScheduledProduct = () => localRequest.get(version_default + '/schedule/product');
export const apiScheduledSalesSlip = () => localRequest.get(version_default + '/schedule/salesSlip');
//log
export const apiMaterialLogQuery = () => localRequest.get(version_default + '/materialLog');
//StatisticsReport
export const apiSalesSlipCustomerQuery = () => localRequest.get(version_default + '/statisticsReport/customer');
export const apiStatisticsReportQuery = data => localRequest.get(version_default + '/statisticsReport', { params: data });
//MachSupplier
export const apiMachSupplierQuery = data => localRequest.get(version_default + '/machSupplier', { params: data });
export const apiMachSupplierInsert = data => localRequest.post(version_default + '/machSupplier', data);
export const apiMachSupplierUpdate = data => localRequest.put(version_default + '/machSupplier', data);
export const apiMachSupplierDelete = data => localRequest.put(version_default + '/machSupplier/delete', data);
//MachBasic
export const apiMachBasicQuery = data => localRequest.get(version_default + '/machBasic', { params: data });
export const apiMachBasicInsert = data => localRequest.post(version_default + '/machBasic', data);
export const apiMachBasicUpdate = data => localRequest.put(version_default + '/machBasic', data);
export const apiMachBasicDelete = data => localRequest.put(version_default + '/machBasic/delete', data);
//MachHandle
export const apiMachHandleQuery = data => localRequest.get(version_default + '/machHandle', { params: data });
export const apiMachHandleInsert = data => localRequest.post(version_default + '/machHandle', data);
export const apiMachHandleUpdate = data => localRequest.put(version_default + '/machHandle', data);
export const apiMachHandleDelete = data => localRequest.put(version_default + '/machHandle/delete', data);
//MaintRecord
export const apiMaintRecordQuery = data => localRequest.get(version_default + '/maintRecord', { params: data });
export const apiMaintRecordQueryEQP = data => localRequest.get(version_default + '/maintRecord/record', { params: data });
export const apiMaintRecordInsert = data => localRequest.post(version_default + '/maintRecord', data);
export const apiMaintRecordUpdate = data => localRequest.put(version_default + '/maintRecord', data);
export const apiMaintRecordDelete = data => localRequest.put(version_default + '/maintRecord/delete', data);
export const apiMaintRecordDeleteDetails = data => localRequest.put(version_default + '/maintRecord/deleteDetails', data);
//MaintContract
export const apiMaintContractQuery = data => localRequest.get(version_default + '/maintContract', { params: data });
export const apiMaintContractInsert = (data) => localRequest.post(version_default + '/maintContract', data );
export const apiMaintContractUpdate = data => localRequest.put(version_default + '/maintContract', data);
export const apiMaintContractDelete = data => localRequest.put(version_default + '/maintContract/delete', data);
//Maintain-Report
export const apiMachReportQuery = data => localRequest.get(version_default + '/maintReport/machReport', { params: data });
export const apiMachReportQueryDetail = data => localRequest.get(version_default + '/maintReport/machReportDetail', { params: data });
export const apiEmployeeReportQuery = data => localRequest.get(version_default + '/maintReport/employeeReport', { params: data });
export const apiEmployeeReportQueryDetail = data => localRequest.get(version_default + '/maintReport/employeeReportDetail', { params: data });