import { customerQuery, customerUpdate, customerInsert } from '../../action/customer/customerActions.js';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Form, Row, Button, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { store } from 'react-notifications-component';
import { useParams, useHistory, useLocation } from "react-router-dom";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { CustContactsAddBox } from '../Customer/AddBox/CustContactsAddBox.jsx';
import { CustContactsEditBox } from '../Customer/EditBox/CustContactsEditBox.jsx';
import { CenteredModalYesNo } from '../../components/Material/CenteredModal';
import { useCurrentUser, useDepartment } from '../../utils/hooks';

const defaultMessage = {
    title: "更新",
    message: "資料上傳中，請稍後...",
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 3000,
        onScreen: true
    }
};

const completeMessage = {
    title: "更新",
    message: "更新完成",
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 3000,
        onScreen: true
    }
};

const emptyCust = {
    name: null,
    assistingBy: null,
    custContacts: [],
    taxId: null,
    telephone: null,
    officialWebsite: null,
    address: null,
    note: null,
    customer: null,
    flag: 1,
    engineer: null
};

let selectedContact = {};

export const CustomerBox = (props) => {
    const { onCustomerModify = () => { } } = props;
    const { customers, organization } = useSelector(x => x);
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();
    const { id } = useParams();

    const isAdd = id == null || id == "" || id == 0;// eslint-disable-line
    const saveCustomer = (customer) => {
        if (isAdd) {
            return customerInsert(customer);
        }
        else {
            return customerUpdate(customer);
        }
    }

    const previous_path = () => {

        if (location.pathname.indexOf('tab') > 0) {
            return false;
        } else {
            return true;
        }
    }
    const [isShowAdd, setShowAdd] = useState(false);
    const [isShowEdit, setShowEdit] = useState(false);
    const [isShowAssistingBy, setShowAssistingBy] = useState(true);
    const [data, setData] = useState(emptyCust);
    const user = useCurrentUser();
    const department = useDepartment(user.departmentID);
    useEffect(() => {
        const editingCustomer = customers.find(e => e.customerId == id);// eslint-disable-line
        setData(isAdd ? emptyCust : editingCustomer);
        onCustomerModify(emptyCust);
      
        //技術部
        if (department.ID === 58) {
            setShowAssistingBy(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    /* eslint-disable */
    const addMode = () => {
        if (id == null || id == "" || id == 0) return true;
        return false;
    }

    const CustEdit = useSelector(x => x.ui.CustEdit);
    const close = () => {
        if (CustEdit.disabled === false) {
            setModalShow(true);

        } else {
            dispatch(customerQuery());
            history.goBack();
        }
    }
    /* eslint-enable */

    const insertOrUpdateData = async (data) => {
        let msgId = store.addNotification(defaultMessage);
        await dispatch(saveCustomer(data));
        await dispatch(customerQuery());
        store.removeNotification(msgId);
        store.addNotification(completeMessage);

        history.goBack();
    }
    const toggleAdd = () => {
        setShowAdd(x => x = !x);
    }

    const toggleEdit = () => {
        setShowEdit(x => x = !x);
    }

    //modal
    const [modalShow, setModalShow] = useState(false);

    return (
        <React.Fragment>
            <Container>

                <Row>
                    <Col md={6} className="mx-auto">
                        <Card className="mb-1" style={{ width: '100%', minWidth: '312px' }}>
                            <Card.Body>
                                <Form autoComplete="false">
                                    {previous_path() &&
                                        <Row style={{ margin: '0 0 20px 0' }} >
                                            <Button onClick={close}>
                                                <FontAwesomeIcon className='rounded me-2' icon={faTimes} />
                                                關閉
                                            </Button>
                                        </Row>

                                    }
                                    <Row>
                                        <Form.Group as={Row} className="mb-4">
                                            <Form.Label column sm="3">
                                                <strong>客戶名稱</strong>
                                            </Form.Label>
                                            <Col sm="9">
                                                <Form.Control type="text" placeholder=""
                                                    defaultValue={addMode() ? "" : data.name}
                                                    onChangeCapture={(e) => {
                                                        setData({ ...data, name: e.target.value });
                                                        // data.name = e.target.value;
                                                        onCustomerModify(data);
                                                    }}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-4">
                                            <Form.Label column sm="3">
                                                <strong>統編</strong>
                                            </Form.Label>
                                            <Col sm="9">
                                                <Form.Control type="text" placeholder=""
                                                    defaultValue={addMode() ? "" : data.taxId}
                                                    onChangeCapture={(e) => {
                                                        setData({ ...data, taxId: e.target.value });
                                                        //data.taxId = e.target.value;
                                                        onCustomerModify(data);
                                                    }}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-4">
                                            <Form.Label column sm="3">
                                                <strong>室內電話</strong>
                                            </Form.Label>
                                            <Col sm="9">
                                                <Form.Control type="text" placeholder=""
                                                    defaultValue={addMode() ? "" : data.telephone}
                                                    onChangeCapture={(e) => {
                                                        setData({ ...data, telephone: e.target.value });
                                                        //data.telephone = e.target.value;
                                                        onCustomerModify(data);
                                                    }}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-4">
                                            <Form.Label column sm="3">
                                                <strong>官方網站</strong>
                                            </Form.Label>
                                            <Col sm="9">
                                                <Form.Control type="text" placeholder=""
                                                    defaultValue={addMode() ? "" : data.officialWebsite}
                                                    onChangeCapture={(e) => {
                                                        setData({ ...data, officialWebsite: e.target.value });
                                                        //data.officialWebsite = e.target.value;
                                                        onCustomerModify(data);
                                                    }}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-4">
                                            <Form.Label column sm="3">
                                                <strong>地址</strong>
                                            </Form.Label>
                                            <Col sm="9">
                                                <Form.Control type="text" placeholder=""
                                                    defaultValue={addMode() ? "" : data.address}
                                                    onChangeCapture={(e) => {
                                                        setData({ ...data, address: e.target.value });
                                                        //data.address = e.target.value;
                                                        onCustomerModify(data);
                                                    }}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-4">
                                            <Form.Label column sm="3">
                                                <strong>備註</strong>
                                            </Form.Label>
                                            <Col sm="9">
                                                <Form.Control type="text" placeholder=""
                                                    defaultValue={addMode() ? "" : data.note}
                                                    onChangeCapture={(e) => {
                                                        setData({ ...data, note: e.target.value });
                                                        //data.note = e.target.value;
                                                        onCustomerModify(data);
                                                    }}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-4">
                                            <Form.Label column sm="3">
                                                <strong>負責人</strong>
                                            </Form.Label>
                                            {isShowAssistingBy &&
                                                <Col sm="9">
                                                    <select
                                                        className="form-select"
                                                        value={data.assistingBy == null ? -1 : data.assistingBy}
                                                        onChange={(e) => {
                                                            setData({ ...data, assistingBy: e.target.value });
                                                        }}
                                                    >
                                                        {organization.members.map(item => {
                                                            return (<option key={item.ID} value={item.ID}>{item.Name}</option>)
                                                        })}
                                                    </select>
                                                </Col>
                                            }
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-4">
                                            <Form.Label column sm="3">
                                                <strong>負責工程師</strong>
                                            </Form.Label>
                                            <Col sm="9">
                                                <select
                                                    className="form-select"
                                                    value={data.engineer == null ? -1 : data.engineer}
                                                    onChange={(e) => {
                                                        setData({ ...data, engineer: e.target.value });
                                                    }}
                                                >
                                                    { // eslint-disable-next-line array-callback-return
                                                        organization.members.map(item => {
                                                        if (item.DepartmentID === 58) {
                                                            return (<option key={item.ID} data-key={item.DepartmentID} value={item.ID}>{item.Name}</option>)
                                                        }
                                                    })}

                                                </select>
                                            </Col>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <div>
                                            <Button variant="primary" onClick={toggleAdd} style={{ display: addMode() }}>
                                                <FontAwesomeIcon className='rounded me-2' icon={faPlus} />
                                                新增聯絡人
                                            </Button>
                                        </div>
                                        <CustContactsEditBox
                                            data={selectedContact}
                                            onSave={(contact) => {
                                                /* eslint-disable */
                                                const newArray = data.custContacts
                                                    .filter((item) => item.id != contact.id)
                                                    ;
                                                /* eslint-enable */
                                                let newData = {
                                                    ...data,
                                                    custContacts: [...newArray, contact]
                                                };
                                                console.log(newData);
                                                setData(newData);
                                                onCustomerModify(newData);
                                            }}

                                            show={isShowEdit}
                                            onHide={toggleEdit}
                                        />
                                        <CustContactsAddBox
                                            data={data.customerId}
                                            setContact={(contact) => {
                                                let newData = {
                                                    ...data,
                                                    custContacts: [...data.custContacts, contact]
                                                };
                                                setData(newData);
                                                onCustomerModify(newData);
                                            }}
                                            show={isShowAdd}
                                            onHide={toggleAdd}
                                        />
                                    </Row>

                                    <Row>
                                        <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                                            <AgGridReact
                                                rowData={data.custContacts}
                                                defaultColDef={{
                                                    sortable: true,
                                                    resizable: true,
                                                    wrapText: true,
                                                    autoHeight: true,
                                                    filter: true,
                                                    animateRows: true
                                                }}
                                                onRowClicked={(e) => {
                                                    const contact = data.custContacts.find(x => x.name == e.data.name);// eslint-disable-line
                                                    selectedContact = { ...contact };
                                                    toggleEdit();
                                                }}
                                                frameworkComponents={{
                                                    checkboxRenderer: (contact) => {
                                                        return (
                                                            <input
                                                                type="checkbox"
                                                                checked={contact.value}
                                                                disabled
                                                            />
                                                        )
                                                    }
                                                }}
                                            >
                                                <AgGridColumn headerName="主要聯絡人" field="mainContact" cellRenderer="checkboxRenderer" width={130}></AgGridColumn>
                                                <AgGridColumn headerName="聯絡人名稱" field="name" width={130}></AgGridColumn>
                                                <AgGridColumn headerName="職稱" field="title" width={130}></AgGridColumn>
                                                <AgGridColumn headerName="連絡電話" field="cellphone" width={130}></AgGridColumn>
                                                <AgGridColumn headerName="E-mail" field="email" width={250}></AgGridColumn>
                                            </AgGridReact>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Form.Group>
                                            <Row className="mx-auto">
                                                {!CustEdit.disabled &&
                                                    <Button
                                                        variant="primary"
                                                        onClick={() => {
                                                            /* eslint-disable */
                                                            if (data.name == null || data.name == "" || data.name == undefined) {
                                                                window.alert("請輸入客戶名稱");
                                                            }
                                                            else {
                                                                insertOrUpdateData(data);
                                                            }
                                                            /* eslint-enable */
                                                        }}
                                                    >
                                                        <FontAwesomeIcon className='rounded me-2' icon={faSave} />
                                                        儲存
                                                    </Button>
                                                }
                                            </Row>
                                        </Form.Group>
                                    </Row>
                                    <CenteredModalYesNo
                                        title={'存檔'}
                                        message={'確定要存檔?'}
                                        btnText1={'確定'}
                                        btnText2={'取消'}
                                        show={modalShow}
                                        onHide={() => {
                                            dispatch(customerQuery());
                                            history.goBack(); setModalShow(false)
                                        }}
                                        onClick={() => {
                                            if (data.name === null || data.name === "" || data.name === undefined) {
                                                window.alert("請輸入客戶名稱");
                                            } else {
                                                insertOrUpdateData(data);
                                            } setModalShow(false);
                                        }}
                                    />
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </React.Fragment>
    )

}
