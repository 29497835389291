import { rolesQuery, rolesUpdate, rolesInsert, rolemembersInsert, rolemembersUpdate } from '../../../action/permission/rolesActions.js';
import React from 'react';
import { connect } from 'react-redux';
import { Card, Form, Row, Button, Col, Offcanvas, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faPenSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { store } from 'react-notifications-component';
import { useParams, useHistory } from "react-router-dom";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const defaultMessage = {
    title: "更新",
    message: "資料上傳中，請稍後...",
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 3000,
        onScreen: true
    }
};

const completeMessage = {
    title: "更新",
    message: "更新完成",
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 3000,
        onScreen: true
    }
};

const emptyRole = {
    id: null,
    name: null
};

export const RoleMembersComponent = (props) => {
    const { rolesQuery, rolesUpdate, rolesInsert, /*rolemembersInsert, rolemembersUpdate,*/ roles } = props;
    const history = useHistory();
    const { id } = useParams();
    const addMode = () => {
        if (id === null || id === "" || id === 0) return true;
        return false;
    }
    const close = () => history.goBack();
    const data = addMode() ? emptyRole : Object.assign({}, roles.find(e => e.Id === id));
    const insertOrUpdateData = async (data) => {
        let msgId = store.addNotification(defaultMessage);
        if (addMode()) {
            await rolesInsert(data);
        }
        else {
            await rolesUpdate(data);
        }
        await rolesQuery();
        store.removeNotification(msgId);
        store.addNotification(completeMessage);
    }

    return (
        <React.Fragment>
            <Offcanvas
                show={true}
                onHide={props.onHide}
                placement="end"
                style={{ minWidth: '375px', width: '100%' }}
            >
                <Offcanvas.Header className="bg-primary text-light">
                    <Offcanvas.Title>
                        <FontAwesomeIcon className='rounded me-2' icon={faPenSquare} />
                        {addMode() ? "新增身份資料" : "編輯身份資料"}
                    </Offcanvas.Title>
                    <Button onClick={close}>
                        <FontAwesomeIcon className='rounded me-2' icon={faTimes} />
                        關閉
                    </Button>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Container>.
                        <Row>
                            <Col md={6} className="mx-auto">
                                <Card className="mb-1" style={{ width: '100%', minWidth: '312px' }}>
                                    <Card.Body>
                                        <Form autoComplete="false">
                                            <Row>
                                                <Col>
                                                    <Card>
                                                        <Card.Body>
                                                            <Card.Title>{data.name}</Card.Title>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col>
                                                    
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Form.Group>
                                                    <Row className="mx-auto">
                                                        <Button
                                                            variant="primary"
                                                            onClick={() => {
                                                                if (data.name === null || data.name === "" || data.name === undefined) {
                                                                    window.alert("請輸入身份名稱");
                                                                }
                                                                else if (roles.find(x => x.name === data.name) !== null && addMode()) {
                                                                    window.alert("身份名稱不可重複");
                                                                }
                                                                else {
                                                                    insertOrUpdateData(data);
                                                                    close();
                                                                }
                                                            }}
                                                        >
                                                            <FontAwesomeIcon className='rounded me-2' icon={faSave} />
                                                            儲存
                                                        </Button>
                                                    </Row>
                                                </Form.Group>
                                            </Row>
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    )

}

export const RoleMembers = connect((state) => state, { rolesUpdate, rolesInsert, rolesQuery, rolemembersInsert, rolemembersUpdate })(RoleMembersComponent)