import React, { useState, useRef, useCallback } from 'react';
import { Form, Row, Button, Col, Container } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { useRWD } from '../../../../useRWD.js';
import { staffReportQuery, staffReportDetailQuery } from '../../../../action/maintain/reportAction.js';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { store } from 'react-notifications-component';
import { exportExcelMethod } from '../../../../exportExcel.js';

const defaultstate = {
    StartDate: new Date(),
    EndDate: new Date(),
    department: [],
    gridApi: null
};

const completeMessage = {
    title: "搜尋",
    message: "搜尋完成",
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 300,
        onScreen: true
    }
};

export const MaintStaffReport = (props) => {
    const dispatch = useDispatch();

    const rowStyle = {
        cursor: "pointer",
    };
    const [state, setState] = useState(defaultstate);
    const { maintReport, organization } = useSelector(x => x);
    //表資料帶入
    let filteredData = maintReport.staffReport;
    //搜尋
    const handleLoadingUI = (isShow) => { if (isShow === true) { dispatch({ type: 'SHOW_LOADING' }); } else { dispatch({ type: 'HIDE_LOADING' }); } }//show laod UI
    const handlesearch = async () => {
        handleLoadingUI(true);
         dispatch(await staffReportQuery({
            StartDate: state.StartDate,
            EndDate: state.EndDate
        }));
         dispatch(await staffReportDetailQuery({
            StartDate: state.StartDate,
            EndDate: state.EndDate
        }));
        handleLoadingUI(false);
        store.addNotification(completeMessage);
    }
    //匯出excel
    const gridRef = useRef();
    const onBtnExport = useCallback((params) => {
        agGridDataHandle(gridRef, organization);
    }, []);//  eslint-disable-line react-hooks/exhaustive-deps
    //依據視窗大小調整 高度
    let device = useRWD();
    device = (device + 75) + 'px';

    return (

        <React.Fragment >
            <Container fluid>
                <Row className="mx-auto">
                    <Form.Group as={Row} className="mb-1">
                        <Form.Label>日期區間</Form.Label>
                        <Col md={2}>
                            <DatePicker
                                className="form-control"
                                placeholder="開始"
                                isClearable
                                selectsStart
                                selected={
                                    state.StartDate == null ? state.StartDate : new Date(state.StartDate)
                                }
                                dateFormat="yyyy/MM/dd"
                                onChange={(date) => {
                                    setState({
                                        ...state, StartDate: date
                                    });
                                }} />
                        </Col>

                        <Col md={2}>
                            <DatePicker
                                className="form-control"
                                placeholder="結束"
                                isClearable
                                selectsEnd
                                dateFormat="yyyy/MM/dd"
                                selected={
                                    state.EndDate == null ? state.EndDate : new Date(state.EndDate)
                                }
                                onChange={(date) => {
                                    setState({
                                        ...state, EndDate: date
                                    });
                                }} />
                        </Col>
                        <Col md={2}>
                            <Button variant="primary"
                                onClick={(e) => {
                                    handlesearch();
                                }}
                            >
                                <FontAwesomeIcon className='rounded' icon={faSearch} />
                                搜尋
                            </Button>
                        </Col>
                        <Col md={2}>
                            <Button variant="outline-primary" className='me-1' onClick={() => { onBtnExport(); }}>
                                <FontAwesomeIcon className='rounded me-2' icon={faFileExport} />
                                Export
                            </Button>
                        </Col>
                    </Form.Group>
                </Row>
                <Row className="mx-auto mt-3">
                    <div className="ag-theme-alpine " style={{ height: device, width: '100%' }}>
                        <div style={{ height: '100%', width: '100%' }}>

                            <AgGridReact
                                ref={gridRef}
                                rowData={filteredData}
                                rowStyle={rowStyle}
                                paginationAutoPageSize={true}
                                defaultColDef={{
                                    sortable: true,
                                    resizable: true,
                                    wrapText: true,
                                    autoHeight: true,
                                    filter: true
                                }}

                                onGridReady={(params) => {
                                    setState({
                                        ...state,
                                        gridApi: params.api
                                    });
                                }}


                            >
                                <AgGridColumn headerName="細項" field="employee" cellRendererFramework={EditRenderer} width={100} ></AgGridColumn>
                                <AgGridColumn headerName="維修人員" field="employee" width={105} valueGetter={(x) => {
                                    if (x.data.employee === "" || x.data.employee === undefined) {
                                        return null;
                                    }
                                    let item = organization.allMembers.find((e) => e.ID === x.data.employee);
                                    return item.Name;
                                }}></AgGridColumn>
                                <AgGridColumn headerName="總維修量" field="totalRecords_Period" headerTooltip="從古至今計算" width={105}></AgGridColumn>
                                <AgGridColumn headerName="區間維修量" field="records_Period" headerTooltip="依搜尋區間計算" width={110}></AgGridColumn>
                                <AgGridColumn headerName="月維修量" field="monRecords_Period" headerTooltip="依月計算，區間跨月時以上個月計算(ex:3/29~4/6 計算3月)" width={120}></AgGridColumn>
                                <AgGridColumn headerName="保養(保固期)" field="jc1" width={130}></AgGridColumn>
                                <AgGridColumn headerName="保養(合約)" field="jc2" width={120}></AgGridColumn>
                                <AgGridColumn headerName="保養(非合約)" field="jc3" width={130}></AgGridColumn>
                                <AgGridColumn headerName="叫修(保固期)" field="jc4" width={130}></AgGridColumn>
                                <AgGridColumn headerName="叫修(合約)" field="jc5" width={120}></AgGridColumn>
                                <AgGridColumn headerName="叫修(非合約)" field="jc6" width={130}></AgGridColumn>
                                <AgGridColumn headerName="教育訓練" field="jc7" width={110}></AgGridColumn>
                                <AgGridColumn headerName="業務新機測試" field="jc8" width={130}></AgGridColumn>
                                <AgGridColumn headerName="業務/展示機" field="jc9" width={130}></AgGridColumn>
                                <AgGridColumn headerName="業務合作/贈送" field="jc10" width={140}></AgGridColumn>
                                <AgGridColumn headerName="機器安裝" field="jc11" width={110}></AgGridColumn>
                                <AgGridColumn headerName="交貨/客戶拜訪" field="jc12" width={140}></AgGridColumn>
                                <AgGridColumn headerName="行政/作業/開會" field="jc13" width={150}></AgGridColumn>
                                <AgGridColumn headerName="支援" field="jc14" width={80}></AgGridColumn>
                                <AgGridColumn headerName="進度回覆" field="jc15" width={105}></AgGridColumn>
                            </AgGridReact>
                        </div>
                    </div>
                </Row>
            </Container>
        </React.Fragment>

    )
}
//轉至細節頁面
export const EditRenderer = (props) => {
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const hist = useHistory();
    const EditMethod = async () => {
        let id = cellValue;
        hist.push(`/maintStaffReportDetail/${id}`);
    }

    return (
        <span>
            <button
                style={{ height: 30, lineHeight: 0.5, fontWeight: 'bold' }}
                className="btn btn-link"
                onClick={() => {
                    EditMethod();
                }}
            >
                查看
            </button>
        </span>
    );
};
//匯出資料處理
const agGridDataHandle = (gridRef, organization) => {
    let columns_array = [];
    let rows_array = [];
    //表標頭headerName
    const _columnApi = gridRef.current.api.columnModel.columnApi.getAllDisplayedColumns();
    _columnApi.forEach((item, index) => {
        const excelTitle = {
            name: '',
        };
        if (index === 0) {
            excelTitle.name = 'No.';
        } else {
            excelTitle.name = item.userProvidedColDef.headerName;
        }
        columns_array.push(excelTitle);
    })
    //表資料
    let employeeName = null;
    gridRef.current.api.forEachNode((node, index) => {
        if (node.data.employee === "" || node.data.employee === undefined) {
            employeeName = null;
        } else {
            let item = organization.allMembers.find((e) => e.ID === node.data.employee);
            employeeName = item.Name;
        }

        let temp = [];
        temp[0] = index + 1;
        temp[1] = employeeName;
        temp[2] = node.data.totalRecords_Period;
        temp[3] = node.data.records_Period;
        temp[4] = node.data.monRecords_Period;
        temp[5] = node.data.jc1;
        temp[6] = node.data.jc2;
        temp[7] = node.data.jc3;
        temp[8] = node.data.jc4;
        temp[9] = node.data.jc5;
        temp[10] = node.data.jc6;
        temp[11] = node.data.jc7;
        temp[12] = node.data.jc8;
        temp[13] = node.data.jc9;
        temp[14] = node.data.jc10;
        temp[15] = node.data.jc11;
        temp[16] = node.data.jc12;
        temp[17] = node.data.jc13;
        temp[18] = node.data.jc14;
        temp[19] = node.data.jc15;
        rows_array.push(temp);
    });
    //匯出Excel
    exportExcelMethod(columns_array, rows_array, '工程師紀錄分析');
}