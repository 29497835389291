import { apiMachReportQuery, apiMachReportQueryDetail, apiEmployeeReportQuery, apiEmployeeReportQueryDetail } from '../../utils/api.js';


export const machReportQuery = (machReport) => async (dispatch, getState) => {
    let res = await apiMachReportQuery(machReport);
    let data = JSON.parse(res.request.response);
    dispatch(renMachReport(data));

}


export const machReportDetailQuery = (machReportDetail) => async (dispatch, getState) => {
    let res = await apiMachReportQueryDetail(machReportDetail);
    let data = JSON.parse(res.request.response);
    dispatch(renMachReportDetail(data));
}



const typeRenMachReport = 'typeRenMachReport';
const renMachReport = (arrMachReport) => {

    return {
        type: typeRenMachReport,
        machReport: arrMachReport
    }
}
const typeRenMachReportDetail = 'typeRenMachReportDetail';
const renMachReportDetail = (arrMachReportDetail) => {

    return {
        type: typeRenMachReportDetail,
        machReportDetail: arrMachReportDetail
    }
}
//---
export const staffReportQuery = (staffReport) => async (dispatch, getState) => {
    let res = await apiEmployeeReportQuery(staffReport);
    let data = JSON.parse(res.request.response);
    dispatch(renStaffReport(data));

}
export const staffReportDetailQuery = (staffReportDetail) => async (dispatch, getState) => {
    let res = await apiEmployeeReportQueryDetail(staffReportDetail);
    let data = JSON.parse(res.request.response);
    dispatch(renStaffReportDetail(data));
}
const typeRenStaffReport = 'typeRenStaffReport';
const renStaffReport = (arrStaffReport) => {

    return {
        type: typeRenStaffReport,
        staffReport: arrStaffReport
    }
}
const typeRenStaffReportDetail = 'typeRenStaffReportDetail';
const renStaffReportDetail = (arrStaffReportDetail) => {

    return {
        type: typeRenStaffReportDetail,
        staffReportDetail: arrStaffReportDetail
    }
}


const initState = {
    machReport: [],
    machReportDetail: [],
    staffReport: [],
    staffReportDetail:[]
}


export const reducer = (state = initState, action) => {
    switch (action.type) {
        case typeRenMachReport:
            return { ...state, machReport: [...action.machReport] };
        case typeRenMachReportDetail:
            return { ...state, machReportDetail: [...action.machReportDetail] };
        case typeRenStaffReport:
            return { ...state, staffReport: [...action.staffReport] };
        case typeRenStaffReportDetail:
            return { ...state, staffReportDetail: [...action.staffReportDetail] };
        default:
            return state;
    }
}