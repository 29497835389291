import { apiUserGroupQuery, apiUserGroupInsert, apiUserGroupUpdate, apiUserFunctionQuery, apiUserFunctionInsert, apiUserFunctionUpdate, apiAdminFuncQuery } from '../../utils/api.js';


export const userGroupQuery = () => async (dispatch, getState) => {
    let res = await apiUserGroupQuery();
    let data = JSON.parse(res.request.response);
    await dispatch(renUserGroup(data));
}

export const userGroupInsert = (newUserGroup) => async (dispatch, getState) => {
    let res = await apiUserGroupInsert(newUserGroup);
    if (res.status !== 200) {
        return;
    }
    await dispatch(userGroupQuery());
    return res;
}

export const userGroupUpdate = (newUserGroup) => async (dispatch, getState) => {
    let res = await apiUserGroupUpdate(newUserGroup);
    if (res.status !== 200) {
        return;
    }
    await dispatch(userGroupQuery());
    return res;
}

export const userFunctionQuery = () => async (dispatch, getState) => {
    let res = await apiUserFunctionQuery();
    let data = JSON.parse(res.request.response);

    await  dispatch(renUserFunction(data));

   
    let temp = [];
    const AllRoleid = [];
    /* eslint-disable  */
    data.map((item) => {
        if (!temp.includes(item.roleID)) {
            temp.push(item.roleID);
            AllRoleid.push(item);
        }
        
    });
    
    /* eslint-enable  */
    await dispatch(renAllRoleid(AllRoleid));

}

export const userFunctionInsert = (newUserFunction) => async (dispatch, getState) => {
    let res = await apiUserFunctionInsert(newUserFunction);
    if (res.status !== 200) {
        return;
    }
    await dispatch(userFunctionQuery());
}

export const userFunctionUpdate = (newUserFunction) => async (dispatch, getState) => {
    let res = await apiUserFunctionUpdate(newUserFunction);
    if (res.status !== 200) {
        console.log(res);
        return;
    }
    await  dispatch(userFunctionQuery());
}
const defultValue = {
    UserId:''
}
export const AdminFuncQuery = (newData) => async (dispatch, getState) => {
    defultValue.UserId = newData;
    let res = await apiAdminFuncQuery(defultValue);
    let data = JSON.parse(res.request.response);
    if (res.status !== 200) {
        console.log(res);
        return;
    }
    return data;
}


const typeRenUserGroup = 'typeRenUserGroup';
const renUserGroup = (arrUserGroup) => {

    return {
        type: typeRenUserGroup,
        userGroup: arrUserGroup
    }
}

const typeRenUserFunction = 'typeRenUserFunction';
const renUserFunction = (arrUserFunction) => {

    return {
        type: typeRenUserFunction,
        userFunction: arrUserFunction
    }
}

const typeRenAllRoleid = 'typeRenAllRoleid';
const renAllRoleid = (arrAllRoleid) => {

    return {
        type: typeRenAllRoleid,
        allRoleid: arrAllRoleid
    }
}

const initState = {
    userGroup: [],
    userFunction: [],
    allRoleid: [],
}


export const reducer = (state = initState, action) => {
    switch (action.type) {
        case typeRenUserGroup:
            return { ...state, userGroup: [...action.userGroup] };
        case typeRenUserFunction:
            return { ...state, userFunction: [...action.userFunction] };
        case typeRenAllRoleid:
            return { ...state, allRoleid: [...action.allRoleid] };
        default:
            return state;
    }
}