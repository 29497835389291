import { apiMaintRecordQuery, apiMaintRecordInsert, apiMaintRecordUpdate, apiMaintRecordQueryEQP, apiMaintRecordDelete, apiMaintRecordDeleteDetails } from '../../utils/api.js';


export const recordQuery = (newRecord) => async (dispatch, getState) => {
    let res = await apiMaintRecordQuery(newRecord);
    let data = JSON.parse(res.request.response);
     dispatch(renRecord(data));
}

export const recordInsert = (newRecord) => async (dispatch, getState) => {
    let res = await apiMaintRecordInsert(newRecord);
    if (res.status !== 200) {
        return;
    }
    dispatch(await recordQuery());
}

export const recordUpdate = (newRecord) => async (dispatch, getState) => {
    let res = await apiMaintRecordUpdate(newRecord);
    if (res.status !== 200) {
        return;
    }
    dispatch(await recordQuery());
}
export const recordQueryEQP = (newRecord) => async (dispatch, getState) => {
    let res = await apiMaintRecordQueryEQP(newRecord);
    let data = JSON.parse(res.request.response);
    dispatch(await renRecordEQP(data));
}
export const recordDelete = (newRecord) => async (dispatch, getState) => {
    let res = await apiMaintRecordDelete(newRecord);
    if (res.status !== 200) {
        return;
    }
    dispatch(await recordQuery());
}
export const recordDeleteDetails = (newRecord) => async (dispatch, getState) => {
    let res = await apiMaintRecordDeleteDetails(newRecord);
    if (res.status !== 200) {
        return;
    }
    dispatch( recordQuery());
}
 

const typeRenRecord = 'typeRenRecord';
const renRecord = (arrRecord) => {

    return {
        type: typeRenRecord,
        record: arrRecord
    }
}
const typeRenRecordEQP = 'typeRenRecordEQP';
const renRecordEQP = (arrRecordEQP) => {

    return {
        type: typeRenRecordEQP,
        recordEQP: arrRecordEQP
    }
}




const initState = {
    record: [],
    recordEQP: [],
}


export const reducer = (state = initState, action) => {
    switch (action.type) {
        case typeRenRecord:
            return { ...state, record: [...action.record] };
        case typeRenRecordEQP:
            return { ...state, recordEQP: [...action.recordEQP] };
        default:
            return state;
    }
}