import { customerQuery } from '../../action/customer/customerActions.js';
import React, { useState, useEffect } from 'react';
import { Card, Row,  Tab, Col, Button, Container, FormControl, InputGroup, Collapse } from 'react-bootstrap';
import NavBar from '../Navbar/NavBar.jsx';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch, faFileExport, faChevronDown, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import '../Customer/customer.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-dropdown-tree-select/dist/styles.css'

import { useRWD, devicesIsMobile } from '../../useRWD.js';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { store } from 'react-notifications-component';


const queryParam = {
    keyword: null,
    name: null,
    assistingBy: null,
    custContacts: [],
    taxId: null,
    telephone: null,
    officialWebsite: null,
    address: null,
    note: null,
    customer: null
};

const completeMessage = {
    title: "搜尋",
    message: "搜尋完成",
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 300,
        onScreen: true
    }
};

export const Customer = (props) => {

    const hist = useHistory();
    const dispatch = useDispatch();
    const rowStyle = {
        cursor: "pointer",
    };
    const [state, setState] = useState({ gridApi: null });
    const { customers } = useSelector(x => x);
    let filteredData = customers;

    const handleLoadingUI = (isShow) => { if (isShow === true) { dispatch({ type: 'SHOW_LOADING' }); } else { dispatch({ type: 'HIDE_LOADING' }); } }//show laod UI
    useEffect(() => {
        // Create an async function within our effect:
        async function runEffect() {
            handleLoadingUI(true);
            await dispatch(customerQuery());
            handleLoadingUI(false);
        }
        // Immediately call this function to run the effect:
        runEffect();

    }, []) //  eslint-disable-line react-hooks/exhaustive-deps

    if (queryParam.keyword != null) {
        filteredData = filteredData.filter(x => {
            if (x.name == null) {
                return false;
            }
            return x.name.includes(queryParam.keyword);
        });
        store.addNotification(completeMessage);
    }
    const CustAdd = useSelector(x => x.ui.CustAdd);
    const [data, setData] = useState(queryParam);

    //依據視窗大小調整 高度
    let device = useRWD();
    device = (device) + 'px';
    let isMoble = devicesIsMobile();//判斷手機
    return (
        <React.Fragment>
            <NavBar />
            <Container fluid>
                <Row className="mx-0">
                    <InputGroup className="mb-3">
                        {!CustAdd.disabled &&

                            <Link to="/customerbox/0">
                                <Button variant="primary" style={{ background: '#00A2FF' }}>
                                    {!isMoble && <FontAwesomeIcon className='rounded' icon={faPlus} />}
                                    新增
                                </Button>
                            </Link>

                        }
                        <FormControl
                            type="search"
                            placeholder="輸入關鍵字 : "
                            aria-label="Search"
                            size="1g"
                            className="keyword-bar"
                            defaultValue={queryParam.keyword}
                            onChange={(e) => setData({ ...queryParam, keyword: e.target.value })}
                        />
                        <Button variant="primary" onClick={() => { console.log(data); dispatch(customerQuery(data)); }}>
                            {!isMoble && <FontAwesomeIcon className='rounded' icon={faSearch} />}
                            搜尋
                        </Button>
                    </InputGroup>
                </Row>
                <Row className="mx-auto">
                    <Card className="mb-3" style={{ width: '100%', minWidth: '312px', borderRadius: '10px' }}>
                        <Card.Body>
                            <Tab.Container defaultActiveKey="first">
                                <Row className="align-items-center">

                                    
                                    <Col className="d-flex justify-content-end">
                                        <Button variant="outline-primary" onClickCapture={() => { state.gridApi.exportDataAsCsv(); }}>
                                            <FontAwesomeIcon className='rounded' icon={faFileExport} />
                                            Export
                                        </Button>
                                    </Col>
                                </Row>
                                
                                <Row>
                                    {isMoble &&
                                        <MobileSearchUI />
                                    }
                                    {!isMoble &&
                                        <div className="ag-theme-alpine" style={{ height: device, width: '100%' }}>
                                            <AgGridReact
                                                rowData={filteredData}
                                                rowStyle={rowStyle}

                                                defaultColDef={{
                                                    sortable: true,
                                                    resizable: true,
                                                    wrapText: true,
                                                    autoHeight: true,
                                                    filter: true
                                                }}
                                                onGridReady={(params) => {
                                                    setState({
                                                        ...state,
                                                        gridApi: params.api
                                                    });
                                                }}
                                                onRowClicked={(e) => {
                                                    let id = e.data.customerId;
                                                    hist.push(`/customertab/${id}`);
                                                }}

                                                paginationAutoPageSize={true}
                                                pagination={true}
                                            >
                                                <AgGridColumn headerName="客戶編號" field="customerId" width={130} pinned="left"></AgGridColumn>
                                                <AgGridColumn headerName="客戶名稱" field="name" width={250} pinned="left"></AgGridColumn>
                                                <AgGridColumn headerName="聯絡人名稱" field="contactName" width={130}></AgGridColumn>
                                                <AgGridColumn headerName="職稱" field="contactTitle" width={130} ></AgGridColumn>
                                                <AgGridColumn headerName="連絡電話" field="contactCellphone" width={130} ></AgGridColumn>
                                                <AgGridColumn headerName="E-mail" field="contactEmail" width={250} ></AgGridColumn>
                                                <AgGridColumn headerName="統編" field="taxId" width={130} ></AgGridColumn>
                                                <AgGridColumn headerName="室內電話" field="telephone" width={130} ></AgGridColumn>
                                                <AgGridColumn headerName="官方網站" field="officialWebsite" width={250} ></AgGridColumn>
                                                <AgGridColumn headerName="地址" field="address" width={250}></AgGridColumn>
                                                <AgGridColumn headerName="備註" field="note" width={250}></AgGridColumn>
                                            </AgGridReact>
                                        </div>
                                    }
                                </Row>
                            </Tab.Container>
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        </React.Fragment>
    );

}
export const MobileSearchUI = (props) => {
    const { customers } = useSelector(x => x);
    let filteredData = customers;
    const hist = useHistory();
    const EditMethod = (id) => {
        hist.push(`/customertab/${id}`);
    }

    const [expandedId, setExpandedId] = useState(null);

    const handleExpandClick = (id) => {
        setExpandedId(expandedId === id ? null : id);
    };

    return (
        <div style={{ width: '100%' }}>
            {
                filteredData.map((data, index) => {
                    return (

                        <Card key={index} style={{ borderRadius: '20px', margin: '5px' }}>
                            <Card.Body style={{ padding: '8px' }}>

                                <Row>
                                    <Card.Title style={{ fontSize: '14px' }}>No.{data.customerId} &nbsp; {data.name}</Card.Title>
                                    <Col>
                                        <Card.Text style={{ fontSize: '12px' }}>
                                            聯絡人名稱:{data.contactName}&nbsp;
                                            職稱:{data.contactTitle}<br />
                                            連絡電話:{data.contactCellphone}<br />
                                            地址:{data.address}<br />
                                            <Button style={{ display: 'contents', lineHeight: '0rm' }}
                                                variant="link"
                                                onClick={() => handleExpandClick(data.customerId)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={expandedId === data.customerId}
                                            >
                                                <FontAwesomeIcon icon={faChevronDown} />
                                            </Button>
                                        </Card.Text>
                                    </Col>
                                    <Col xs={2}>
                                        <Button variant="link" style={{ fontSize: '20px' }} onClick={() => {
                                            EditMethod(data.customerId);
                                        }}>
                                            <FontAwesomeIcon icon={faSignOutAlt} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>

                            <Collapse in={expandedId === data.customerId}  >
                                <Card.Text style={{ fontSize: '12px', padding: '5px' }}>
                                    統編:{data.taxId}<br />
                                    室內電話:{data.telephone}<br />
                                    E-mail:{data.contactEmail}<br />
                                    官方網站:{data.officialWebsite}<br />
                                    備註:{data.note}
                                </Card.Text>
                            </Collapse>

                        </Card>
                    )
                })
            }

        </div>
    );
}