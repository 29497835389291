import React, { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Container, Row, Tab, Tabs } from 'react-bootstrap';
import NavBar from '../Navbar/NavBar.jsx';
import '../Maintain/maintainTab.css';
import { MachBasic } from '../Maintain/BasicInfo/Basic/MachBasic.jsx';
import { MachSupplier } from '../Maintain/BasicInfo/Supplier/MachSupplier.jsx';
import { MachHandle } from '../Maintain/BasicInfo/Handle/MachHandle.jsx';

import { useRWD } from '../../useRWD.js';


const machTabs = ['machBasic', 'machSupplier','machHandle'];


export const MachBasicTab = (props) => {

    const { tabs, id } = useParams();
    const [key, setKey] = useState('');

    useEffect(() => {
        const Data = machTabs.find(e => e === tabs);// eslint-disable-line eqeqeq
        setKey(Data);
    }, []) //  eslint-disable-line react-hooks/exhaustive-deps
   

    //依據視窗大小調整 高度
    let device = useRWD();
    device = (device) + 'px';

    return (
        <React.Fragment>
            <NavBar />
            <Container fluid>
                <Row className="mx-auto" >
                    <Tabs className="nav nav-pills" style={{ height: device }}
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        variant="pills"
                        justify>
                        <Tab eventKey={machTabs[1]} title="供應商"  >
                            <MachSupplier/>
                        </Tab>
                        <Tab eventKey={machTabs[0]} title="機台基本資料" >
                            <MachBasic />
                        </Tab>
                        <Tab eventKey={machTabs[2]} title="手握把資料">
                            <MachHandle id={id} />
                        </Tab>
                    </Tabs>

                </Row>
            </Container>
        </React.Fragment>



    );

}