import React, { useState, useEffect } from 'react';
import { Card, Form, Row, Button, Modal, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';


export const CustContactsEditBox = (props) => {
    const { data, show, onHide, onSave = () => { } } = props;

  
    const temp = data;
    const emptyCust = {
        id: null,
        name: null,
        title: null,
        cellphone: null,
        email: null,
        maincontact: false
    }
    const [demo, setdemo] = useState(emptyCust);

    useEffect(() => {
        if (data !== undefined) {
            setdemo(data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

/*  const dispatch = useDispatch();*/
    //const updateData = async (info) => {
    //    await dispatch(custcontactsUpdate(info));
    //    await dispatch(customerQuery());
    //    onHide();
    //}

    return (
        <React.Fragment>
            <Modal show={show} fullscreen={false} centered onHide={onHide}>
                <Modal.Header className="bg-primary text-light" closeButton>
                    <Modal.Title>編輯客戶聯絡人資料</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className="" style={{ width: '100%', minWidth: '312px' }}>
                        <Card.Body>
                            <Form autoComplete="false">
                                <Row>
                                    <Form.Group as={Row} className="mb-4">
                                        <Form.Label column sm="3">
                                            <strong>聯絡人名稱</strong>
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control type="text" placeholder=""
                                                defaultValue={temp == null ? null : temp.name}
                                                onChangeCapture={(e) => {
                                                    setdemo({ ...demo, name: e.target.value });
                                                    temp.name = e.target.value;
                                                }}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-4">
                                        <Form.Label column sm="3">
                                            <strong>職稱</strong>
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control type="text" placeholder=""
                                                defaultValue={temp == null ? null : temp.title}
                                                onChangeCapture={(e) => {
                                                    setdemo({ ...demo, title: e.target.value });
                                                    temp.title = e.target.value;
                                                }}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-4">
                                        <Form.Label column sm="3">
                                            <strong>連絡電話</strong>
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control type="text" placeholder=""
                                                defaultValue={temp == null ? null : temp.cellphone}
                                                onChangeCapture={(e) => {
                                                    setdemo({ ...demo, cellphone: e.target.value });
                                                    temp.cellphone = e.target.value;
                                                }}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group as={Row} className="mb-4">
                                        <Form.Label column sm="3">
                                            <strong>E-mail</strong>
                                        </Form.Label>
                                        <Col sm="9">
                                            <Form.Control type="text" placeholder=""
                                                defaultValue={temp == null ? null : temp.email}
                                                onChangeCapture={(e) => {
                                                    setdemo({ ...demo, email: e.target.value });
                                                    temp.email = e.target.value;
                                                }}
                                            />
                                        </Col>
                                    </Form.Group>
                                    <Form.Group className="mb-4 ml-4">
                                        <Form.Check type="switch" label="主要聯絡人" id="custom-switch"
                                            defaultChecked={temp == null ? false : temp.mainContact}
                                            onChangeCapture={(e) => {
                                                setdemo({ ...demo, maincontact: e.target.checked });
                                                temp.maincontact = e.target.checked;
                                            }}
                                        />
                                    </Form.Group>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary"
                        onClickCapture={() => {
                            if (temp.name === null || temp.name === "" || temp.name === undefined) {
                                window.alert("請輸入聯絡人名稱");
                            } else {

                                /*updateData(temp);*/
                                
                                onSave(demo);
                            }
                            onHide();
                        }}
                    >
                        <FontAwesomeIcon className='rounded me-2' icon={faSave} />
                        儲存
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment >
    );
}


