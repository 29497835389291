import { userGroupQuery, userFunctionInsert, userFunctionUpdate } from '../../action/permission/adminActions.js';
import React, { useState, useEffect } from 'react';
import { Card, Row, Tab, Col, Button, Container, Form, Table, Stack, ListGroup } from 'react-bootstrap';
import NavBar from '../Navbar/NavBar.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faPen } from '@fortawesome/free-solid-svg-icons';
import "react-datepicker/dist/react-datepicker.css";
import 'react-dropdown-tree-select/dist/styles.css'
import '../Permission/Admin.css';
import { useSelector, useDispatch } from 'react-redux';

import { functiondata } from '../../utils/hooks';
import { store } from 'react-notifications-component';


const completeMessage = {
    title: "完成",
    message: "新增/更新完成",
    type: "success",
    insert: "top",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 1000,
        onScreen: true
    }
};

export const Administration = (props) => {

    const dispatch = useDispatch();

    //UI 顯示設定
    const [isSaveBtnHinden, setSaveBtnHinden] = useState('invisible');
    const [isListBoxHinden, setListBoxHinden] = useState('visible');
    const [isInputBoxHinden, setInputBoxHinden] = useState('invisible');
    const [isAddBtnHinden, setAddBtnHinden] = useState('visible');
    const [isEditBtnHinden, setEditBtnHinden] = useState('visible');
    const [isEdit, setEdit] = useState(false);

    const { admin } = useSelector(x => x.config);
    const { administration, organization } = useSelector(x => x);
    const TKdeptInfo = [];
    organization.departments.forEach((item) => {
        if (item.LevelCode.includes('259')) {
            TKdeptInfo.push(item);
        }
    });
    const emptyAdmin = {
        id: null,
        roleID: null,
        userID: null,
        name: null,
        Function: [],
    };

    const [AdminData, setAdminData] = useState(emptyAdmin);
    const [AdminName, setAdminName] = useState('');

    useEffect(() => {
        var result = functiondata(admin);
        let newData = {
            ...AdminData,
            name: AdminName,
            Function: [...AdminData.Function, ...result]
        };
        setAdminData(newData);
        console.log(AdminData);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    //權限Func
    const FuncInsertUpdate = async (data) => {
        if (isEdit === true) {
            await dispatch(userFunctionUpdate(data.Function));
        } else {
            let roleID_ = 0;
            //欄位名稱搜尋泰可 Roleid
            TKdeptInfo.forEach((item) => {
                if (item.Name.includes(AdminName)) {
                    roleID_ = item.ID;
                }
            })
            if (roleID_ === 0) {
                if (administration.userFunction.length > 0) {
                    roleID_ = (administration.userFunction[(administration.userFunction.length - 1)].roleID + 1);
                }
            }

            let result = [];
            data.Function.forEach((item) => {
                let newFunc = {
                    ...item,
                    roleID: roleID_,
                    remark: AdminName
                }
                result.push(newFunc);
            })
            let newData = {
                ...AdminData,
                name: AdminName,
                roleID: roleID_,
                Function: result
            };
            setAdminData(newData);
            await dispatch(userFunctionInsert(newData.Function));
        }
        handleFunctionGrid(AdminData.roleID, AdminData.name);
        //新增/更新提示訊息
        store.addNotification(completeMessage);
    }

    //群組 Table Function
    const handleFunctionGrid = (e, name_) => {

        let checkBox = [];
        let result = [];
        administration.userFunction.forEach((item) => {
            if (item.roleID === parseInt(e)) {
                checkBox.push(item.enable);
                result.push(item);
            }
        })
        let newData = {
            ...AdminData,
            roleID: e,
            name: name_,
            Function: result
        };
        setAdminData(newData);


        isItemSelected.length = 0;
        setSelected(checkBox);



    }
    const [userName, setuserName] = useState([]);// eslint-disable-line no-unused-vars

    const handleUserList = (e) => {
        let getuserID_arry = [];
        administration.userGroup.forEach((item) => {
            if (item.roleID.includes(e)) {
                getuserID_arry.push(item.userID);
            }
        })
        userName.length = 0;
        getuserID_arry.forEach((index) => {
            organization.members.forEach((item) => {
                if (item.ID === index) {
                    userName.push(item.Name);
                }
            })
        })
        console.log(userName);
    }

    //群組 Table CheckBox
    const [isItemSelected, setSelected] = useState(() => {
        let array = [];
        if (AdminData.Function.length > 0) {
            AdminData.Function.forEach((en) => {
                array.push(en.enable);
            })
            return array;
        } else {
            admin.forEach((en) => {
                array.push(!en.flag);
            })
            return array;
        }
    });
    //群組
    const handleCheckboxChange = (e) => {
        let selected = e.target.checked;
        let index = (e.target.id - 1);
        isItemSelected[index] = selected;
        AdminData.Function[index].enable = selected;

        let array = [];
        isItemSelected.forEach((en) => {
            array.push(en);
        })
        isItemSelected.length = 0;
        setSelected(array);
    };

    return (
        <React.Fragment>
            <NavBar />
            <Container fluid>
                <Row className="mx-auto">
                    <Card className="mb-3" style={{ width: '100%', minWidth: '312px' }}>
                        <Card.Body>
                            <Tab.Container defaultActiveKey="first">
                                <Row className="mb-3 row-end"  >
                                    <Form.Group style={{ margin: '0', padding: '0' }} as={Row} className="mb-1">
                                        <Col sm="3" >
                                            <Form.Label>群組 </Form.Label>

                                            <div className={isListBoxHinden} style={{ display: 'flex' }}>
                                                <select className="form-select"
                                                    onChange={(e) => {
                                                        let roleID_ = e.target.value;
                                                        let name_ = '';
                                                        administration.userFunction.forEach((item) => {
                                                            if (item.roleID === parseInt(e.target.value)) {
                                                                name_ = item.remark;
                                                                return
                                                            }
                                                        })
                                                        setAdminName(name_);
                                                        handleFunctionGrid(roleID_, name_);
                                                        handleUserList(roleID_);
                                                    }}>
                                                    <option>請選擇</option>
                                                    {   /* eslint-disable */
                                                        administration.allRoleid.map((item, index) => {
                                                            return (<option key={index} value={item.roleID} >{item.remark} </option>);
                                                        })
                                                        /* eslint-enable */
                                                    }
                                                </select>
                                                <div className={isInputBoxHinden} style={{ display: 'flex' }}>
                                                    <Form.Control type="text" onChange={(e) => {
                                                        setAdminName(e.target.value);
                                                        setAdminData({
                                                            ...AdminData,
                                                            name: e.target.value
                                                        });
                                                    }} />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col sm="1" >
                                            <Stack>
                                                <div className={isAddBtnHinden} >
                                                    <Button variant="primary"
                                                        onClick={() => {
                                                            dispatch(userGroupQuery());
                                                            setSaveBtnHinden('visible');
                                                            setInputBoxHinden('visible');
                                                            setListBoxHinden('invisible');
                                                            setAddBtnHinden('invisible');
                                                            setEditBtnHinden('invisible');
                                                            setEdit(false);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon className='rounded' icon={faPlus} />
                                                    </Button>
                                                </div>
                                                <div className={isEditBtnHinden}>
                                                    <Button variant="link" onClick={() => {
                                                        setSaveBtnHinden('visible');
                                                        setAddBtnHinden('invisible');
                                                        setEditBtnHinden('invisible');
                                                        setEdit(true);
                                                    }}>
                                                        <FontAwesomeIcon className='rounded' size="1x" icon={faPen} />
                                                    </Button>
                                                </div>
                                            </Stack>
                                        </Col>
                                        <Col sm="2">
                                            <div className={isSaveBtnHinden} >
                                                <Button variant="primary"
                                                    onClick={() => {
                                                        FuncInsertUpdate(AdminData);
                                                        setSaveBtnHinden('invisible');
                                                        setInputBoxHinden('invisible');
                                                        setListBoxHinden('visible');
                                                        setAddBtnHinden('visible');
                                                        setEditBtnHinden('visible');
                                                    }}
                                                >
                                                    存檔
                                                </Button>
                                                <Button variant="danger"
                                                    onClick={() => {
                                                        setSaveBtnHinden('invisible');
                                                        setInputBoxHinden('invisible');
                                                        setAddBtnHinden('visible');
                                                        setEditBtnHinden('visible');
                                                        setListBoxHinden('visible');
                                                    }}
                                                >
                                                    取消
                                                </Button>
                                            </div>
                                        </Col>
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Col sm="3">
                                        <div className="ag-theme-alpine" style={{ fontSize: 18 }}>
                                            <Table striped bordered hover size="sm">
                                                <thead>
                                                    <tr>
                                                        <th>名稱</th>
                                                        <th>Check</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {admin.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.text}</td>
                                                            <td>
                                                                <Form.Check
                                                                    checked={isItemSelected[index]}
                                                                    key={item.value}
                                                                    id={item.value}
                                                                    value={item.comment}
                                                                    onChange={(e) => {
                                                                        handleCheckboxChange(e);
                                                                    }}
                                                                /></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                    <Col  sm={{ span: 2, offset: 1 }}>
                                       
                                        <ListGroup>
                                            <ListGroup.Item active>群組名單</ListGroup.Item>
                                            {
                                                userName.map((item) => (

                                                    <ListGroup.Item>{item}</ListGroup.Item>


                                                ))
                                            }


                                        </ListGroup>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        </React.Fragment>
    );

}
