import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Form, Row, Button, Col, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { store } from 'react-notifications-component';
import { useParams, useHistory } from "react-router-dom";
import { productSettingsQuery, productSettingsUpdate } from '../../../action/material/productAction.js';
import { phtoUpload, phtoDownload } from '../../../action/material/phtoUploadAction.js';
import { qrCodeQuery } from '../../../action/material/qrCodeAction.js';
import { CenteredModalAlert } from '../../../components/Material/CenteredModal';


const defaultMessage = {
    title: "上傳中",
    message: "資料上傳中，請稍後...",
    type: "warning",
    insert: "top",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 2000,
        onScreen: true
    }
};

const completeMessage = {
    title: "完成",
    message: "更新完成",
    type: "success",
    insert: "top",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 1000,
        onScreen: true
    }
};
const phtoMessage = {
    title: "圖片",
    message: "圖片尺寸有問題",
    type: "danger",
    insert: "top",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 5000,
        onScreen: true
    }
};

const emptyProduct = {
    id: null,
    code: null,
    name: null,
    addressCategory: null,
    verifyName: null,
    failureLimit: null,
    productImagePath: null,
    healthImagePath: null,
    labelCode: null
};

const empty = {
    category: null,
    path: null
}


export const ProductSettingsEdit = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { productSettings, qrcode } = useSelector(x => x);
    const { code } = useParams();

    const [data, setData] = useState(emptyProduct);
    const [disable, setDisable] = useState(false);


    useEffect(() => {
        const ProductData = productSettings.productSettings.find(e => e.code == code);// eslint-disable-line eqeqeq
        setData(ProductData);
        dispatch(qrCodeQuery());
        const _addressCategory = qrcode.qrCode.find(e => e.productID == ProductData.id);// eslint-disable-line eqeqeq
        if (_addressCategory !== undefined) { setDisable(true); }
       

    }, [disable]) //  eslint-disable-line react-hooks/exhaustive-deps
    const isEmpty = (value) => {
        return (value == null || (typeof value === "string" && value.trim().length === 0));
    }
    const insertOrUpdateData = async (data) => {
        //判斷 網址路徑名稱=產品名稱 不能與其他產品名稱重複
        /* eslint-disable array-callback-return*/
        const _addressCategory = productSettings.productSettings.find((e) => {
            if (!isEmpty(e.addressCategory)) {
                return e.addressCategory.toUpperCase().includes(data.addressCategory.toUpperCase());
            }
        });
        /* eslint-enable array-callback-return*/
        if (_addressCategory !== undefined && _addressCategory.code !== data.code) {
            setModalMesg('網址路徑名稱 不能重複!');
            setModalShow(true);
        } else {
            let msgId = store.addNotification(defaultMessage);
            await dispatch(productSettingsUpdate(data));
            await dispatch(productSettingsQuery());
            store.removeNotification(msgId);
            store.addNotification(completeMessage);

            history.goBack();
        }
    }

    //上傳照片
    const handleupload = async (files, whichPhoto) => {

        let flag = true;
        let path = '';
        //判斷 照片大小和長寬
        const isLt1000KB = files.size / 1024 < 1000;
        if (whichPhoto === 'Product') {
            flag = await checkImageWH(files, 1200, 900, whichPhoto);//W1200 H900(4:3)
            path = data.productImagePath.split('/');
        } else {
            flag = await checkImageWH(files, 800, 0, whichPhoto);//W800 H不限
            data.healthImagePath.split('/');
        }
        if (!isLt1000KB) {
            setModalMesg('圖片必須小於1000KB!');
            setModalShow(true);
        } else if (!flag) {
            store.addNotification(phtoMessage);
            //清空 file input
            if (whichPhoto === 'Product') {
                document.getElementById("uploadProductImage").value = "";
            } else {
                document.getElementById("uploadHealthImage").value = "";
            }
            
        } else {
            let formData = new FormData();
            formData.append('title', files);
            dispatch(phtoUpload(formData));
            //存檔時 圖片路徑
            let newpath = '';
            /* eslint-disable array-callback-return*/
            path.map((item, index) => {
                if (index !== (path.length - 1)) {
                    newpath += item + '/';
                }
            })
            /* eslint-enable array-callback-return*/
            if (whichPhoto === 'Product') {
                setData({ ...data, productImagePath: newpath + files.name });
            } else {
                
                setData({ ...data, healthImagePath: newpath + files.name });
            }

        }

    }

    const close = () => {
        history.goBack();
    }
    //瀏覽照片
    const [background, setbackground] = useState('');
    const showPhoto = async () => {

        let flieList = await dispatch(phtoDownload(empty));
        setbackground(flieList.request.responseURL);
    }
    //modal
    const [modalShow, setModalShow] = useState(false);
    const [modalMesg, setModalMesg] = useState('');

    return (
        <React.Fragment>
            <Container>
                <Row>
                    <Col md={6} className="mx-auto">
                        <Card className="mb-1" style={{ width: '100%', minWidth: '320px' }}>
                            <Card.Body>
                                <Form autoComplete="false">

                                    <Row style={{ margin: '0 0 20px 0' }} >
                                        <Button onClick={close}>
                                            <FontAwesomeIcon className='rounded me-2' icon={faTimes} />
                                            關閉
                                        </Button>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Row} className="mb-4">
                                            <Form.Label column sm="4">
                                                <strong>產品代號</strong>
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Label >
                                                    <strong>{data.code}</strong>
                                                </Form.Label>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-4">
                                            <Form.Label column sm="4">
                                                <strong>產品名稱</strong>
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Label >
                                                    <strong>{data.name}</strong>
                                                </Form.Label>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-4">
                                            <Form.Label column sm="4">
                                                <strong>網址路徑名稱</strong>
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control type="text" placeholder="" disabled={disable}
                                                    defaultValue={data.addressCategory}
                                                    onChangeCapture={(e) => {
                                                        setData({ ...data, addressCategory: e.target.value });

                                                    }}
                                                />
                                                <Form.Text className="text-muted">
                                                    設定此名稱，QRCode頁面【產品名稱】 將會顯示
                                                </Form.Text>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-4">
                                            <Form.Label column sm="4">
                                                <strong>驗證顯示名稱</strong>
                                            </Form.Label>
                                            <Col sm="8">
                                                <Form.Control type="text" placeholder=""
                                                    defaultValue={data.verifyName}
                                                    onChangeCapture={(e) => {
                                                        setData({ ...data, verifyName: e.target.value });

                                                    }}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-4">
                                            <Form.Label column sm="5">
                                                <strong>驗證失敗次數上限</strong>
                                            </Form.Label>
                                            <Col sm="7">
                                                <Form.Control type="text" placeholder=""
                                                    defaultValue={data.failureLimit}
                                                    onChangeCapture={(e) => {
                                                        setData({ ...data, failureLimit: e.target.value });

                                                    }}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-4">
                                            <Col sm="4">
                                                <Form.Label >
                                                    <strong>產品圖片路徑</strong>
                                                    
                                                </Form.Label>
                                                <Button
                                                    variant="secondary"
                                                    onClick={() => {
                                                        empty.category = 'Product';
                                                        empty.path = data.productImagePath;
                                                        showPhoto();

                                                    }}
                                                >
                                                    瀏覽
                                                </Button>
                                            </Col>

                                            <Col sm="8">
                                                <Form.Label>{data.productImagePath}</Form.Label>
                                                <Form.Control type="file"
                                                    id="uploadProductImage"
                                                    onChangeCapture={(e) => {
                                                        handleupload(e.target.files[0], 'Product');

                                                    }}
                                                />
                                                <span class="text-danger">*圖片限制:W1200／H900(4:3)</span>
                                            </Col>

                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-4">

                                            <Col sm="4">
                                                <Form.Label >
                                                    <strong>衛教圖片路徑</strong>
                                                </Form.Label>
                                                <Button
                                                    variant="secondary"
                                                    onClick={() => {
                                                        empty.category = 'Health';
                                                        empty.path = data.healthImagePath;
                                                        showPhoto();

                                                    }}
                                                >
                                                    瀏覽
                                                </Button>
                                            </Col>

                                            <Col sm="8">
                                                <Form.Label>{data.healthImagePath}</Form.Label>
                                                <Form.Control type="file"
                                                    id="uploadHealthImage"
                                                    onChangeCapture={(e) => {
                                                        handleupload(e.target.files[0], 'Health');

                                                    }}
                                                />
                                                <span class="text-danger">*圖片限制:W800／H不限</span>
                                            </Col>
                                            <Col sm="2">

                                            </Col>
                                        </Form.Group>
                                        <Form.Group as={Row} className="mb-4">
                                            <Form.Label column sm="3">
                                                <strong>標籤前綴</strong>
                                            </Form.Label>
                                            <Col sm="9">
                                                <Form.Control type="text" placeholder=""
                                                    defaultValue={data.labelCode}
                                                    onChangeCapture={(e) => {
                                                        setData({ ...data, labelCode: e.target.value });

                                                    }}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group>
                                            <Row className="mx-auto">
                                                <Button
                                                    variant="primary"
                                                    onClick={() => {
                                                        insertOrUpdateData(data);

                                                    }}
                                                >
                                                    <FontAwesomeIcon className='rounded me-2' icon={faSave} />
                                                    儲存
                                                </Button>

                                            </Row>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group>
                                            <Row className="mx-auto">
                                                <img src={background} alt="Background" width={200} height={200} />
                                            </Row>
                                        </Form.Group>
                                    </Row>

                                </Form>

                                <CenteredModalAlert
                                    title={'警告'}
                                    message={modalMesg}
                                    btnText={'確定'}
                                    show={modalShow}
                                    onHide={() => setModalShow(false)}
                                />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </React.Fragment>

    )
}
//圖片上傳限制寬高度
function checkImageWH(file, width, height, whichPhoto) {

    return new Promise(function (resolve, reject) {

        const filereader = new FileReader();

        filereader.onload = (e) => {

            const src = e.target.result;

            const image = new Image();

            image.onload = function () {
                if (whichPhoto === 'Product') {
                    if (this.width !== width && this.height !== height) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                } else {
                    if (this.width !== width) {
                        resolve(false);
                    } else {
                        resolve(true);
                    }
                }
            };

            image.onerror = reject;
            image.src = src;
        };

        filereader.readAsDataURL(file);

    });

}

