import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { reducers } from './configureReducers.js';
import loggerMiddleware from 'redux-logger';
/*import { save, load } from "redux-localstorage-simple"*/
import { persistStore, persistReducer } from 'redux-persist'

import expireReduxState from 'redux-persist-expire-state';
import storage from 'redux-persist/lib/storage'
import { loadingMiddleware, expireMiddleware } from './App.js';

export default function configureStore(history, initialState) {

    const middleware = [
        thunk,
        routerMiddleware(history),
        loggerMiddleware
    ];

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history)
    });

    const enhancers = [

    ];
    
    const minute = 60;
    const hour = minute * 60;
    /*const day = hour * 24;*/

    const resetReducer = 'currentUser'

    const persistConfig = {
        key: 'root',
        storage,
        whitelist: [resetReducer],
        transforms: [
            expireReduxState(resetReducer, {
                expireAfter: hour,
                expireKey: 'persistedAt',
                expireState: {}
            })
        ]
    }

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


    const persistedReducer = persistReducer(persistConfig, rootReducer)
    const store = createStore(persistedReducer, initialState, composeEnhancers(applyMiddleware(...middleware, loadingMiddleware, expireMiddleware), ...enhancers));
    persistStore(store)

    //const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleware), ...enhancers));
   
    return store;
}
