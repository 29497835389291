import React from 'react';
import { Form } from 'react-bootstrap';

export const ConfigSelection = (props) => {
    const { title = "", settings = [], defaultValue = 1, onChange = () => { }, disabled = false } = props;
    return (
        <Form.Group className="mb-1">
            <Form.Label>{title}</Form.Label>
            <select className="form-select" style={{ padding: '0.1rem 2.25rem 0.1rem 0.75rem' }} onChange={onChange} value={defaultValue} disabled={disabled } >
                {
                    settings.map(item => {
                        return (<option key={item.configId} value={item.value} >{item.text}{item.comment}</option>)
                    })
                }
            </select>
        </Form.Group >
    );
}