import React from 'react';
import { Card, Row, Col, Button, Container, Form, Dropdown } from 'react-bootstrap';
import NavBar from '../Navbar/NavBar.jsx';
import 'react-dropdown-tree-select/dist/styles.css'
import { useSelector, useDispatch } from 'react-redux';
import { store } from 'react-notifications-component';
import { configInsert } from '../../action/config/configActions.js';


const completeMessage = {
    title: "新增",
    message: "完成",
    type: "success",
    insert: "top",
    container: "center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 3000,
        onScreen: true
    }
};
const defaultInster = {
    type: null,
    value: null,
    name: '',
    text: '',
    comment: '',
    order: null
}

export const Config = (props) => {
    const dispatch = useDispatch();

    const { degree, jobclass, level, progress, status, preSaleEquip } = useSelector(x => x.config);
    const { eqpStatus, eqpArea, maintJobClass, maintStatus, maintContractJobClass, maintContractPayment } = useSelector(x => x.config);

    const CaseCount = [{ name: '預定銷售設備', congfig: preSaleEquip, input: '' }, { name: '案例狀態', congfig: status, input: '' }, { name: '案例等級', congfig: level, input: '' },
    { name: '把握度', congfig: degree, input: '' }, { name: '銷售進度', congfig: progress, input: '' }, { name: '工作類別', congfig: jobclass, input: '' }];
    //設備名稱 關聯 機型加上有其他選項，因此 不額外給 介面設定
    const MaintainCount = [{ name: '狀態', congfig: eqpStatus, input: '' }, { name: '區域', congfig: eqpArea, input: '' }, { name: '工作內容', congfig: maintJobClass, input: '' }, { name: '維修狀態', congfig: maintStatus, input: '' }, { name: '合約類別', congfig: maintContractJobClass, input: '' }, { name: '合約付款方式', congfig: maintContractPayment, input: '' }];

    const InsterConfigBtn = async (data, index) => {
        let item = data[index].congfig[data[index].congfig.length - 1];
        defaultInster.type = item.type;
        defaultInster.value = item.value + 1;
        defaultInster.name = item.name;
        defaultInster.text = data[index].input;
        defaultInster.order = item.order + 1;

        dispatch(await configInsert(defaultInster));
    }
    
    return (
        <React.Fragment>
           
            <NavBar />
            <Container fluid>
                <Row className="mx-auto">
                    <Card.Header>設定檔</Card.Header>
                    <Card className="mb-3" style={{ width: '100%', minWidth: '312px' }}>
                        <Card.Body>
                            <Card.Header>案例管理</Card.Header>
                            <Card className="mb-3" style={{ width: '100%', minWidth: '312px' }}>
                                <Card.Body>
                                    {
                                        CaseCount.map((item, index) => {
                                            return (
                                                <Row key={index} style={{ padding: '10px' }} >
                                                    <Col sm="4">
                                                        <Dropdown>
                                                            <Dropdown.Toggle style={{ width: '160px' }} id="dropdown-basic" >{item.name}</Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {
                                                                    item.congfig.map((temp) => {
                                                                        return (
                                                                            <Dropdown.Item>{temp.text}{temp.comment}</Dropdown.Item>
                                                                        );
                                                                    })

                                                                }
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </Col>
                                                    <Col sm="1">
                                                        <Form.Label>新增</Form.Label>
                                                    </Col>
                                                    <Col>
                                                        <Form.Control type="text"
                                                            onChange={(e) => {
                                                                CaseCount[index].input = e.target.value
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col sm="2" >
                                                        <Button
                                                            onClick={() => {
                                                                InsterConfigBtn(CaseCount, index);
                                                                store.addNotification(completeMessage);
                                                            }}
                                                        > 新增 </Button>
                                                    </Col>
                                                </Row>)
                                        })
                                    }
                                </Card.Body>
                            </Card>


                            <Card.Header>維修管理</Card.Header>
                            <Card className="mb-3" style={{ width: '100%', minWidth: '312px' }}>
                                <Card.Body>
                                    <Card.Body>
                                        {
                                            MaintainCount.map((item, index) => {
                                                return (
                                                    <Row key={index} style={{ padding: '10px' }} >
                                                        <Col sm="4">
                                                            <Dropdown>
                                                                <Dropdown.Toggle style={{ width: '160px' }} id="dropdown-basic" >{item.name}</Dropdown.Toggle>
                                                                <Dropdown.Menu>
                                                                    {
                                                                        item.congfig.map((temp) => {
                                                                            return (
                                                                                <Dropdown.Item>{temp.text}{temp.comment}</Dropdown.Item>
                                                                            );
                                                                        })

                                                                    }
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </Col>
                                                        <Col sm="1">
                                                            <Form.Label>新增</Form.Label>
                                                        </Col>
                                                        <Col>
                                                            <Form.Control type="text"
                                                                onChange={(e) => {
                                                                    MaintainCount[index].input = e.target.value
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col sm="2" >
                                                            <Button
                                                                onClick={() => {
                                                                    InsterConfigBtn(MaintainCount, index);
                                                                    store.addNotification(completeMessage);
                                                                }}
                                                            > 新增 </Button>
                                                        </Col>
                                                    </Row>)
                                            })
                                        }
                                    </Card.Body>
                                </Card.Body>
                            </Card>
                        </Card.Body>
                    </Card>
                </Row>
            </Container>

        </React.Fragment >
    );

}
