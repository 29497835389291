import { apiCustomerQuery, apiCustomerInsert, apiCustomerUpdate, apiCustContactsInsert, apiCustContactsUpdate } from '../../utils/api.js';

export const customerQuery = (newCustomer) => async (dispatch, getState) => {
    let res = await apiCustomerQuery(newCustomer);
    let data = JSON.parse(res.request.response);
    dispatch(renCustomer(data));
}

export const customerInsert = (newCustomer) => async (dispatch, getState) => {
    let res = await apiCustomerInsert(newCustomer);
    if (res !== 200) {
        return;
    }
    dispatch(customerQuery());
}

export const customerUpdate = (newCustomer) => async (dispatch, getState) => {
    let res = await apiCustomerUpdate(newCustomer);
    if (res !== 200) {
        return;
    }
    dispatch(customerQuery());
}

export const custcontactsInsert = (newCustContacts) => async (dispatch, getState) => {
    let res = await apiCustContactsInsert(newCustContacts);
    if (res !== 200) {
        return;
    }
    dispatch(customerQuery());
}

export const custcontactsUpdate = (newCustContacts) => async (dispatch, getState) => {
    console.log(newCustContacts);
    let res = await apiCustContactsUpdate(newCustContacts);
    if (res !== 200) {
        console.log(res);
        return;
    }
    dispatch(customerQuery());
}

const typeRenCustomer = 'typeRenCustomer';
const renCustomer = (arrCustomers) => {

    return {
        type: typeRenCustomer,
        customers: arrCustomers
    }
}

const initState = [];


export const reducer = (state = initState, action) => {
    switch (action.type) {
        case typeRenCustomer:
            return action.customers
        default:
            return state;
    }
}