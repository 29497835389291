import { apiMachBasicQuery, apiMachBasicInsert, apiMachBasicUpdate, apiMachBasicDelete } from '../../utils/api.js';


export const basicQuery = (newBasic) => async (dispatch, getState) => {
    let res = await apiMachBasicQuery(newBasic);
    let data = JSON.parse(res.request.response);
    dispatch(renBasic(data));
}

export const basicInsert = (newBasic) => async (dispatch, getState) => {
    let res = await apiMachBasicInsert(newBasic);
    if (res.status !== 200) {
        return;
    }
    dispatch(await basicQuery());
}

export const basicUpdate = (newBasic) => async (dispatch, getState) => {
    let res = await apiMachBasicUpdate(newBasic);
    if (res.status !== 200) {
        return;
    }
    dispatch(await basicQuery());
}
export const basicDelete = (newBasic) => async (dispatch, getState) => {
    let res = await apiMachBasicDelete(newBasic);
    if (res.status !== 200) {
        return;
    }
    dispatch(await basicQuery());
}



const typeRenBasic = 'typeRenQBasic';
const renBasic = (arrBasic) => {

    return {
        type: typeRenBasic,
        basic: arrBasic
    }
}



const initState = {
    basic: []
}


export const reducer = (state = initState, action) => {
    switch (action.type) {
        case typeRenBasic:
            return { ...state, basic: [...action.basic] };

        default:
            return state;
    }
}