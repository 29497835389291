import React, { useState, useEffect } from 'react';
import { Row, Button, Container, FormControl, InputGroup, Card, Collapse, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faPlus, faChevronDown, faSignOutAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import { Link } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import 'react-dropdown-tree-select/dist/styles.css'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useSelector, useDispatch } from 'react-redux';
import { recordQuery, recordDelete } from '../../../../action/maintain/recordAction.js';
import { basicQuery} from '../../../../action/maintain/basicAction.js';

import { useRWD, devicesIsMobile } from '../../../../useRWD.js';
import { store } from 'react-notifications-component';
import { useHistory } from "react-router-dom";
import '../../../Maintain/BtnforPhone.css';

const queryParam = {
    keyword: null
};

const completeMessage = {
    title: "搜尋",
    message: "完成",
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 3000,
        onScreen: true
    }
};
export const MaintRecord = (props) => {

    const dispatch = useDispatch();
    const rowStyle = {
        cursor: "pointer",
    };
    const [state, setState] = useState({ gridApi: null });
    const { maintJobClass, maintStatus, eqpArea, eqpType, eqpName } = useSelector(x => x.config);
    const { maintRecord, machBasic, organization, customers } = useSelector(x => x);
    const { MachRecordAdd, MachRecordEdit, MachDelete } = useSelector(x => x.uiMach);

    let filteredData = maintRecord.record;


    const [data, setData] = useState(queryParam);
    const handleLoadingUI = (isShow) => { if (isShow === true) { dispatch({ type: 'SHOW_LOADING' }); } else { dispatch({ type: 'HIDE_LOADING' }); } }//show laod UI
    useEffect(() => {
        // Create an async function within our effect:
        async function runEffect() {
            handleLoadingUI(true);
            await dispatch(basicQuery());
            await dispatch(recordQuery());
            handleLoadingUI(false);
        }
        // Immediately call this function to run the effect:
        runEffect();

    }, []) //  eslint-disable-line react-hooks/exhaustive-deps


    //依據視窗大小調整 高度
    let device = useRWD();
    device = (device + 80) + 'px';
    let isMoble = devicesIsMobile();//判斷手機
    return (
        <React.Fragment>
            <Container fluid="mx-0">
                <Row className="mx-0">
                    <Row className="mx-0">
                        <InputGroup className="mb-3">
                            {!MachRecordAdd.disabled &&
                                <Link to="/maintRecordEdit/0">
                                    <Button style={{ background: '#00A2FF' }} className="btn-custom-phone">
                                        <FontAwesomeIcon className='rounded me-2' icon={faPlus} />
                                        新增
                                    </Button>
                                </Link>
                            }
                            <FormControl
                                type="search"
                                placeholder="輸入關鍵字 : "
                                aria-label="Search"
                                size="1g"
                                className="keyword-bar"
                                defaultValue={queryParam.keyword}
                                onChange={(e) => setData({ ...queryParam, keyword: e.target.value })}
                            />
                            <Button variant="primary" className="btn-custom-phone" onClick={() => {
                                dispatch(recordQuery(data));
                                completeMessage.title = "搜尋";
                                store.addNotification(completeMessage);
                            }}>
                                <FontAwesomeIcon className='rounded' icon={faSearch} />
                                搜尋
                            </Button>
                        </InputGroup>
                    </Row>

                    <Row className="mx-0">
                        {isMoble &&
                            <MobileSearchUI />
                        }
                        {!isMoble &&
                            <div className="ag-theme-alpine" style={{ height: device, width: '100%' }}>

                                <AgGridReact
                                    rowData={filteredData}
                                    rowStyle={rowStyle}
                                    defaultColDef={{
                                        sortable: true,
                                        resizable: true,
                                        wrapText: true,
                                        autoHeight: true,
                                        filter: true
                                    }}
                                    onGridReady={(params) => {
                                        setState({
                                            ...state,
                                            gridApi: params.api
                                        });
                                    }}


                                    paginationAutoPageSize={true}
                                    pagination={true}
                                >
                                    {!MachDelete.disabled &&
                                    <AgGridColumn headerName="" field="id" cellRendererFramework={DeleteRenderer} width={60} pinned="left"></AgGridColumn>
                                    }
                                    <AgGridColumn headerName="No." field="id" width={80} pinned="left"></AgGridColumn>
                                    {!MachRecordEdit.disabled &&
                                        <AgGridColumn headerName="紀錄" field="id" cellRendererFramework={EditRenderer} width={100} pinned="left"></AgGridColumn>
                                    }
                                    <AgGridColumn headerName="區域" field="equipmentID" width={80} valueGetter={(x) => {
                                        let basicID = machBasic.basic.find((e) => e.id === x.data.equipmentID);
                                        if (basicID === undefined) { return null; }
                                        let item = eqpArea.find((e) => e.value === basicID.area);
                                        return item === undefined ? null : item.text;
                                    }}></AgGridColumn>
                                    <AgGridColumn headerName="客戶名稱" field="equipmentID" width={150} valueGetter={(x) => {
                                        let basicID = machBasic.basic.find((e) => e.id === x.data.equipmentID);
                                        if (basicID === undefined) {
                                            return null;
                                        }
                                        let item = customers.find((e) => e.customerId === basicID.customerId);
                                        if (item === undefined) {
                                            return null;
                                        }
                                        return item.name;
                                    }}></AgGridColumn>
                                    <AgGridColumn headerName="機型" field="equipmentID" width={150} editable={true} valueGetter={(x) => {
                                        let basicID = machBasic.basic.find((e) => e.id === x.data.equipmentID);
                                        if (basicID === undefined) { return null; }
                                        let itemName = eqpName.find((e) => e.value === basicID.name);
                                        if (itemName === undefined) { return null; }
                                        let item = eqpType.find((e) => itemName.configId === e.order && e.value === basicID.type);
                                        return item === undefined ? null : item.text;
                                    }}></AgGridColumn>
                                    <AgGridColumn headerName="設備序號" field="equipmentID" editable={true} width={130} valueGetter={(x) => {
                                        let item = machBasic.basic.find((e) => e.id === x.data.equipmentID);
                                        return item === undefined ? null : item.serialNo;
                                    }}></AgGridColumn>
                                    <AgGridColumn headerName="維護日期" field="date" width={120} cellRenderer={(x) => {
                                        return x.data.date ? x.data.date.replace('T', ' ').substr(0, 10) : '';
                                    }}></AgGridColumn>
                                    <AgGridColumn headerName="工作內容分類" field="category" width={130} valueGetter={(x) => {
                                        let item = maintJobClass.find((e) => e.value === x.data.category);
                                        return item === undefined ? null : item.text;
                                    }}></AgGridColumn>

                                    <AgGridColumn headerName="維修狀態說明" field="maintStatus" width={130} valueGetter={(x) => {
                                        let item = maintStatus.find((e) => e.value === x.data.maintStatus);
                                        return item === undefined ? null : item.text;
                                    }}></AgGridColumn>
                                    <AgGridColumn headerName="維修內容說明" field="content" width={150} ></AgGridColumn>
                                    <AgGridColumn headerName="維修結果/建議" field="mainResults" width={150} ></AgGridColumn>
                                    <AgGridColumn headerName="車程(時)" field="transTime" width={110} ></AgGridColumn>
                                    <AgGridColumn headerName="工時(時)" field="workingHours" width={110} ></AgGridColumn>
                                    <AgGridColumn headerName="設備所在位址" field="eqpLocation" width={150} valueGetter={(x) => {
                                        let item = customers.find((e) => e.customerId === x.data.eqpLocation);
                                        if (item === undefined) {
                                            return null;
                                        }
                                        return item.name;
                                    }}></AgGridColumn>
                                    <AgGridColumn headerName="備註" field="remark" width={200} ></AgGridColumn>
                                    <AgGridColumn headerName="維修人員" field="employee" width={120} valueGetter={(x) => {
                                        let item = organization.allMembers.find((e) => e.ID === x.data.employee);
                                        return item === undefined ? null : item.Name;
                                    }}></AgGridColumn>
                                    <AgGridColumn headerName="更新者" field="updatedBy" width={100} valueGetter={(x) => {
                                        let item = organization.allMembers.find((e) => e.ID === x.data.updatedBy);
                                        return item === undefined ? null : item.Name;
                                    }}></AgGridColumn>
                                    <AgGridColumn headerName="更新時間" field="updatedDate" width={200} cellRenderer={(x) => {
                                        return x.data.updatedDate ? x.data.updatedDate.replace('T', ' ').substr(0, 19) : '';
                                    }}></AgGridColumn>
                                </AgGridReact>
                            </div>
                        }
                    </Row>

                </Row>
            </Container>
        </React.Fragment>
    );

}

export const EditRenderer = (props) => {
    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const hist = useHistory();
    const EditMethod = async () => {
        let id = cellValue;
        hist.push(`/maintRecordEdit/${id}`);
    }

    return (
        <span>
            <button
                style={{ height: 30, lineHeight: 0.5, fontWeight: 'bold' }}
                className="btn btn-link"
                onClick={() => {
                    EditMethod();
                }}
            >
                紀錄
            </button>
        </span>
    );
};
export const DeleteRenderer = (props) => {

    const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
    const dispatch = useDispatch();
    const { maintRecord } = useSelector(x => x);
    const DeleteMethod = async () => {
        let id = cellValue;
        const MaintRecordData = maintRecord.record.find(e => e.id == id);// eslint-disable-line eqeqeq

        await dispatch(recordDelete(MaintRecordData));

    }

    return (
        <span>
            <button
                style={{ height: 25, width: 25, padding: 0 }}
                className="btn btn-danger"
                onClick={() => {
                    DeleteMethod();
                }}
            >
                <FontAwesomeIcon icon={faTrashAlt} />
            </button>
        </span>
    );
};
export const MobileSearchUI = (props) => {
    const { maintJobClass, maintStatus, eqpArea, eqpType, eqpName } = useSelector(x => x.config);
    const { maintRecord, machBasic, organization, customers } = useSelector(x => x);
    let filteredData = maintRecord.record;
    const hist = useHistory();
    const EditMethod = (id) => {
        hist.push(`/maintRecordEdit/${id}`);
    }

    const [expandedId, setExpandedId] = useState(null);

    const handleExpandClick = (id) => {
        setExpandedId(expandedId === id ? null : id);
    };

    return (
        <div style={{ width: '100%' }}>
            {
                filteredData.map((data, index) => {
                    let basicID = machBasic.basic.find((e) => e.id === data.equipmentID);
                    let _eqpArea = eqpArea.find((e) => e.value === basicID.area);
                    let _customer = customers.find((e) => e.customerId === basicID.customerId);
                    _customer = _customer === undefined ? null : _customer.name;
                    let _eqpName = eqpName.find((e) => e.value === basicID.name);
                    let _eqpType = eqpType.find((e) => _eqpName.configId === e.order && e.value === basicID.type);
                    let _date = data.date ? data.date.replace('T', ' ').substr(0, 10) : '';
                    let _category = maintJobClass.find((e) => e.value === data.category);
                    let _maintStatus = maintStatus.find((e) => e.value === data.maintStatus);
                    let _eqpLocation = customers.find((e) => e.customerId === data.eqpLocation);
                    _eqpLocation = _eqpLocation === undefined ? null : _eqpLocation.name;
                    let _employee = organization.allMembers.find((e) => e.ID === data.employee);
                    let _organization = organization.allMembers.find((e) => e.ID === data.updatedBy);
                    let _updatedDate = data.updatedDate ? data.updatedDate.replace('T', ' ').substr(0, 10) : '';
                    return (

                        <Card key={index} style={{ borderRadius: '20px', margin: '5px' }}>
                            <Card.Body style={{ padding: '8px' }}>

                                <Row>
                                    <Card.Title style={{ fontSize: '14px' }}>設備序號:{basicID.serialNo}</Card.Title>
                                    <Col>
                                        <Card.Text style={{ fontSize: '12px' }}>
                                            客戶名稱:{_customer}&nbsp;
                                            維修人員:{_employee === undefined ? '' : _employee.Name}<br />
                                            維護日期:{_date}<br />
                                            機型:{_eqpType.text}&nbsp;
                                            區域:{_eqpArea.text}<br />
                                            <Button style={{ display: 'contents', lineHeight: '0rm' }}
                                                variant="link"
                                                onClick={() => handleExpandClick(data.id)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={expandedId === data.id}
                                            >
                                                <FontAwesomeIcon icon={faChevronDown} />
                                            </Button>
                                        </Card.Text>
                                    </Col>
                                    <Col xs={2}>
                                        <Button variant="link" style={{ fontSize: '20px' }} onClick={() => {
                                            EditMethod(data.id);
                                        }}>
                                            <FontAwesomeIcon icon={faSignOutAlt} />
                                        </Button>
                                    </Col>
                                </Row>
                            </Card.Body>

                            <Collapse in={expandedId === data.id}  >
                                <Card.Text style={{ fontSize: '12px', padding: '5px' }}>
                                    車程(分):{data.transTime}&nbsp;
                                    工時(時):{data.workingHours}<br />
                                    工作內容分類:{_category.text}<br />
                                    維修狀態說明:{_maintStatus.text}&nbsp;
                                    維修內容說明:{data.content}<br />
                                    維修結果/建議:{data.mainResults}<br />
                                    設備所在位址:{_eqpLocation}<br />
                                    備註:{data.remark}<br />
                                    更新人員:{_organization === undefined ? '':_organization.Name}&nbsp;
                                    更新時間:{_updatedDate}
                                </Card.Text>
                            </Collapse>

                        </Card>
                    )
                })
            }

        </div>
    );
}