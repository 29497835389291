import React, { useState } from 'react';
import { Form, Row, Button, Col, Container } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFileExport } from '@fortawesome/free-solid-svg-icons';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import NavBar from '../../Navbar/NavBar.jsx';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import { useRWD } from '../../../useRWD.js';
import { reportQuery } from '../../../action/case/caseActions.js';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { store } from 'react-notifications-component';

const defaultstate = {
    StartDate: new Date(),
    EndDate: new Date(),
    department: [],
    gridApi: null
};

const completeMessage = {
    title: "搜尋",
    message: "搜尋完成",
    type: "success",
    insert: "top",
    container: "top-center",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
        duration: 300,
        onScreen: true
    }
};

export const BusinessReport = (props) => {
    const hist = useHistory();
    const dispatch = useDispatch();

    const rowStyle = {
        cursor: "pointer",
    };
    const [state, setState] = useState(defaultstate);
    const { businessreport } = useSelector(x => x.businessCase);
    const handlesearch = () => {
        let start = new Date(
            state.StartDate.getFullYear(),
            state.StartDate.getMonth(),
            state.StartDate.getDate());
        let end = new Date(
            state.EndDate.getFullYear(),
            state.EndDate.getMonth(),
            state.EndDate.getDate());
        dispatch(reportQuery({
            StartDate: start,
            EndDate: end
        }));
        store.addNotification(completeMessage);
    }
    //依據視窗大小調整 高度
    let device = useRWD();
    device = (device + 75) + 'px';

    return (

        <React.Fragment >
            <NavBar />
            <Container fluid>
                <Row className="mx-auto">
                    <Form.Group as={Row} className="mb-1">
                        <Form.Label>日期區間</Form.Label>
                        <Col md={2}>
                            <DatePicker
                                className="form-control"
                                placeholder="開始"
                                selected={state.StartDate}
                                isClearable
                                selectsStart
                                dateFormat="yyyy/MM/dd"
                                onChange={(date) => {
                                    setState({
                                        ...state, StartDate: date
                                    });
                                }} />
                        </Col>

                        <Col md={2}>
                            <DatePicker
                                className="form-control"
                                placeholder="結束"
                                selected={state.EndDate}
                                isClearable
                                selectsEnd
                                dateFormat="yyyy/MM/dd"
                                onChange={(date) => {
                                    setState({
                                        ...state, EndDate: date
                                    });
                                }} />
                        </Col>
                        <Col md={2}>
                            <Button variant="primary"
                                onClick={(e) => {
                                    handlesearch();
                                }}
                            >
                                <FontAwesomeIcon className='rounded' icon={faSearch} />
                                搜尋
                            </Button>
                        </Col>
                        <Col md={2}>
                            <Button variant="outline-primary" className='me-1' onClickCapture={() => { state.gridApi.exportDataAsCsv(); }}>
                                <FontAwesomeIcon className='rounded me-2' icon={faFileExport} />
                                Export
                            </Button>
                        </Col>
                    </Form.Group>
                </Row>
                <Row className="mx-auto mt-3">
                    <div className="ag-theme-alpine " style={{ height: device, width: '100%' }}>
                        <div style={{ height: '100%', width: '100%' }}>

                            <AgGridReact
                                rowData={businessreport}
                                rowStyle={rowStyle}
                                paginationAutoPageSize={true}
                                defaultColDef={{
                                    sortable: true,
                                    resizable: true,
                                    wrapText: true,
                                    autoHeight: true,
                                    filter: true
                                }}

                                onGridReady={(params) => {
                                    setState({
                                        ...state,
                                        gridApi: params.api
                                    });
                                }}
                                onRowClicked={(e) => {
                                    let id = e.data.userID;
                                    console.log(id);
                                    hist.push(`/business/ReportDetail/${id}`);
                                }}

                            >
                                <AgGridColumn headerName="業務人員" field="userName" width={105} ></AgGridColumn>
                                <AgGridColumn headerName="本期拜訪總數" field="jc0" width={130}></AgGridColumn>
                                <AgGridColumn headerName="陌生拜訪" field="jc1" width={105}></AgGridColumn>
                                <AgGridColumn headerName="客情維護（交機前）" field="jc2" width={180}></AgGridColumn>
                                <AgGridColumn headerName="客情維護（交機後）" field="jc3" width={180}></AgGridColumn>
                                <AgGridColumn headerName="報價/議價" field="jc4" width={110}></AgGridColumn>
                                <AgGridColumn headerName="交機" field="jc5" width={80}></AgGridColumn>
                                <AgGridColumn headerName="Demo" field="jc6" width={90}></AgGridColumn>
                                <AgGridColumn headerName="診所人員教育訓練" field="jc7" width={160}></AgGridColumn>
                                <AgGridColumn headerName="產品介紹" field="jc8" width={105}></AgGridColumn>
                                <AgGridColumn headerName="送貨/請款" field="jc9" width={110}></AgGridColumn>
                                <AgGridColumn headerName="行銷/學術活動邀約" field="jc10" width={180}></AgGridColumn>
                                <AgGridColumn headerName="前期拜訪總數" field="jc11" width={140}></AgGridColumn>
                                <AgGridColumn headerName="本月拜訪總數" field="jc12" width={140}></AgGridColumn>
                            </AgGridReact>
                        </div>
                    </div>
                </Row>
            </Container>
        </React.Fragment>

    )
}

//class BusinessReport extends React.PureComponent {
//    state = {
//        StartDate: new Date(),
//        EndDate: new Date()
//    };
//    rowStyle = {
//        cursor: "pointer",
//    };

//    //UNSAFE_componentWillMount = () => {
//    //    reportQuery(new Date(), new Date())
//    //}


//    //columnStyle1 = {
//    //    backgroundColor: 'skyblue'
//    //}
//    //columnStyle2 = {
//    //    backgroundColor: 'yellow'
//    //}
//    //columnStyle3 = {
//    //    backgroundColor: 'lightgray'
//    //}


//    render() {
//        const { reportQuery, findMember } = this.props;
//        return (
//            <React.Fragment>
//                <NavBar />
//                <Container fluid>
//                    <Row className="mx-auto">
//                        <Form.Group as={Row} className="mb-1">
//                            <Form.Label>日期區間</Form.Label>
//                            <Col md={2}>
//                                <DatePicker
//                                    className="form-control"
//                                    placeholder="開始"
//                                    selected={this.state.StartDate}
//                                    isClearable
//                                    selectsStart
//                                    dateFormat="yyyy/MM/dd"
//                                    onChange={(date) => {
//                                        this.setState({ StartDate: date });
//                                    }} />
//                            </Col>

//                            <Col md={2}>
//                                <DatePicker
//                                    className="form-control"
//                                    placeholder="結束"
//                                    selected={this.state.EndDate}
//                                    isClearable
//                                    selectsEnd
//                                    dateFormat="yyyy/MM/dd"
//                                    onChange={(date) => {
//                                        this.setState({ EndDate: date });
//                                    }} />
//                            </Col>
//                            <Col md={2}>
//                                <Button variant="primary"
//                                    onClick={(e) => {
//                                        let start = new Date(
//                                            this.state.StartDate.getFullYear(),
//                                            this.state.StartDate.getMonth(),
//                                            this.state.StartDate.getDate());
//                                        let end = new Date(
//                                            this.state.EndDate.getFullYear(),
//                                            this.state.EndDate.getMonth(),
//                                            this.state.EndDate.getDate());

//                                        reportQuery({
//                                            StartDate: start,
//                                            EndDate: end
//                                        });
//                                    }}
//                                >
//                                    <FontAwesomeIcon className='rounded' icon={faSearch} />
//                                    搜尋
//                                </Button>
//                            </Col>
//                            <Col md={2}>
//                                <Button variant="outline-primary" className='me-1' onClickCapture={() => { this.state.gridApi.exportDataAsCsv(); }}>
//                                    <FontAwesomeIcon className='rounded me-2' icon={faFileExport} />
//                                    Export
//                                </Button>
//                            </Col>
//                        </Form.Group>
//                    </Row>
//                    <Row className="mx-auto mt-3">
//                        <div className="ag-theme-alpine " style={{ height: '600px', width: '100%' }}>
//                            <div style={{ height: '100%', width: '100%' }}>

//                                <AgGridReact
//                                    rowData={this.props.businessCase.businessreport}
//                                    rowStyle={this.rowStyle}
//                                    paginationAutoPageSize={true}
//                                    defaultColDef={{
//                                        sortable: true,
//                                        resizable: true,
//                                        wrapText: true,
//                                        autoHeight: true,
//                                        filter: true
//                                    }}
//                                    onGridReady={(params) => {
//                                        this.setState({ gridApi: params.api });
//                                    }}
//                                    onRowClicked={(e) => {
//                                        let id = e.data.userID;
//                                        console.log(id);
//                                        this.props.history.push(`/business/ReportDetail/${id}`);
//                                    }}

//                                >
//                                    <AgGridColumn headerName="業務人員" field="userName" width={105} ></AgGridColumn>
//                                    <AgGridColumn headerName="本期拜訪總數" field="jc0" width={130}></AgGridColumn>
//                                    <AgGridColumn headerName="陌生拜訪" field="jc1" width={105}></AgGridColumn>
//                                    <AgGridColumn headerName="客情維護" field="jc2" width={105}></AgGridColumn>
//                                    <AgGridColumn headerName="demo" field="jc3" width={90}></AgGridColumn>
//                                    <AgGridColumn headerName="報價議價" field="jc4" width={105}></AgGridColumn>
//                                    <AgGridColumn headerName="診所人員教育訓練" field="jc5" width={160}></AgGridColumn>
//                                    <AgGridColumn headerName="產品介紹" field="jc6" width={105}></AgGridColumn>
//                                    <AgGridColumn headerName="送貨/請款" field="jc7" width={110}></AgGridColumn>
//                                    <AgGridColumn headerName="交機" field="jc8" width={80}></AgGridColumn>
//                                    <AgGridColumn headerName="前期拜訪總數" field="jc9" width={140}></AgGridColumn>
//                                    <AgGridColumn headerName="本月拜訪總數" field="jc10" width={140}></AgGridColumn>
//                                </AgGridReact>
//                            </div>
//                        </div>
//                    </Row>
//                </Container>
//            </React.Fragment>

//        )
//    }
//}

//const actions = Object.assign(
//    {},
//    {
//        reportQuery,
//        findMember
//    }
//);

//export default connect((state) => state, actions)(BusinessReport);