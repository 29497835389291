import { apiRolesQuery, apiRolesInsert, apiRolesUpdate, apiRoleMembersInsert, apiRoleMembersUpdate } from '../../utils/api.js';

export const rolesQuery = () => async (dispatch, getState) => {
    let res = await apiRolesQuery({});
    let data = JSON.parse(res.request.response);
    dispatch(renRoles(data));
}

export const rolesInsert = (newRole) => async (dispatch, getState) => {
    let res = await apiRolesInsert(newRole);
    if (res !== 200) {
        return;
    }
    dispatch(rolesQuery());
}

export const rolesUpdate = (newRole) => async (dispatch, getState) => {
    let res = await apiRolesUpdate(newRole);
    if (res !== 200) {
        return;
    }
    dispatch(rolesQuery());
}

export const rolemembersInsert = (newRowMember) => async (dispatch, getState) => {
    let res = await apiRoleMembersInsert(newRowMember);
    if (res !== 200) {
        return;
    }
    dispatch(rolesQuery());
}

export const rolemembersUpdate = (newRowMember) => async (dispatch, getState) => {
    console.log(newRowMember);
    let res = await apiRoleMembersUpdate(newRowMember);
    if (res !== 200) {
        console.log(res);
        return;
    }
    dispatch(rolesQuery());
}

const typeRenRoles = 'typeRenRoles';
const renRoles = (arrRoles) => {
    return {
        type: typeRenRoles,
        roles: arrRoles
    }
}

const initState = {
    roles: [],
};

export const reducer = (state = initState, action) => {
    switch (action.type) {
        case typeRenRoles:
            return action.roles
        default:
            return state;
    }
}