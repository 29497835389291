import { apiMachHandleQuery, apiMachHandleInsert, apiMachHandleUpdate, apiMachHandleDelete } from '../../utils/api.js';


export const handleQuery = (newHandle) => async (dispatch, getState) => {
    let res = await apiMachHandleQuery(newHandle);
    let data = JSON.parse(res.request.response);
    dispatch(renHandle(data));
}

export const handleInsert = (newHandle) => async (dispatch, getState) => {
    let res = await apiMachHandleInsert(newHandle);
    if (res.status !== 200) {
        return;
    }
    dispatch(await handleQuery());
}

export const handleUpdate = (newHandle) => async (dispatch, getState) => {
    let res = await apiMachHandleUpdate(newHandle);
    if (res.status !== 200) {
        return;
    }
    dispatch(await handleQuery());
}
export const handleDelete = (newHandle) => async (dispatch, getState) => {
    let res = await apiMachHandleDelete(newHandle);
    if (res.status !== 200) {
        return;
    }
    dispatch(await handleQuery());
}



const typeRenHandle = 'typeRenQHandle';
const renHandle = (arrHandle) => {

    return {
        type: typeRenHandle,
        handle: arrHandle
    }
}



const initState = {
    handle: []
}


export const reducer = (state = initState, action) => {
    switch (action.type) {
        case typeRenHandle:
            return { ...state, handle: [...action.handle] };

        default:
            return state;
    }
}