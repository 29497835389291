const typeMachSupplierQuery = "MachSupplierQuery";
export const toggleMachSupplierQuery = (data) => (dispatch, getState) => {

    dispatch(disabledMachSupplierQuery(data));
}

export const disabledMachSupplierQuery = (status) => {
    return {
        type: typeMachSupplierQuery,
        supplierQuery: status
    }
}
const typeMachSupplierAdd = "MachSupplierAdd";
export const toggleMachSupplierAdd = (data) => (dispatch, getState) => {

    dispatch(disabledMachSupplierAdd(data));
}

export const disabledMachSupplierAdd = (status) => {
    return {
        type: typeMachSupplierAdd,
        supplierAdd: status
    }
}
const typeMachSupplierEdit = "MachSupplierEdit";
export const toggleMachSupplierEdit = (data) => (dispatch, getState) => {

    dispatch(disabledMachSupplierEdit(data));
}

export const disabledMachSupplierEdit = (status) => {
    return {
        type: typeMachSupplierEdit,
        supplierEdit: status
    }
}
const typeMachBasicQuery = "MachBasicQuery";
export const toggleMachBasicQuery = (data) => (dispatch, getState) => {

    dispatch(disabledMachBasicQuery(data));
}

export const disabledMachBasicQuery = (status) => {
    return {
        type: typeMachBasicQuery,
        machBasicQuery: status
    }
}
const typeMachBasicAdd = "MachBasicAdd";
export const toggleMachBasicAdd = (data) => (dispatch, getState) => {

    dispatch(disabledMachBasicAdd(data));
}

export const disabledMachBasicAdd = (status) => {
    return {
        type: typeMachBasicAdd,
        machBasicAdd: status
    }
}
const typeMachBasicEdit = "MachBasicEdit";
export const toggleMachBasicEdit = (data) => (dispatch, getState) => {

    dispatch(disabledMachBasicEdit(data));
}

export const disabledMachBasicEdit = (status) => {
    return {
        type: typeMachBasicEdit,
        machBasicEdit: status
    }
}
const typeMachHandleQuery = "MachHandleQuery";
export const toggleMachHandleQuery = (data) => (dispatch, getState) => {

    dispatch(disabledMachHandleQuery(data));
}

export const disabledMachHandleQuery = (status) => {
    return {
        type: typeMachHandleQuery,
        machHandleQuery: status
    }
}
const typeMachHandleAdd = "MachHandleAdd";
export const toggleMachHandleAdd = (data) => (dispatch, getState) => {

    dispatch(disabledMachHandleAdd(data));
}

export const disabledMachHandleAdd = (status) => {
    return {
        type: typeMachHandleAdd,
        machHandleAdd: status
    }
}
const typeMachHandleEdit = "MachHandleEdit";
export const toggleMachHandleEdit = (data) => (dispatch, getState) => {

    dispatch(disabledMachHandleEdit(data));
}

export const disabledMachHandleEdit = (status) => {
    return {
        type: typeMachHandleEdit,
        machHandleEdit: status
    }
}
const typeMachRecordQuery = "MachRecordQuery";
export const toggleMachRecordQuery = (data) => (dispatch, getState) => {

    dispatch(disabledMachRecordQuery(data));
}

export const disabledMachRecordQuery = (status) => {
    return {
        type: typeMachRecordQuery,
        machRecordQuery: status
    }
}
const typeMachRecordAdd = "MachRecordAdd";
export const toggleMachRecordAdd = (data) => (dispatch, getState) => {

    dispatch(disabledMachRecordAdd(data));
}

export const disabledMachRecordAdd = (status) => {
    return {
        type: typeMachRecordAdd,
        machRecordAdd: status
    }
}
const typeMachRecordEdit = "MachRecordEdit";
export const toggleMachRecordEdit = (data) => (dispatch, getState) => {

    dispatch(disabledMachRecordEdit(data));
}

export const disabledMachRecordEdit = (status) => {
    return {
        type: typeMachRecordEdit,
        machRecordEdit: status
    }
}
const typeMachContractQuery = "MachContractQuery";
export const toggleMachContractQuery = (data) => (dispatch, getState) => {

    dispatch(disabledMachContractQuery(data));
}

export const disabledMachContractQuery = (status) => {
    return {
        type: typeMachContractQuery,
        machContractQuery: status
    }
}
const typeMachContractAdd = "MachContractAdd";
export const toggleMachContractAdd = (data) => (dispatch, getState) => {

    dispatch(disabledMachContractAdd(data));
}

export const disabledMachContractAdd = (status) => {
    return {
        type: typeMachContractAdd,
        machContractAdd: status
    }
}
const typeMachContractEdit = "MachContractEdit";
export const toggleMachContractEdit = (data) => (dispatch, getState) => {

    dispatch(disabledMachContractEdit(data));
}

export const disabledMachContractEdit = (status) => {
    return {
        type: typeMachContractEdit,
        machContractEdit: status
    }
}
const typeMachReport = "MachReport";
export const toggleMachReport = (data) => (dispatch, getState) => {

    dispatch(disabledMachReport(data));
}

export const disabledMachReport = (status) => {
    return {
        type: typeMachReport,
        machReport: status
    }
}
const typeMaintStaffReport = "MaintStaffReport";
export const toggleMaintStaffReport = (data) => (dispatch, getState) => {

    dispatch(disabledMaintStaffReport(data));
}

export const disabledMaintStaffReport = (status) => {
    return {
        type: typeMaintStaffReport,
        maintStaffReport: status
    }
}
const typeMachBasicSnoEdit = "MachBasicSnoEdit";
export const toggleMachBasicSnoEdit = (data) => (dispatch, getState) => {

    dispatch(disabledMachBasicSnoEdit(data));
}

export const disabledMachBasicSnoEdit = (status) => {
    return {
        type: typeMachBasicSnoEdit,
        machBasicSnoEdit: status
    }
}
const typeMachHandSnoEdit = "MachHandSnoEdit";
export const toggleMachHandSnoEdit = (data) => (dispatch, getState) => {

    dispatch(disabledMachHandSnoEdit(data));
}

export const disabledMachHandSnoEdit = (status) => {
    return {
        type: typeMachHandSnoEdit,
        machHandSnoEdit: status
    }
}
const typeMachDelete = "MachDelete";
export const toggleMachDelete = (data) => (dispatch, getState) => {

    dispatch(disabledMachDelete(data));
}

export const disabledMachDelete = (status) => {
    return {
        type: typeMachDelete,
        machDelete: status
    }
}

const initState = {
    MachSupplierQuery: {
        disabled: false
    },
    MachSupplierAdd: {
        disabled: false
    },
    MachSupplierEdit: {
        disabled: false
    },
    MachBasicQuery: {
        disabled: false
    },
    MachBasicAdd: {
        disabled: false
    },
    MachBasicEdit: {
        disabled: false
    },
    MachHandleQuery: {
        disabled: false
    },
    MachHandleAdd: {
        disabled: false
    },
    MachHandleEdit: {
        disabled: false
    },
    MachRecordQuery: {
        disabled: false
    },
    MachRecordAdd: {
        disabled: false
    },
    MachRecordEdit: {
        disabled: false
    },
    MachContractQuery: {
        disabled: false
    },
    MachContractAdd: {
        disabled: false
    },
    MachContractEdit: {
        disabled: false
    },
    MachReport: {
        disabled: false
    },
    MaintStaffReport: {
        disabled: false
    },
    MachBasicSnoEdit: {
        disabled: false
    },
    MachHandSnoEdit: {
        disabled: false
    },
    MachDelete: {
        disabled: false
    }
}

export const reducer = (state = initState, action) => {
    switch (action.type) {
        case typeMachSupplierQuery:
            return Object.assign({}, state, { MachSupplierQuery: { disabled: action.supplierQuery } });
        case typeMachSupplierAdd:
            return Object.assign({}, state, { MachSupplierAdd: { disabled: action.supplierAdd } });
        case typeMachSupplierEdit:
            return Object.assign({}, state, { MachSupplierEdit: { disabled: action.supplierEdit } });
        case typeMachBasicQuery:
            return Object.assign({}, state, { MachBasicQuery: { disabled: action.machBasicQuery } });
        case typeMachBasicAdd:
            return Object.assign({}, state, { MachBasicAdd: { disabled: action.machBasicAdd } });
        case typeMachBasicEdit:
            return Object.assign({}, state, { MachBasicEdit: { disabled: action.machBasicEdit } });
        case typeMachHandleQuery:
            return Object.assign({}, state, { MachHandleQuery: { disabled: action.machHandleQuery } });
        case typeMachHandleAdd:
            return Object.assign({}, state, { MachHandleAdd: { disabled: action.machHandleAdd } });
        case typeMachHandleEdit:
            return Object.assign({}, state, { MachHandleEdit: { disabled: action.machHandleEdit } });
        case typeMachRecordQuery:
            return Object.assign({}, state, { MachRecordQuery: { disabled: action.machRecordQuery } });
        case typeMachRecordAdd:
            return Object.assign({}, state, { MachRecordAdd: { disabled: action.machRecordAdd } });
        case typeMachRecordEdit:
            return Object.assign({}, state, { MachRecordEdit: { disabled: action.machRecordEdit } });
        case typeMachContractQuery:
            return Object.assign({}, state, { MachContractQuery: { disabled: action.machContractQuery } });
        case typeMachContractAdd:
            return Object.assign({}, state, { MachContractAdd: { disabled: action.machContractAdd } });
        case typeMachContractEdit:
            return Object.assign({}, state, { MachContractEdit: { disabled: action.machContractEdit } });
        case typeMachReport:
            return Object.assign({}, state, { MachReport: { disabled: action.machReport } });
        case typeMaintStaffReport:
            return Object.assign({}, state, { MaintStaffReport: { disabled: action.maintStaffReport } });
        case typeMachBasicSnoEdit:
            return Object.assign({}, state, { MachBasicSnoEdit: { disabled: action.machBasicSnoEdit } });
        case typeMachHandSnoEdit:
            return Object.assign({}, state, { MachHandSnoEdit: { disabled: action.machHandSnoEdit } });
        case typeMachDelete:
            return Object.assign({}, state, { MachDelete: { disabled: action.machDelete } });
        default:
    }
    return state;
}