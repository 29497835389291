import React from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom'
import ReactNotification from 'react-notifications-component'
import { AuthorizedRouteCookie } from './utils/authorizedRouteByCookie.js'
/*import {AuthorizedRoute } from './utils/authorizedRoute.js'*/

// Layouts
/*import Home from './components/Home/Home.jsx';*/
import { Login } from './components/Login/Login.jsx';
import NavBar from './components/Navbar/NavBar.jsx';
import NotFound from './components/Error/NotFound.jsx';
import Unauthorized from './components/Error/Unauthorized.jsx';
import { Business } from './components/Business/Business.jsx';
import { BusinessBox } from './components/Business/Box/BusinessBox.jsx';
import { BusinessReport } from './components/Business/Report/BusinessReport.jsx';
import { ReportDetail } from './components/Business/Report/BusinessReportDetail.jsx';
import { Customer } from './components/Customer/Customer.jsx';
import { CustomerBox } from './components/Customer/CustomerBox.jsx';
import { CustomerTab } from './components/Customer/CustomerTab.jsx';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/scss/custom.scss'
import '../src/css/custom.css'
import 'react-notifications-component/dist/theme.css'
import Roles from './components/Permission/Roles/Roles.jsx';
import { RoleMembers } from './components/Permission/Roles/RoleMembers.jsx';
import { Administration } from './components/Permission/Administration.jsx';
import { UserGroup } from './components/Permission/UserGroup.jsx';
import { Config } from './components/Permission/Config.jsx';
import { ProductSettings } from './components/Material/ProductSettings/ProductSettings.jsx';
import { ProductSettingsEdit } from './components/Material/ProductSettings/ProductSettingsEdit.jsx';
import { QRCode } from './components/Material/QRCode.jsx';
import { ProductTrack } from './components/Material/ProductTrack/ProductTrack.jsx';
import { MaterialLog } from './components/Material/MaterialLog.jsx';
import { StatisticsReport } from './components/Material/StatisticsReport.jsx';
import { MachBasicTab } from './components/Maintain/MachBasicTab.jsx';
import { MaintainTab } from './components/Maintain/MaintainTab.jsx';
import { ReportTab } from './components/Maintain/ReportTab.jsx';
import { MachSupplierEdit } from './components/Maintain/BasicInfo/Supplier/MachSupplierEdit.jsx';
import { MachBasicEdit } from './components/Maintain/BasicInfo/Basic/MachBasicEdit.jsx';
import { MachHandleEdit } from './components/Maintain/BasicInfo/Handle/MachHandleEdit.jsx';
import { MaintRecordEdit } from './components/Maintain/MaintMgt/Record/MaintRecordEdit.jsx';
import { MaintContractEdit } from './components/Maintain/MaintMgt/Contract/MaintContractEdit.jsx';
import { MachReportDetail } from './components/Maintain/Report/Mach/MachReportDetail.jsx';
import { MaintStaffReportDetail } from './components/Maintain/Report/MaintStaff/MaintStaffReportDetail.jsx';
import { MaskLoad } from './components/Loading.jsx';
import { loadingState } from './action/ui/uiActions.js';

export const App = (props) => {

    return (
        <div>
            <BrowserRouter>
                <ReactNotification />
                <Switch>
                    <AuthorizedRouteCookie exact path="/navbar" component={NavBar} />
                    <AuthorizedRouteCookie exact path="/navbar/:Expand" component={NavBar} />
                    <AuthorizedRouteCookie exact path="/business" component={Business} />
                    <AuthorizedRouteCookie exact path='/business/BusinessBox/:id' component={BusinessBox} />
                    <AuthorizedRouteCookie path="/login" component={Login} />
                    <AuthorizedRouteCookie path="/unauthorized" component={Unauthorized} />
                    <AuthorizedRouteCookie path="/customer" component={Customer} />
                    <AuthorizedRouteCookie exact path="/customerbox/:id" component={CustomerBox} />
                    <AuthorizedRouteCookie exact path="/customertab/:id" component={CustomerTab} />
                    <AuthorizedRouteCookie path="/roles" component={Roles} />
                    <AuthorizedRouteCookie exact path="/rolemembers/:id" component={RoleMembers} />
                    <AuthorizedRouteCookie path="/userFunc" component={Administration} />
                    <AuthorizedRouteCookie path="/userGroup" component={UserGroup} />
                    <AuthorizedRouteCookie path="/config" component={Config} />
                    <AuthorizedRouteCookie path="/business/Report" component={BusinessReport} />
                    <AuthorizedRouteCookie exact path="/business/ReportDetail/:id" component={ReportDetail} />
                    <AuthorizedRouteCookie path="/productSettings" component={ProductSettings} />
                    <AuthorizedRouteCookie path="/productSettingsEdit/:code" component={ProductSettingsEdit} />
                    <AuthorizedRouteCookie path="/qrCode" component={QRCode} />
                    <AuthorizedRouteCookie path="/productTrack" component={ProductTrack} />
                    <AuthorizedRouteCookie path="/statisticsReport" component={StatisticsReport} />
                    <AuthorizedRouteCookie path="/materialLog" component={MaterialLog} />
                    <AuthorizedRouteCookie path="/machBasicTab/:tabs/:id" component={MachBasicTab} />
                    <AuthorizedRouteCookie path="/maintainTab/:tabs/:id" component={MaintainTab} />
                    <AuthorizedRouteCookie path="/reportTab/:tabs/:id" component={ReportTab} />
                    <AuthorizedRouteCookie exact path="/machSupplierEdit/:id" component={MachSupplierEdit} />
                    <AuthorizedRouteCookie exact path="/machBasicEdit/:id" component={MachBasicEdit} />
                    <AuthorizedRouteCookie exact path="/machHandleEdit/:id" component={MachHandleEdit} />
                    <AuthorizedRouteCookie exact path="/maintRecordEdit/:id" component={MaintRecordEdit} />
                    <AuthorizedRouteCookie exact path="/maintContractEdit/:id" component={MaintContractEdit} />
                    <AuthorizedRouteCookie exact path="/machReportDetail/:id" component={MachReportDetail} />
                    <AuthorizedRouteCookie exact path="/maintStaffReportDetail/:id" component={MaintStaffReportDetail} />
                    <Route path="/notfound" component={NotFound} />
                    <Redirect to="/navbar" />
                </Switch>
            </BrowserRouter>
            <MaskLoad />
        </div>
    );
    
}

export const IS_DEBUG = process.env.NODE_ENV === "development";

export default App;


export const loadingMiddleware = ({ dispatch, getState }) => (next) => (action) => {
    if (action.type === 'SHOW_LOADING') {
        dispatch(loadingState('block'));
    } else if (action.type === 'HIDE_LOADING') {
        dispatch(loadingState('none'));
    } 

    return next(action);
};
export const expireMiddleware = (store) => (next) => (action) => {
    sessionStorage.setItem('userActionTime', Date.now());
   
    return next(action);
};
