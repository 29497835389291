import { apiMaterialLogQuery } from '../../utils/api.js';


export const MaterialLogQuery = (newdata) => async (dispatch, getState) => {
    let res = await apiMaterialLogQuery();
    let data = JSON.parse(res.request.response);
    dispatch(renMaterialLog(data));
}



const typeRenMaterialLog = 'typeRenMaterialLog';
const renMaterialLog = (arrMaterialLog) => {

    return {
        type: typeRenMaterialLog,
        materialLog: arrMaterialLog
    }
}


const initState = {
    materialLog: []
}


export const reducer = (state = initState, action) => {
    switch (action.type) {
        case typeRenMaterialLog:
            return { ...state, materialLog: [...action.materialLog] };

        default:
            return state;
    }
}