
import { doLogin } from '../../action/auth/authActions.js';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//import * as store from '../Login/Login.js';
//import LoadingOverlay from 'react-loading-overlay';
import themeStar from '../../img/logo-black.svg';
import { Button, Spinner } from 'react-bootstrap';
import { IS_DEBUG } from '../../App';
import '../Login/login.css';
import { requestDepartment, requestMembners } from '../../action/organization/organizationActions';

export const Login = (props) => {
    const dispatch = useDispatch();//派遣(傳送參數)到Redux
    const triggerLogin = async (data) => {
        await dispatch(doLogin(data));
        const path = '/navbar/Expand=1';
        props.history.push(path);
    }
    //登入參數 帳號和密碼
    const data = {
        IronMan: '',
        CaptainAmerica: ''
    };

    console.log("Current Debug State :", IS_DEBUG);


    return (
        <React.Fragment>
            <div className="container" style={{ height: '100 %', width: '100%' }} onKeyDown={async (e) => {
                //鍵盤按 enter 登入
                if (e.keyCode === 13) {
                    await triggerLogin(data);
                }
            }}>
                <div className="row align-items-center justify-content-center vh-100">
                    <div className="col-md-7">
                        <img src={themeStar} className="w-50 m-3" alt="logo" />
                        <h3>業務管理系統.</h3>
                    </div>
                    <div className="col-md-5">
                        <form className="login-form" action="#">
                            <div className="mb-3 form-floating">
                            </div>


                            {  //判斷是否IS_DEBUG 模式
                                IS_DEBUG &&
                                <DebugUserSelector onChange={(e) => {
                                    data.IronMan = e.target.value;
                                }} />
                            }
                            {  //帳號
                                !IS_DEBUG &&
                                <div className="mb-3 form-floating">
                                    <input autoFocus={true} type="username" className="form-control" id="floatingInput" placeholder="ID"
                                        onChange={(e) => {
                                            data.IronMan = e.target.value;
                                        }}
                                    />
                                    <label htmlFor="floatingInput" >帳號</label>
                                </div>
                            }
                            {   //密碼
                                !IS_DEBUG &&
                                <div className="mb-3 form-floating">
                                    <input type="password" className="form-control" id="floatingPassword" placeholder="Password"
                                        onChange={(e) => {
                                            data.CaptainAmerica = e.target.value;
                                        }} />
                                    <label htmlFor="floatingPassword" >密碼</label>
                                </div>
                            }
                            <Button variant="primary" className="w-100 btn-lg" disabled={props.isActive}
                                onClick={() => {
                                    triggerLogin(data);//登入按鈕
                                }}
                            >
                                {props.isActive ?
                                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" /> : "登入"
                                }
                            </Button>
                            <div className="text-center pt-3 pb-3">
                            </div>
                        </form>
                        <p className="pt-3 text-center">
                            <b>Copyright</b> ©.
                        </p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

//使用Redux參數
const DebugUserSelector = (props) => {
    const { onChange } = props;
    const dispatch = useDispatch();
    useEffect(() => {

        const param = {
            ID: 30,
            Code: '9'
        };
        dispatch(requestMembners(param));//工號
        dispatch(requestDepartment());//部門
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const { members, departments } = useSelector(x => x.organization);

    return (
        <div className="mb-3 form-floating">
            <select className="form-control" onChange={onChange}>
                <option value="-1">請選擇</option>
                {
                    members.map(x => {
                        const department = departments.find(y => y.ID === x.DepartmentID);
                        return (<option key={x.ID} value={x.ID}>{x.Name} ({department.Name})  </option>)
                    })
                }
            </select>

            <label htmlFor="floatingInput" >帳號</label>
        </div>
    )
}
