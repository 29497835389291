import { reducer as caseReducer } from '../src/action/case/caseActions.js';
import { reducer as orgReducer } from '../src/action/organization/organizationActions.js';
import { reducer as configReducer } from '../src/action/config/configActions.js';
import { reducer as authReducer } from '../src/action/auth/authActions.js';
import { reducer as caseLogReducer } from '../src/action/case/caseLogActions.js';
import { reducer as uiReducer } from '../src/action/ui/uiActions.js';
import { reducer as uiMachReducer } from '../src/action/ui/uiActionMach.js';
import { reducer as customerReducer } from '../src/action/customer/customerActions.js';
import { reducer as queryReducer } from '../src/action/query/queryAction.js'
import { reducer as userGroupReducer } from '../src/action/permission/adminActions.js'
import { reducer as productSettingsReducer } from '../src/action/material/productAction.js'
import { reducer as qrCodeReducer } from '../src/action/material/qrCodeAction.js'
import { reducer as productTrackReducer } from '../src/action/material/productTrackAction.js'
import { reducer as meterialLogReducer } from '../src/action/material/meterialLogAction.js'
import { reducer as statisticsReportReducer } from '../src/action/material/statisticsReportAction.js'
import { reducer as supplierReducer } from '../src/action/maintain/supplierAction.js'
import { reducer as machBasicReducer } from '../src/action/maintain/basicAction.js'
import { reducer as machHandleReducer } from '../src/action/maintain/handleAction.js'
import { reducer as maintRecordReducer } from '../src/action/maintain/recordAction.js'
import { reducer as maintContractReducer } from '../src/action/maintain/contractAction.js'
import { reducer as maintReportReducer } from '../src/action/maintain/reportAction.js'

export const reducers = {
    currentUser     : authReducer,
    config          : configReducer,
    businessCase    : caseReducer,
    organization    : orgReducer,
    caseLogs        : caseLogReducer,
    ui              : uiReducer,
    uiMach          : uiMachReducer,
    customers       : customerReducer,
    queryParam      : queryReducer,
    administration  : userGroupReducer,
    productSettings : productSettingsReducer,
    qrcode          : qrCodeReducer,
    productTrack    : productTrackReducer,
    log             : meterialLogReducer,
    statistics      : statisticsReportReducer,
    supplier        : supplierReducer,
    machBasic       : machBasicReducer,
    machHandle      : machHandleReducer,
    maintRecord     : maintRecordReducer,
    maintContract   : maintContractReducer,
    maintReport     : maintReportReducer,
};